const defaultState = {
  currentUrl: null,
  urlParams: {},
  paxshopPage: null,
};

export default function routing(state = defaultState, action) {
  if (action.type === 'UPDATE_ROUTING_STATE') {
    return { ...action.routingState };
  }

  return state;
}
