import http from 'axios';
import {
  createAxiosAuthConfig,
  selectJwtToken,
} from '../authorization/selectors';
import { store } from '../store';

export interface Message {
  componentId: string | null;
  createDateTime: string;
  message: string;
  messageCode: string | null;
  orderId: number;
  supplierCode: null | string;
  transactionId: string;
  type: 'WARNING' | 'IMPORTANT';
}

export async function fetchMessages() {
  const accessToken = selectJwtToken(store.getState());

  const resp = await http.get<{ data: Message[] }>(
    '/retail-gateway/api/tools/messages',
    createAxiosAuthConfig(accessToken)
  );

  return resp.data.data;
}
