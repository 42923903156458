import { IRoutingReduxState, IUpdateReduxRoutingAction } from 'types/routing';

export function updateRouting(
  routingState: IRoutingReduxState
): IUpdateReduxRoutingAction {
  return {
    type: 'UPDATE_ROUTING_STATE',
    routingState,
  };
}
