import http from 'axios';
import { MiddlewareAPI, Dispatch } from 'redux';
import {
  accessDenied,
  apiError,
  apiStart,
  apiEnd,
  makeRequest,
} from './actions';
import { API } from './constants';
import { selectJwtToken } from '../../authorization/selectors';
import { IStoreState } from '../../types/store';

export default function httpMiddleware({
  getState,
  dispatch,
}: MiddlewareAPI<Dispatch<any>, IStoreState>) {
  return next => action => {
    next(action);

    if (action.type !== API) return;

    const {
      url,
      method,
      data,
      onSuccess,
      onFailure,
      label,
    } = (action as ReturnType<typeof makeRequest>).payload;
    const dataOrParams = ['GET', 'DELETE'].includes(method) ? 'params' : 'data';

    const accessToken = selectJwtToken(getState());

    if (label) {
      dispatch(apiStart(label));
    }

    const headers = accessToken
      ? {
          Authorization: `Bearer ${accessToken}`,
        }
      : {};

    http
      .request({
        url,
        method,
        headers,
        [dataOrParams]: data,
      })
      .then(({ data: responseData }) => {
        dispatch(onSuccess(responseData));
      })
      .catch(error => {
        dispatch(apiError(error, label));
        if (onFailure) {
          dispatch(onFailure(error));
        }

        if (error.response && error.response.status === 403) {
          dispatch(accessDenied(window.location.pathname, label));
        }
      })
      .finally(() => {
        if (label) {
          dispatch(apiEnd(label));
        }
      });
  };
}
