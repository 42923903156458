import { PAXSHOP_LOGOUT_ACTION_ID } from 'authorization/actions/constants';

/*
  {
    lastValidation: {
      res: [
        {
          airPathDirection: 'OUT',
          'status': true,
          'description': null
        },
        {
          airPathDirection: 'IN',
          'status': true,
          'description': null
        }
      ],
      networkError: true | false,
      data: [] //occupiedSeats
    },

    validationProcess: {
      validating: true;
      dataBeingValidated: [] //occupiedSeats
    }
  }
 */
const defaultState = {
  lastValidation: null,

  validationProcess: {
    validating: false,
    dataBeingValidated: [],
  },
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case 'CHECK_SEATS_SET':
      if (state.validationProcess.dataBeingValidated !== action.occupiedSeats) {
        return state;
      }

      return {
        lastValidation: {
          res: action.res.data.map(item => ({
            airPathDirection: item.aitPathDirection, // typo on backend
            status: item.status,
            description: item.description,
          })),
          data: action.occupiedSeats,
          networkError: false,
        },
        validationProcess: {
          validating: false,
          dataBeingValidated: action.occupiedSeats,
        },
      };

    case 'CHECK_SEATS_SET_REQUEST':
      return {
        ...state,
        validationProcess: {
          validating: true,
          dataBeingValidated: action.occupiedSeats,
        },
      };

    case 'CHECK_SEATS_SET_TIMEOUT_START':
      return {
        ...state,
        validationProcess: {
          validating: true,
          dataBeingValidated: action.occupiedSeats,
        },
      };

    case 'CHECK_SEATS_SET_FAILURE':
      return {
        lastValidation: {
          res: [],
          data: action.occupiedSeats,
          networkError: true,
        },
        validationProcess: {
          validating: false,
          dataBeingValidated: action.occupiedSeats,
        },
      };

    case PAXSHOP_LOGOUT_ACTION_ID:
      return defaultState;

    default:
      return state;
  }
}
