import { IPaxshopPage } from 'types/routing';

export const PAGE_LOGIN: IPaxshopPage = {
  pageId: 'LOGIN',
  url: '/',

  titleCms: 'TITLE_PAGE_HOME',
  titleFallback: 'Home',

  containerClass: 'home',
  auth: {
    requiresLogin: false,
  },
};

export const PAGE_TERMS: IPaxshopPage = {
  pageId: 'TERMS',
  url: '/terms-and-conditions',

  titleCms: 'TITLE_PAGE_TERMS',
  titleFallback: 'Terms & Conditions',
  containerClass: 'terms_page',

  auth: {
    requiresLogin: false,
  },
};

export const PAGE_MY_BOOKING: IPaxshopPage = {
  pageId: 'MY_BOOKING',
  url: '/mybooking',

  titleCms: 'TITLE_PAGE_MY_BOOKING',
  titleFallback: 'My Booking',
  containerClass: 'mybooking',

  auth: {
    requiresLogin: true,
  },
};

export const PAGE_SEATING: IPaxshopPage = {
  pageId: 'SEATING',
  url: '/seating',

  titleCms: 'TITLE_PAGE_SEATING',
  titleFallback: 'Seating',
  containerClass: 'seating',

  auth: {
    requiresLogin: true,
  },
};

export const PAGE_BAGGAGE: IPaxshopPage = {
  pageId: 'SVC_BAGGAGE',
  url: '/baggage',

  titleCms: 'TITLE_PAGE_EXTRA_BAGGAGE',
  titleFallback: 'Extra baggage',
  containerClass: 'baggage',

  auth: {
    requiresLogin: true,
  },
};

export const PAGE_OTHER_SERVICES: IPaxshopPage = {
  pageId: 'SVC_OTHER',
  url: '/otherservices',

  titleCms: 'TITLE_PAGE_MEAL_AND_DRINK',
  titleFallback: 'Choose your meal & drinks',
  containerClass: 'otherServices',

  auth: {
    requiresLogin: true,
  },
};

export const PAGE_LOUNGES: IPaxshopPage = {
  pageId: 'SVC_LOUNGES',
  url: '/lounges',

  titleCms: 'TITLE_LOUNGES',
  titleFallback: 'Lounges',
  containerClass: 'svcLounges',

  auth: {
    requiresLogin: true,
  },
};

export const PAGE_CART: IPaxshopPage = {
  pageId: 'CART',
  url: '/cart',

  titleCms: 'TITLE_PAGE_CART',
  titleFallback: 'Cart',
  containerClass: 'cartpage',

  auth: {
    requiresLogin: true,
  },
};

export const PAGE_CHECKOUT: IPaxshopPage = {
  pageId: 'CHECKOUT',
  url: '/checkout',

  titleCms: 'TITLE_PAGE_CHECKOUT',
  titleFallback: 'Checkout',
  containerClass: 'checkout',

  auth: {
    requiresLogin: true,
  },
};

export const PAGE_CONFIRMATION: IPaxshopPage = {
  pageId: 'CONFIRMATION',
  url: '/confirmation',

  titleCms: 'TITLE_PAGE_CONFIRMATION',
  titleFallback: 'Confirmation',
  containerClass: 'confirmation',

  auth: {
    requiresLogin: true,
  },
};

export const PAGE_PAYMENT_FAILED: IPaxshopPage = {
  pageId: 'PAYMENT_FAILED',
  url: '/payment-failed',

  titleCms: 'TITLE_PAGE_PAYMENT_FAILED',
  titleFallback: 'Payment Failed',
  containerClass: 'paymentFailedContainer',

  auth: {
    requiresLogin: true,
  },
};

export const ALL_PAGES: IPaxshopPage[] = [
  PAGE_LOGIN,
  PAGE_TERMS,
  PAGE_MY_BOOKING,
  PAGE_SEATING,
  PAGE_BAGGAGE,
  PAGE_OTHER_SERVICES,
  PAGE_LOUNGES,
  PAGE_CART,
  PAGE_CHECKOUT,
  PAGE_CONFIRMATION,
  PAGE_PAYMENT_FAILED,
];

export const ALL_PAGES_MAP: { [id: string]: IPaxshopPage } = ALL_PAGES.reduce(
  (map, page) => {
    map[page.pageId] = page;
    return map;
  },
  {} as { [id: string]: IPaxshopPage }
);

export function matchPaxshopPage(url: string): IPaxshopPage | null {
  for (let i = 0; i < ALL_PAGES.length; i++) {
    if (url === ALL_PAGES[i].url) {
      return ALL_PAGES[i];
    }
  }

  return null;
}
