import { appConfig } from 'config';
import { createExtractedDataSelector } from '_helpers/datacontainer/selectors';
import { chooseValidDateFormat } from '_helpers/dateFormat';
import { systemDetectLanguage } from 'languages/_helpers/langDetectors';
import { createSelector } from 'reselect';
import { IAuthRes } from 'types/auth';
import { IDataContainer } from 'types/datacontainer';
import { ICMSKeysMap, IDateOptions } from 'types/languages';
import { IPaxshopReduxStore, IStoreState } from 'types/store';
import { arrayToObject, merge } from '../_helpers/mergeCMSKeys';
import { setLanguage } from '../actions';
import { selectAuthData } from '../../authorization/selectors';

const speedDate: (fmt: string, date: Date) => string = require('speed-date')
  .UTC;

const bookingLangsSelector: (
  state: IStoreState
) => IDataContainer<string[]> = createExtractedDataSelector(
  (state: IStoreState) => state.auth.authData,
  (authData: IAuthRes) => (authData ? authData.languages : null)
);

export const allLangsSelector = createSelector(
  (state: IStoreState) => state.languages.shopLangs,
  bookingLangsSelector,
  (shopLangs, bookingLangs) => {
    if (shopLangs && bookingLangs.data) {
      const langs: string[] = [];
      for (let i = 0; i < shopLangs.length; i++) {
        const l = shopLangs[i];
        if (bookingLangs.data.indexOf(l) !== -1) {
          langs.push(l);
        }
      }
      return langs;
    }
    if (shopLangs) {
      return shopLangs;
    }
    if (bookingLangs.data) {
      return bookingLangs.data;
    }
    return [];
  }
);

export const currentLangSelector = createSelector(
  allLangsSelector,
  (state: IStoreState) => state.languages.selectedLanguage,
  (allLangs, selectedLang: string): string => {
    if (!allLangs || allLangs.length === 0) {
      return null;
    }

    if (selectedLang && allLangs.indexOf(selectedLang) !== -1) {
      return selectedLang;
    }

    return allLangs[0];
  }
);

export const selectIsPaxpartnerKeysUsed = (state: IStoreState) =>
  Boolean(
    state.init.paxshopAppCtx.paxPartner && appConfig.paxpartnerSalesChannel
  );

export const selectEffectiveLanguage = (state: IStoreState) =>
  selectIsPaxpartnerKeysUsed(state)
    ? state.languages.paxPartnerCMSKeys.locale
    : state.languages.shopCMSKeys.locale;

export const selectIfCMSLoadedCompletely = createSelector(
  currentLangSelector,
  selectIsPaxpartnerKeysUsed,
  selectAuthData,
  (state: IStoreState) => state.languages.shopCMSKeys,
  (state: IStoreState) => state.languages.bookingCMSKeys,
  (state: IStoreState) => state.languages.paxPartnerCMSKeys,
  (
    currentLocale,
    isPaxpartner,
    authData,
    shopKeys,
    bookingKeys,
    paxpartnerKeys
  ) => {
    if (isPaxpartner) {
      return paxpartnerKeys && paxpartnerKeys.locale === currentLocale;
    }
    if (authData !== null) {
      return (
        bookingKeys &&
        bookingKeys.locale === currentLocale &&
        shopKeys &&
        shopKeys.locale === currentLocale
      );
    }
    return shopKeys && shopKeys.locale === currentLocale;
  }
);

export const allCMSKeysRawSelector = createSelector(
  selectIsPaxpartnerKeysUsed,
  (state: IStoreState) => state.languages.shopCMSKeys,
  (state: IStoreState) => state.languages.bookingCMSKeys,
  (state: IStoreState) => state.languages.paxPartnerCMSKeys,
  (state: IStoreState) =>
    state.auth.authData &&
    state.auth.authData.data &&
    state.auth.authData.data.carrierHasMultipleLogos,
  (
    isPaxpartner,
    shopCMSKeys,
    bookingCMSKeys,
    paxPartnerCMSKeys,
    doubleBranded
  ) =>
    isPaxpartner
      ? arrayToObject((paxPartnerCMSKeys && paxPartnerCMSKeys.cmsTexts) || [])
      : merge(
          (bookingCMSKeys && bookingCMSKeys.cmsTexts) || [],
          (shopCMSKeys && shopCMSKeys.cmsTexts) || [],
          doubleBranded
        )
);

export const dateOptionsSelector = createSelector(
  allCMSKeysRawSelector,
  (state: IStoreState) =>
    state.init.paxshopData.data ? state.init.paxshopData.data.dateFormat : null,
  (cmsKeys: { [key: string]: string }, psDateFormat: string): IDateOptions => {
    const psDateFmt = chooseValidDateFormat([
      (cmsKeys && cmsKeys.DATE_FORMAT && cmsKeys.DATE_FORMAT.toUpperCase()) ||
        null,
      psDateFormat,
      'YYYY-MM-DD',
    ]);

    return {
      paxshopDateFormat: psDateFmt,
    };
  }
);

const WEEKDAYS_CMS = [
  'SUNDAY',
  'MONDAY',
  'TUESDAY',
  'WEDNESDAY',
  'THURSDAY',
  'FRIDAY',
  'SATURDAY',
];

const MONTHS_CMS = [
  'JANUARY',
  'FEBRUARY',
  'MARCH',
  'APRIL',
  'MAY',
  'JUNE',
  'JULY',
  'AUGUST',
  'SEPTEMBER',
  'OCTOBER',
  'NOVEMBER',
  'DECEMBER',
];

export const localeUtilsSelector = createSelector(
  allCMSKeysRawSelector,
  (cmsKeys: { [key: string]: string }) => ({
    formatMonthTitle: d =>
      `${cmsKeys[`MONTH_${MONTHS_CMS[d.getMonth()]}`]} ${d.getFullYear()}`,
    formatWeekdayShort: i => cmsKeys[`WEEKDAY_${WEEKDAYS_CMS[i]}`],
    formatWeekdayLong: i => cmsKeys[`WEEKDAY_${WEEKDAYS_CMS[i]}`],
    getFirstDayOfWeek: () => 1,
    formatDay: (d): any => {},
  })
);

function insertParamsToCms(cmsKeyValue: string, params: any): string {
  return cmsKeyValue.replace(/(\${([^{]*)\})/g, (...args) => params[args[2]]);
}

export const localeDataSelector = createSelector(
  dateOptionsSelector,
  allCMSKeysRawSelector,
  (dateOptions: IDateOptions, cmsKeys: ICMSKeysMap) => ({
    dateOptions,
    cmsKeys,
    formatMomentDate: (mom, fmt) =>
      mom ? speedDate(fmt || dateOptions.paxshopDateFormat, mom._d) : '',
    printCmsText: cmsKey =>
      cmsKeys.hasOwnProperty(cmsKey) ? cmsKeys[cmsKey] : cmsKey,
    printCmsTextWithParams: (cmsKey, params) =>
      cmsKeys.hasOwnProperty(cmsKey)
        ? insertParamsToCms(cmsKeys[cmsKey], params)
        : cmsKey,
    ifCmsFilled: (cmsKey, func) =>
      cmsKeys.hasOwnProperty(cmsKey) && !!cmsKeys[cmsKey]
        ? func(cmsKeys[cmsKey])
        : null,
  })
);

export function initLanguages(store: IPaxshopReduxStore) {
  let wasLanguageSetFromUrl = false;

  function getParameterByName(name: string, url: string): string {
    if (!url) {
      url = window.location.href;
    }
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);

    const results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  store.subscribe(() => {
    const state = store.getState();
    const selectedLang = state.languages.selectedLanguage;
    const allLangs = allLangsSelector(state);

    if (!allLangs || allLangs.length === 0) {
      return;
    }

    let queryLang: string;

    if (!wasLanguageSetFromUrl) {
      queryLang = getParameterByName('lang', location.search);
      wasLanguageSetFromUrl = true;
    }

    if (queryLang || !selectedLang || allLangs.indexOf(selectedLang) === -1) {
      const systemLang = systemDetectLanguage(allLangs);
      store.dispatch(setLanguage(queryLang || systemLang || allLangs[0]));
    }
  });
}
