import React, { Component } from 'react';
import { appConfig } from 'config';
import { Bem } from 'react-bem-classes';
import { captureException, addBreadcrumb } from '@sentry/browser';
import './index.scss';
import { connect } from 'react-redux';
import { PassengerSelector } from 'passengers/containers/selector';
import { SegmentsSelector } from 'segments/containers/selector';
import { DisplayTime, DisplayDate } from '_helpers/displayDate';
import * as actions from 'check-in/actions';
import {
  passengersSelector,
  currentPassengerSelector,
} from 'passengers/helpers/selectors';
import { localeDataSelector, currentLangSelector } from 'languages/selectors';
import { currentSegmentSelector } from 'segments/helpers/selectors';
import {
  alreadyCheckedInSegmentsSelector,
  selectCheckInSegments,
} from 'check-in/helpers/selectors';
import {
  currentBookingNumberSelector,
  currentBookingDepartureDateSelector,
} from 'authorization/selectors';
import { Modal } from 'layouts/modal';
import MailModal from 'check-in/components/mailModal';
import QRCode from 'qrcode.react';
import { isAppleDevice, isIpad } from '_helpers/isMobile';
import { IStoreState } from 'types/store';
import { IPassenger } from 'types/passengers';
import { IEnhancedSegment } from 'types/segments';
import { ILocaleData } from 'types/languages';
import { ICheckInSegment } from 'types/checkIn';
import walletIcon from './images/general-wallet.svg';
import pkpassButton from './images/add-to-wallet-uk.svg';
import { selectCurrentPassengerSegmentSeat } from '../../../seatingWidget/helpers/selectors';

function createPKPassQRCode(state): string {
  const segmentsForCheckin = selectCheckInSegments(state);
  const currentSegment = currentSegmentSelector(state);
  const currentPassenger = currentPassengerSelector(state);

  try {
    return segmentsForCheckin
      .find(({ segmentId }) => segmentId === currentSegment.segmentId)
      .qrCodes.find(
        ({ passengerId }) => passengerId === currentPassenger.passengerId
      ).qrString;
  } catch (e) {
    addBreadcrumb({
      type: 'info',
      category: 'important-state-part',
      message: JSON.stringify(
        { segmentsForCheckin, currentSegment, currentPassenger },
        null,
        2
      ),
    });
    captureException(e);
  }

  return null;
}

interface IProps {
  passengers: IPassenger[];
  currentPassenger: IPassenger;
  currentSegment: IEnhancedSegment;
  localeData: ILocaleData;
  currentPassengerSegmentSeat: string;
  alreadyCheckedInSegment: ICheckInSegment[];
  bookingNumber: string;
  departureDate: string;
  queryParams: { [key: string]: string };
  pkPassQRCode: string;
  currentLang: string;
  pkPassSendingStatus: null | 'load' | 'success' | 'fail';
  sendEmailWithPkPass: (email: string, passengerId: number) => void;
  downloadPkPass: (
    flightNumber: string,
    departureDate: string,
    bookingNumber: string,
    currentPassengerId: number,
    currentLang: string
  ) => Promise<any>;
  resetPkPassSendingStatus: () => void;
}

interface IState {
  downloadPkPassLoader: boolean;
}

const connectPkPass = connect(
  (state: IStoreState) => ({
    passengers: passengersSelector(state),
    currentPassenger: currentPassengerSelector(state),
    currentSegment: currentSegmentSelector(state),
    localeData: localeDataSelector(state),
    currentPassengerSegmentSeat: selectCurrentPassengerSegmentSeat(state),
    alreadyCheckedInSegment: alreadyCheckedInSegmentsSelector(state),
    bookingNumber: currentBookingNumberSelector(state),
    departureDate: currentBookingDepartureDateSelector(state),
    queryParams: state.init.paxshopInitParams.queryParams,
    pkPassQRCode: createPKPassQRCode(state),
    currentLang: currentLangSelector(state),
    pkPassSendingStatus: state.checkIn.pkPassSendingStatus,
  }),
  {
    sendEmailWithPkPass: actions.sendEmailWithPkPass,
    downloadPkPass: actions.downloadPkPass,
    resetPkPassSendingStatus: actions.resetPkPassSendingStatus,
  }
);

class PkPass extends Component<IProps, IState> {
  mailModal: any;

  constructor(props) {
    super(props);
    this.state = {
      downloadPkPassLoader: false,
    };
  }

  componentDidMount() {
    window.addEventListener('blur', this.onBlur);
  }

  componentWillUnmount() {
    window.removeEventListener('blur', this.onBlur);
  }

  onBlur = () => {
    this.setState({ downloadPkPassLoader: false });
  };

  showMailModal() {
    this.props.resetPkPassSendingStatus();
    this.mailModal.open();
  }

  submitMailForm(values) {
    this.props.sendEmailWithPkPass(
      values.email,
      this.props.currentPassenger.passengerId
    );
  }

  downloadPkPassNonApple() {
    const {
      currentPassenger,
      currentSegment,
      bookingNumber,
      departureDate,
      currentLang,
    } = this.props;

    const { flightNumber } = currentSegment.flights[0];

    this.setState({ downloadPkPassLoader: true });

    this.props
      .downloadPkPass(
        flightNumber,
        departureDate,
        bookingNumber,
        currentPassenger.passengerId,
        currentLang
      )
      .then(
        res => {
          const blob = new Blob([res.data], {
            type: 'application/vnd.apple.pkpass',
          }); // res.data is ArrayBuffer
          const url = window.URL.createObjectURL(blob);

          // MSIE/Edge workaround
          if (navigator.msSaveOrOpenBlob) {
            navigator.msSaveOrOpenBlob(
              blob,
              `${flightNumber}_${currentPassenger.passengerId}.pkpass`
            );
          } else {
            // Create a link element, hide it, direct it towards the blob, and then 'click' it programatically
            const a = document.createElement('a');
            a.style.cssText = 'display: none';
            document.body.appendChild(a);
            // Create a DOMString representing the blob and point the link element towards it
            a.href = url;
            a.download = `${flightNumber}_${currentPassenger.passengerId}.pkpass`; // don't download, open right after click
            // programatically click the link to trigger the download
            a.click();
            // release the reference to the file by revoking the Object URL
            window.URL.revokeObjectURL(url);
          }

          this.setState({ downloadPkPassLoader: false });
        },
        () => {
          this.setState({ downloadPkPassLoader: false });
        }
      );
  }

  renderInfoField(bem, titleCms, child) {
    return (
      <div className={bem.element('infoField')}>
        <div className={bem.element('infoTitle')}>
          {this.props.localeData.printCmsText(titleCms)}
        </div>
        <div className={bem.element('infoValue')}>{child}</div>
      </div>
    );
  }

  render() {
    const bem = new Bem('pkPassModal', this.props);

    const {
      passengers,
      localeData,
      currentPassenger,
      currentSegment,
      currentPassengerSegmentSeat,
      alreadyCheckedInSegment,
      bookingNumber,
      departureDate,
      queryParams,
      pkPassQRCode,
      currentLang,
      pkPassSendingStatus,
    } = this.props;

    const firstFlight = currentSegment.flights[0];
    const lastFlight = currentSegment.flights[currentSegment.flights.length - 1];

    const pkPassLink = `${appConfig.contextUrl}/pkpass/${firstFlight.flightNumber}/${departureDate}/${bookingNumber}/${currentPassenger.passengerId}/${currentLang}`;

    const { downloadPkPassLoader } = this.state;

    const walletImage = isAppleDevice() ? pkpassButton : walletIcon;

    return (
      <div className={bem.block()}>
        <div className={bem.element('title')}>
          {localeData.printCmsText('PASSENGERS_PASSES')}
        </div>

        {passengers.length > 1 && (
          <PassengerSelector
            defaultPassengerId={this.props.currentPassenger.passengerId}
            key="passengersSelector"
            className={bem.element('passengers')}
            mini
          />
        )}

        <SegmentsSelector isMini>
          {alreadyCheckedInSegment.map((segment, index) => (
            // @ts-ignore SegmentsSelector maps children and parses this segment attr
            <div key={segment.segmentId} segment={segment}>
              {index === 0
                ? localeData.printCmsText(`OUTBOUND`)
                : localeData.printCmsText(`INBOUND`)}
            </div>
          ))}
        </SegmentsSelector>

        <div className={bem.element('info')}>
          <div
            className={bem.element('mailButton')}
            onClick={() => this.showMailModal()}
          />
          {this.renderInfoField(
            bem,
            'PASSENGER',
            `${currentPassenger.firstName} ${currentPassenger.lastName}`
          )}
          {currentPassengerSegmentSeat &&
            this.renderInfoField(bem, 'SEAT', currentPassengerSegmentSeat)}
          {this.renderInfoField(
            bem,
            'FLIGHT_DATE',
            <DisplayDate
              date={firstFlight.departureDateTimeMoment}
              localeData={localeData}
              className={bem.element('date')}
            />
          )}
          {this.renderInfoField(
            bem,
            'DEPARTURE',
            <div>
              <DisplayTime
                date={firstFlight.departureDateTimeMoment}
                className={bem.element('date')}
              />{' '}
              {firstFlight.departureAirportName}
            </div>
          )}
          {this.renderInfoField(
            bem,
            'ARRIVAL',
            <div>
              <DisplayTime
                date={lastFlight.arrivalDateTimeMoment}
                className={bem.element('date')}
              />{' '}
              {lastFlight.arrivalAirportName}
            </div>
          )}
        </div>

        <div className={bem.element('wallet')}>
          {!isIpad() && [
            !isAppleDevice() && (
              <div>{localeData.printCmsText(`CMS_ADD_TO_MOBILE_WALLET`)}</div>
            ),
            isAppleDevice() ? (
              <a
                onClick={() => {
                  this.setState({ downloadPkPassLoader: true });
                  setTimeout(
                    () => this.setState({ downloadPkPassLoader: false }),
                    3000
                  );
                }}
                href={pkPassLink}
              >
                <img src={walletImage} alt="" />
              </a>
            ) : (
              <a onClick={() => this.downloadPkPassNonApple()}>
                <img src={walletImage} alt="" />
              </a>
            ),
          ]}

          <div className={bem.element('qr-code')}>
            {pkPassQRCode && <QRCode value={pkPassQRCode} />}
          </div>
          <div
            className={`local-loader ${downloadPkPassLoader ? 'active' : ''}`}
          />
        </div>

        <Modal
          ref={c => {
            this.mailModal = c;
          }}
          newDesign
        >
          <MailModal
            sendMailLoader={pkPassSendingStatus === 'load'}
            successMail={pkPassSendingStatus === 'success'}
            submitMailForm={v => this.submitMailForm(v)}
            queryParamEmail={queryParams.email}
            localeData={localeData}
          />
        </Modal>
      </div>
    );
  }
}

export default connectPkPass(PkPass);
