import React from 'react';
import { connect } from 'react-redux';
import bem from 'react-bem-classes';
import './index.scss';
import { segmentsSelector } from 'segments/helpers/selectors';
import MediaQuery from 'react-responsive';
import Cloud from 'layouts/pageCloud';
import { Text } from 'languages/components/text';
import * as actions from '../../actions';
import { SegmentSelectorMobile } from './mobile';
import Segment from '../../components/segmentsItem';

@connect(
  state => ({
    segmentsList: segmentsSelector(state),
    currentSegmentId: state.segments.currentSegmentId,
    showDepartureTime: state.init.paxshopData.data.showDepartureTime,
  }),
  dispatch => ({ dispatch })
)
@bem({
  block: 'segmentsSelector',
  modifiers: ['isMini'],
})
export class SegmentsSelector extends React.Component {
  componentWillMount() {
    this.changeSegment(
      this.props.children
        ? this.props.children[0].props.segment
        : this.props.segmentsList[0]
    );
  }

  changeSegment(segment) {
    if (segment.segmentId !== this.props.currentSegmentId) {
      this.props.dispatch(actions.switchSegment(segment.segmentId));
      this.props.switchSegment && this.props.switchSegment(segment);
    }
  }

  render() {
    const {
      segmentsList,
      currentSegmentId,
      errors,
      doErrorsShow,
      seatingErrors,
      children,
      showDepartureTime,
    } = this.props;

    return !children ? (
      <div>
        <MediaQuery query={SCREENS_MEDIA.portraitTabletAndLarger}>
          <Cloud>
            <div className={this.block()}>
              {segmentsList.map(segment => (
                <Segment
                  errorMessageCms={
                    (
                      (seatingErrors || []).find(
                        error => error.airPathDirection === segment.direction
                      ) || {}
                    ).description || ''
                  }
                  key={segment.segmentId}
                  error={
                    doErrorsShow
                      ? (errors || []).find(
                          e =>
                            e.airPathDirection === segment.direction &&
                            !e.status
                        )
                      : null
                  }
                  onClick={this.changeSegment.bind(this, segment)}
                  className={this.element('item')}
                  segment={segment}
                  showDepartureTime={showDepartureTime}
                  isCurrent={currentSegmentId === segment.segmentId}
                />
              ))}
            </div>
          </Cloud>
        </MediaQuery>

        <MediaQuery query={SCREENS_MEDIA.mobileAndSmaller}>
          <SegmentSelectorMobile
            seatingErrors={seatingErrors}
            segmentsList={segmentsList}
            currentSegmentId={currentSegmentId}
            onSegmentSelect={this.changeSegment.bind(this)}
          />
        </MediaQuery>
      </div>
    ) : children.length === 1 ? null : (
      <div className={this.block()}>
        {children.map(segment =>
          React.cloneElement(segment, {
            ...segment.props,
            className: this.element('miniItem', {
              isActive: segment.props.segment.segmentId === currentSegmentId,
            }),
            onClick: this.changeSegment.bind(this, segment.props.segment),
          })
        )}
      </div>
    );
  }
}
