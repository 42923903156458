import React from 'react';
import bem from 'react-bem-classes';

import './index.scss';

@bem({
  block: 'formGroup2',
})
export class FormGroup2 extends React.Component {
  render() {
    return <div className={this.block()}>{this.props.children}</div>;
  }
}
