import { PAXSHOP_LOGOUT_ACTION_ID } from 'authorization/actions/constants';

const defaultState = null;

export default function(state = defaultState, action) {
  switch (action.type) {
    case 'SWITCH_PASSENGER':
      return action.passengerId;

    case PAXSHOP_LOGOUT_ACTION_ID:
      return null;

    default:
      return state;
  }
}
