import React, { ReactEventHandler } from 'react';
import { useLocale } from '../../_helpers/localeContext';

interface ITransProps {
  children: string;
  fallback?: string;
}

interface ITextProps extends ITransProps {
  deleteImage?: boolean;
  maxLength?: number;
  useSpan?: boolean;
  as?: keyof HTMLElementTagNameMap;
  onClick?: ReactEventHandler<MouseEvent>;
  className?: string;
  id?: string;
}

// React typings doesn't support returning string as JSX.Element for some reason
// That's why we specify return type for T as any
export function T({ children, fallback }: ITransProps): any {
  const { t } = useLocale();
  return t(children, fallback);
}

export function Text({
  children,
  fallback,
  deleteImage,
  maxLength,
  useSpan,
  as = useSpan ? 'span' : 'div',
  onClick,
  className,
  id,
}: ITextProps) {
  const { t } = useLocale();
  let translation = t(children, fallback);
  if (deleteImage) translation = translation.replace(/<img[^>]*>/g, '');
  if (maxLength) translation = translation.substring(0, maxLength);

  return React.createElement(as, {
    onClick,
    className,
    id,
    dangerouslySetInnerHTML: {
      __html: translation,
    },
  });
}
