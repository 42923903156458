import { combineEpics, StateObservable } from 'redux-observable';
import { Observable, merge } from 'rxjs';
import { Action } from 'redux';
import {
  distinctUntilChanged,
  filter,
  map,
  pluck,
  sample,
} from 'rxjs/operators';
import { IStoreState } from 'types/store';
import { selectAuthData } from 'authorization/selectors';
import { appConfig } from 'config';
import { selectIsPaxpartnerKeysUsed } from '../selectors';
import {
  fetchBookingCMSKeys,
  fetchPaxpartnerCMSKeys,
  fetchShopCMSKeys,
} from '../actions';

export function refetchBookingCMSKeys(
  $action: Observable<Action>,
  $state: StateObservable<IStoreState>
) {
  const effectiveObservable = $state.pipe(
    filter(state => !selectIsPaxpartnerKeysUsed(state)),
    filter(state => selectAuthData(state) !== null)
  );

  const whenLanguageChanges = distinctUntilChanged(
    undefined,
    (state: IStoreState) => state.languages.selectedLanguage
  )(effectiveObservable);
  const whenBookingChanges = distinctUntilChanged(
    undefined,
    selectAuthData
  )(effectiveObservable);

  return effectiveObservable.pipe(
    sample(merge(whenLanguageChanges, whenBookingChanges)),
    filter(state => state.languages.selectedLanguage !== null),
    map(state =>
      fetchBookingCMSKeys(
        state.languages.selectedLanguage,
        selectAuthData(state).cmsSalesChannelId
      )
    )
  );
}

export function refetchCommonCMSKeys(
  $action: Observable<Action>,
  $state: StateObservable<IStoreState>
) {
  return $state.pipe(
    filter(state => !selectIsPaxpartnerKeysUsed(state)),
    pluck('languages', 'selectedLanguage'),
    filter(lang => lang !== null),
    distinctUntilChanged(),
    map(fetchShopCMSKeys)
  );
}

export function refetchPaxpartnerCMSKeys(
  $action: Observable<Action>,
  $state: StateObservable<IStoreState>
) {
  return $state.pipe(
    filter(state => selectIsPaxpartnerKeysUsed(state)),
    pluck('languages', 'selectedLanguage'),
    filter(lang => lang !== null),
    distinctUntilChanged(),
    map(lang => fetchPaxpartnerCMSKeys(lang, appConfig.paxpartnerSalesChannel))
  );
}

export default combineEpics(
  refetchCommonCMSKeys,
  refetchBookingCMSKeys,
  refetchPaxpartnerCMSKeys
);
