import './index.scss';

import React, { Component } from 'react';
import bem from 'react-bem-classes';

import { Text } from 'languages/components/text';
import { Price } from 'commonBlocks/components/price';
import { DisplayDate } from '_helpers/displayDate';

@bem({
  block: 'groupedOrdersItem',
})
class GroupedOrdersItem extends React.Component {
  render() {
    const {
      store,
      ordersByGroup,
      groupedOrder,
      serviceGroups,
      showModal,
      localeData,
    } = this.props;

    const total = ordersByGroup.reduce(
      (sum, x) => sum + parseFloat(x.price * x.quantity),
      0
    );

    return (
      <div className={this.block()}>
        <div className={this.element('leftSide')}>
          <div className={this.element('date')}>
            <DisplayDate
              date={groupedOrder.dateMoment}
              localeData={localeData}
            />
            <div className={this.element('segmentInfo')}>
              Order: {groupedOrder.id}
            </div>
          </div>
          <div className={this.element('ordersByGroups')} />
        </div>
        <div className={this.element('rightSide')}>
          <div className={this.element('total')}>
            <Price price={total} currency={groupedOrder.currency} />
          </div>
          <Text
            className={this.element('moreInfo')}
            onClick={showModal.bind(null, groupedOrder.id)}
          >
            MY_BOOKING_ORDER_INFO
          </Text>
        </div>
      </div>
    );
  }
}

export default GroupedOrdersItem;
