import {
  homePageIdSelector,
  isLoggedInSelector,
} from 'authorization/selectors';
import { goToLoginPage, goToPage } from 'routing/routing';
import { store } from 'store';

export function initRoutingAuthWatcher() {
  let lastLoggedIn = isLoggedInSelector(store.getState());

  store.subscribe(() => {
    const state = store.getState();

    const loggedIn = isLoggedInSelector(state);

    if (loggedIn === lastLoggedIn) return;
    lastLoggedIn = loggedIn;

    if (loggedIn) {
      goToPage(homePageIdSelector(state));
    } else {
      goToLoginPage();
    }
  });
}
