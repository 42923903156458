const activeModals: string[] = [];
let bodyOffset = 0;

export function modalOpened(modalId: string) {
  if (activeModals.length === 0) {
    const scrollingElement =
      document.scrollingElement || document.documentElement;

    bodyOffset = scrollingElement.scrollTop;
    document.body.style.position = 'fixed';
    document.body.style.top = `-${bodyOffset}px`;
    document.body.style.width = `100%`;
  }

  activeModals.push(modalId);
}

export function modalClosed(modalId: string) {
  const idx = activeModals.indexOf(modalId);
  if (idx > -1) {
    activeModals.splice(idx, 1);
  } else {
    console.warn(`attempt to close unregistered modal ${modalId}`);
  }

  if (activeModals.length === 0) {
    const scrollingElement =
      document.scrollingElement || document.documentElement;

    document.body.style.position = 'relative';
    document.body.style.top = `0`;
    scrollingElement.scrollTop = bodyOffset;
    document.body.style.width = `auto`;
  }
}

export function isModalActive() {
  return activeModals.length > 0;
}
