function checkNativeDatePickerSupport(): boolean {
  if (!document) {
    return false;
  }
  const mockInput = document.createElement('input');

  try {
    mockInput.type = 'date';
  } catch (ignored) {}

  return mockInput.type === 'date' && 'ontouchstart' in window;
}

export const haveNativeDatepicker = checkNativeDatePickerSupport();
