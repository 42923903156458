import './index.scss';
import * as React from 'react';
import PropTypes from 'prop-types';
import { reduxForm, FieldArray } from 'redux-form';
import {
  validate,
  validateEmail,
  validateEmails,
} from '_helpers/reduxFormHelpers';
import { Bem } from 'react-bem-classes';
import TextInput from 'commonBlocks/components/textInput';
import { connect } from 'react-redux';
import { allCMSKeysRawSelector } from '../../../languages/selectors';

function validateMailForm(values, props) {
  return !!values.emails && values.emails.length > 0
    ? validate(values, { emails: [validateEmails] })
    : validate(values, { email: [validateEmail] });
}

const INDIVIDUAL_TAB = 0;

const ALL_IN_ONE_TAB = 1;

const bem = new Bem('mailBoardingPassForm');
const modalBem = new Bem('modalMail');

class RenderEmails extends React.Component {
  render() {
    const {
      fields,
      meta: { error },
      cmsKeys,
      passengers,
    } = this.props;

    return fields.map((passenger, index) => (
      <div
        className={modalBem.element('formCol')}
        key={passengers[index].passengerId}
      >
        <TextInput
          placeholder={`${passengers[index].firstName} ${passengers[index].lastName}`}
          name={passenger}
        />
      </div>
    ));
  }
}

@connect((state, ownProps) => ({
  cmsKeys: allCMSKeysRawSelector(state),
  initialValues: {
    email: ownProps.queryParamEmail,
  },
}))
@reduxForm({
  form: 'mailBoardingPassForm',
  fields: ['email', 'emails[]'],
  validate: validateMailForm,
})
export default class MailModal extends React.Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
  };

  get currentTab() {
    return this.state.currentTab;
  }

  set currentTab(currentTab) {
    if (currentTab === INDIVIDUAL_TAB) {
      this.props.passengers.forEach(() => {
        !!this.refs.emailsArray &&
          this.refs.emailsArray.getRenderedComponent().props.fields.length <
            this.props.passengers.length &&
          this.refs.emailsArray.getRenderedComponent().props.fields.push('');
      }, this);
    } else {
      !!this.refs.emailsArray &&
        this.refs.emailsArray
          .getRenderedComponent()
          .props.fields.forEach(
            () =>
              this.refs.emailsArray
                .getRenderedComponent()
                .props.fields.pop() /* this.props.fields.emails.removeField(0) */
          );
    }
    this.setState({ currentTab });
  }

  componentWillMount() {
    this.currentTab = ALL_IN_ONE_TAB;
  }

  isShowingTabs() {
    const { passengers } = this.props;

    return passengers && passengers.length > 0;
  }

  renderInputs() {
    const { passengers, localeData, cmsKeys } = this.props;

    return (
      <div>
        <div
          className={`${modalBem.element('formCol')} ${bem.element('tab', {
            hide: this.currentTab !== ALL_IN_ONE_TAB,
          })}`}
        >
          <TextInput
            name="email"
            placeholder={localeData.printCmsText('ENTER_EMAIL_ADDRESS')}
          />
        </div>
        {!!passengers && (
          <div
            className={bem.element('tab', {
              hide: this.currentTab !== INDIVIDUAL_TAB,
            })}
          >
            <div className={modalBem.element('tabDescription')}>
              {localeData.printCmsText('INDIVIDUAL_DESCRIPTION')}
            </div>
            <FieldArray
              name="emails"
              component={RenderEmails}
              cmsKeys={cmsKeys}
              passengers={passengers}
              withRef
              ref="emailsArray"
            />
          </div>
        )}
      </div>
    );
  }

  render() {
    const {
      successMail,
      submitMailForm,
      sendMailLoader,
      handleSubmit,
      localeData,
    } = this.props;

    const isShowingTabs = this.isShowingTabs();

    return (
      <div className={modalBem.block()}>
        <div className={modalBem.element('title')}>
          {localeData.printCmsText('CMS_CHECKIN_MAIL_TITLE')}
        </div>
        {successMail && (
          <div>
            <i className={modalBem.element('successIcon')} />
            <div className={modalBem.element('text')}>
              {localeData.printCmsText('CMS_CHECKIN_MAIL_SUCCESS_TEXT')}
            </div>
          </div>
        )}

        {!successMail && (
          <div>
            {isShowingTabs && (
              <div className={modalBem.element('tabs')}>
                <div
                  className={modalBem.element('tab', {
                    isActive: this.currentTab === ALL_IN_ONE_TAB,
                  })}
                  onClick={() => (this.currentTab = ALL_IN_ONE_TAB)}
                >
                  {localeData.printCmsText('ALL_IN_ONE')}
                </div>
                <div
                  className={modalBem.element('tab', {
                    isActive: this.currentTab === INDIVIDUAL_TAB,
                  })}
                  onClick={() =>
                    this.currentTab !== INDIVIDUAL_TAB &&
                    (this.currentTab = INDIVIDUAL_TAB)
                  }
                >
                  {localeData.printCmsText('INDIVIDUAL')}
                </div>
              </div>
            )}

            <form
              ref="mailBoardingPassForm"
              className={modalBem.element('formRoot')}
              onSubmit={handleSubmit(submitMailForm)}
            >
              <div className={modalBem.element('mailForm')}>
                {this.renderInputs()}
              </div>
              <div className={modalBem.element('buttonRow')}>
                <button
                  className={modalBem.element('sendButton', {
                    disabled: sendMailLoader,
                  })}
                  ref="submitButton"
                  type="submit"
                >
                  <span>
                    {localeData.printCmsText('CMS_CHECKIN_MAIL_BUTTON')}
                  </span>
                </button>
              </div>
            </form>
          </div>
        )}
        <div className={`local-loader ${sendMailLoader ? 'active' : ''}`} />
      </div>
    );
  }
}
