import * as React from 'react';
import * as ReactDOM from 'react-dom';
import localStorageHelper from '_helpers/localStorage';
import StaticErrorPage from 'check-in/components/staticErrorPage';
import { createSelector } from 'reselect';
import { IBookingAuthSrcData, IPaxReferenceData } from 'types/backendProtocol';
import { IDataContainer } from 'types/datacontainer';
import {
  IPaxshopAppContext,
  IPaxshopBootstrapStatus,
  IPaxshopData,
  IPaxshopInitParams,
} from 'types/init';
import { IPaxshopReduxStore, IStoreState } from 'types/store';
import { BootstrapError } from './components/bootstrapError';
import { selectIfCMSLoadedCompletely } from '../languages/selectors';

export const bootstrapStatusSelector = createSelector(
  (state: IStoreState) => state.auth.authData,
  (state: IStoreState) => state.init.paxshopData,
  selectIfCMSLoadedCompletely,
  (authData, shopData, isCmsLoaded): IPaxshopBootstrapStatus => {
    if (
      shopData.availability.pending ||
      !isCmsLoaded ||
      authData.availability.pending
    ) {
      return {
        pending: true,
        shopInitError: null,
      };
    }

    return {
      pending: false,
      shopInitError:
        shopData.availability.errors && shopData.availability.errors.length > 0
          ? shopData.availability.errors[0]
          : null,
    };
  }
);

export function createBootstrapStatusPromise(
  store: IPaxshopReduxStore
): Promise<IPaxshopBootstrapStatus> {
  return new Promise((resolve, reject) => {
    let oldData = bootstrapStatusSelector(store.getState());
    if (!oldData.pending) {
      resolve(oldData);
      return;
    }

    const unsubscribe = store.subscribe(() => {
      const newData = bootstrapStatusSelector(store.getState());
      if (oldData === newData) {
        return;
      }

      oldData = newData;
      if (!newData.pending) {
        unsubscribe();
        resolve(newData);
      }
    });
  });
}

export function getSrcAuthData(
  initParams: IPaxshopInitParams,
  appCtx: IPaxshopAppContext
): { srcAuthData: IBookingAuthSrcData; failOnAuthError: boolean } {
  let srcAuthData: IBookingAuthSrcData = null;
  const failOnAuthError = false;
  let refData: IPaxReferenceData = null;

  const bookingSrcData = localStorageHelper.getBookingSrcData();
  if (bookingSrcData) {
    srcAuthData = Object.assign({}, bookingSrcData, { referenceData: null });
    refData = localStorageHelper.getReferenceData() || null;
  } else {
    refData = initParams.referenceData;
  }

  const paxPartner = appCtx.paxPartner;

  if (paxPartner) {
    if (!paxPartner.lastName && !initParams.deepLink) {
      srcAuthData = null; // dont login if we dont have lastname
    } else {
      srcAuthData = Object.assign({}, srcAuthData, {
        resellerData: paxPartner.resellerData,
        lastname: paxPartner.lastName,
      });
    }
  }

  if (initParams.deepLink) {
    srcAuthData = Object.assign({}, srcAuthData, initParams.deepLink, {
      referenceData: null,
    });
  }

  if (srcAuthData && refData) {
    srcAuthData.referenceData = refData;
  }

  return { srcAuthData, failOnAuthError };
}

export function displayBootstrapError(errorText: string | null): void {
  const rootDomNode = document.getElementById('appRoot');
  const bootstrapError = React.createElement(BootstrapError as any, {
    errorText,
  });

  ReactDOM.render(bootstrapError, rootDomNode);
}

export function displayStaticErrorPage(store: IPaxshopReduxStore): void {
  const rootDomNode = document.getElementById('appRoot');
  const bootstrapError = React.createElement(StaticErrorPage as any, { store });

  ReactDOM.render(bootstrapError, rootDomNode);
}
