class PassengersList {
  constructor(list) {
    this.list = list;
  }

  getPassengerById(passengerId) {
    return this.list.find(passenger => passenger.passengerId === passengerId);
  }
}

export default PassengersList;
