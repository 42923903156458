import { InferValueTypes } from '../../types/store';
import * as actions from '../actions';
import { IPaymentVariant } from '../types';

type IActionTypes = ReturnType<InferValueTypes<typeof actions>>;

const defaultState = null as IPaymentVariant;

export default function(state = defaultState, action: IActionTypes) {
  if (action.type === 'SET_CURRENT_PAY_VARIANT') {
    return action.payVariant;
  }
  return state;
}
