import React, { useEffect, useState } from 'react';
import { Bem } from 'react-bem-classes';
import { connect } from 'react-redux';
import { fetchMessages, Message } from '../../api';
import { IStoreState } from '../../../types/store';
import { paidOrdersSelector } from '../../../payment/selectors';
import './index.scss';
import { LoungePass } from './LoungePass';
import { findLoungePasses } from './helpers';

const bem = new Bem('myBookingFabMessages');

const mapStateToProps = (state: IStoreState) => ({
  orders: paidOrdersSelector(state),
});

type IProps = ReturnType<typeof mapStateToProps>;

function Messages({ orders }: IProps) {
  const [messages, setMessages] = useState<Message[]>([]);

  const loungePasses = findLoungePasses(messages, orders);

  useEffect(() => {
    fetchMessages().then(fetchedMessages => setMessages(fetchedMessages));
  }, []);

  return (
    <div className={bem.block()}>
      {loungePasses.map(pass => (
        <LoungePass item={pass} />
      ))}
    </div>
  );
}

export default connect(mapStateToProps)(Messages);
