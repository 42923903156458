import { ICMSEntry } from 'types/languages';
import { appConfig } from '../../config';

const TRANSLATIONS_EXCEPTIONS = [
  'ALL_PAGES_FOOTER',
  'HOME_TEXT',
  'ALL_PAGES_LANGUAGE_SELECTOR_TEXT',
  'HOME_HEAD',
  'HOME_TEXT',
  'LOGIN_PAGE_BACKGROUND_IMAGE_URL',
  'HEADER_BACKGROUND_IMAGE_URL',
  'LOGIN_PAGE_FAQ_EXTRA_BUTTON_TEXT',
  'LOGIN_PAGE_HEADING',
  'LOGIN_PAGE_LOGIN_BLOCK_TEXT',
  'LOGIN_PAGE_LOGIN_BLOCK_BOOKING_NR_TEXT',
  'LOGIN_PAGE_LOGIN_BLOCK_BUTTON_TEXT',
  'LOGIN_PAGE_LOGIN_BLOCK_DATE_INPUT_TEXT',
  'LOGIN_PAGE_LOGIN_BLOCK_DATE_TEXT',
  'LOGIN_PAGE_LOGO_URL',
  'LOGIN_PAGE_TOP_LOGO_URL',
  'LOGIN_PAGE_TOP_LOGO_MOBILE_HEIGHT_PX',
  'LOGIN_PAGE_TOP_LOGO_TABLET_HEIGHT_PX',
  'LOGIN_PAGE_TOP_LOGO_HEIGHT_PX',
  'LOGIN_PAGE_SUB_HEADING',
  'TITLE_PAGE_HOME',
  'CONFIRM_SHARE_FORM_TITLE',
  'CONFIRM_SHARE_DEFAULT_TEXT',
  'CONFIRM_SHARE_URL',
];

const VERY_SPECIAL_EXCEPTIONS = [
  'CMS_CONTACTINFO_PANEL_TC_TEXT',
  'CONTACT_INFO_TEXT',
  'CONTACT_INFO_PHONE',
  'ALL_PAGES_FAQ',
];

export function arrayToObject(array: ICMSEntry[]): { [key: string]: string } {
  return array.reduce(
    (map, item) => {
      map[item.key] = item.text;
      return map;
    },
    {} as { [key: string]: string }
  );
}

export function merge(
  carrierKeys: ICMSEntry[],
  shopKeys: ICMSEntry[],
  doubleBranded: boolean
): { [key: string]: string } {
  const shopCMSMap = arrayToObject(shopKeys);
  const carrierCMSMap = arrayToObject(carrierKeys);

  const exceptions =
    !doubleBranded && appConfig.preferShopForInformationCMS
      ? TRANSLATIONS_EXCEPTIONS.concat(VERY_SPECIAL_EXCEPTIONS)
      : TRANSLATIONS_EXCEPTIONS;

  const highPriorityShopKeys = shopKeys.filter(item =>
    exceptions.includes(item.key)
  );

  const highPriorityShopCMSMap = arrayToObject(highPriorityShopKeys);

  return Object.assign({}, shopCMSMap, carrierCMSMap, highPriorityShopCMSMap);
}
