import { combineReducers } from 'redux';
import languages from 'languages/reducers';
import payment from 'payment/reducers';
import init from 'init/reducers';
import auth from 'authorization/reducers';
import norec from 'norec/reducers';
import { reducer as form } from 'redux-form';
import routing from 'routing/reducers';
import basket from 'basket/reducers';
import confirmation from 'confirmation/reducers';
import apis from 'apis/reducers';
import checkIn from 'check-in/reducers';
import passengers from './passengers/reducers';
import segments from './segments/reducers';
import seating from './seatingWidget/reducers';
import services from './services/reducers';
import seatingReducer from './pageSeating/reducer';

export default combineReducers({
  services,
  seating,
  segments,
  passengers,
  languages,
  payment,
  init,
  auth,
  norec,
  form,
  routing,
  basket,
  confirmation,
  apis,
  checkIn,
  seatingReducer,
});
