import './index.scss';
import React from 'react';
import { Bem } from 'react-bem-classes';
import { filterInputProps } from '_helpers/reduxFormHelpers';

export class SelectInput2 extends React.Component {
  render() {
    const {
      input,
      meta: { touched, error, invalid, active },
      getErrorMsg,
      disabled,
      options,
      getOptionId,
      getOptionText,
      allowEmptyValue,
      emptyIfDisabled,
      placeholder,
      ...rest
    } = this.props;

    const addEmptyOption = allowEmptyValue || (disabled && emptyIfDisabled);

    const shouldDisplayError = touched && invalid;

    const isEmpty = !input.value || (emptyIfDisabled && disabled);
    const bem = new Bem('selectInput2');

    return (
      <div ref="root" className={bem.block()}>
        <div
          className={`${bem.element('inputBlock', {
            hasValue: !isEmpty,
            active,
            error: !!(shouldDisplayError && error),
          })} uiInput2`}
        >
          <select
            className={`${bem.element('input')}`}
            disabled={disabled}
            {...filterInputProps(input)}
            value={disabled && emptyIfDisabled ? '' : input.value}
          >
            {!addEmptyOption ? null : <option key="__default" value="" />}
            {disabled && emptyIfDisabled
              ? null
              : options.map(op => (
                  <option key={getOptionId(op)} value={getOptionId(op)}>
                    {getOptionText(op)}
                  </option>
                ))}
          </select>
          <span className={bem.element('arrow')} />
          <label>{rest.label}</label>
        </div>
        <span className={bem.element('errorMessage')}>
          {shouldDisplayError && error ? getErrorMsg(error) : ' '}
        </span>
      </div>
    );
  }
}
