import React from 'react';
import { Modal } from 'layouts/modal';
import BoardingPassesDownload from 'check-in/containers/boardingPasses';
import modalDecorator from '_helpers/modalDecorator';

@modalDecorator
export class BoardingPassesDialog extends React.Component {
  render() {
    return (
      <Modal
        ref="modal"
        newDesign
        plain
        mobileFullScreen
        popupRootClass="boardingPassesDownloadPopup"
      >
        <BoardingPassesDownload />
      </Modal>
    );
  }
}
