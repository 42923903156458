import { createSelector } from 'reselect';
import { selectFabScopes } from './fab';
import { IApiService } from '../../types/services';

export const selectServiceFinder = createSelector(
  state => state.services.list,
  selectFabScopes,
  (retailServices, fabScopes) => {
    const mapById = new Map<number | string, Map<number, IApiService>>();

    const allServices: IApiService[] = [...retailServices];
    Object.values(fabScopes).forEach(scope => {
      allServices.push(...scope.services);
    });

    allServices.forEach(service => {
      if (mapById.has(service.serviceId)) {
        const mapBySegment = mapById.get(service.serviceId);
        mapBySegment.set(service.segmentId, service);
      } else {
        const mapBySegment = new Map<number, IApiService>();
        mapBySegment.set(service.segmentId, service);
        mapById.set(service.serviceId, mapBySegment);
      }
    });

    return (serviceId: number | string, segmentId: number) =>
      mapById.get(serviceId)?.get(segmentId);
  }
);
