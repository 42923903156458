import { IPassenger } from '../../types/passengers';

export function switchPassenger(passengerId) {
  return {
    type: 'SWITCH_PASSENGER',
    passengerId,
  };
}

export function attachInfant(infantToAdultSrc) {
  return {
    type: 'ATTACH_INFANT_SUCCESS',
    infantToAdultSrc,
  };
}

export function setPassengerList(list: IPassenger[]) {
  return {
    type: 'PASSENGERS/SET_LIST',
    payload: list,
  };
}
