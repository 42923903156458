import './index.scss';
import React from 'react';
import { Bem } from 'react-bem-classes';
import { filterInputProps } from '_helpers/reduxFormHelpers';

export class TextInput2 extends React.Component {
  render() {
    const {
      input,
      meta: { touched, error, invalid, active },
      getErrorMsg,
      emptyIfDisabled,
      onFieldRendered,
      ...rest
    } = this.props;

    const shouldDisplayError = touched && invalid;

    const isEmpty = !input.value;

    const bem = new Bem('textInput2');
    return (
      <div ref="root" className={bem.block()}>
        <div
          className={`${bem.element('inputBlock', {
            hasValue: !isEmpty,
            active,
            error: !!(shouldDisplayError && error),
          })} uiInput2`}
        >
          <input
            type="text"
            className={bem.element('input')}
            disabled={rest.disabled}
            {...filterInputProps(input)}
            value={rest.disabled && emptyIfDisabled ? '' : input.value}
          />
          <label>{rest.label}</label>
        </div>
        <span className={bem.element('errorMessage')}>
          {shouldDisplayError && error ? getErrorMsg(error) : ' '}
        </span>
      </div>
    );
  }
}
