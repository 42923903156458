import React from 'react';
import { connect } from 'react-redux';
import { allCMSKeysRawSelector } from 'languages/selectors';
import BackgroundImage from '_helpers/backgroundImage';
import { IStoreState } from '../types/store';
import { ICMSKeysMap } from '../types/languages';

interface IProps {
  cmsKeys: ICMSKeysMap;
  cmsKey: string;
  children: JSX.Element;
}

function BackgroundImageFromCMS(props: IProps) {
  const { cmsKeys, cmsKey, children, ...rest } = props;

  return (
    <BackgroundImage {...rest} src={cmsKeys[cmsKey]}>
      {children}
    </BackgroundImage>
  );
}

export default connect(
  (state: IStoreState) => ({
    cmsKeys: allCMSKeysRawSelector(state),
  }),
  {}
)(BackgroundImageFromCMS);
