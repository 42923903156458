import * as actions from './actions';
import { InferValueTypes } from '../types/store';
import { PAXSHOP_LOGOUT_ACTION_ID } from '../authorization/actions/constants';

type IActionTypes =
  | ReturnType<InferValueTypes<typeof actions>>
  | { type: typeof PAXSHOP_LOGOUT_ACTION_ID };

export interface ISeatingReducerState {
  suggestedSeats: {
    [segment: number]: { passengerId: number; seatNumber: string }[];
  };
}

const initialState: ISeatingReducerState = {
  suggestedSeats: {},
};

export default function seating(state = initialState, action: IActionTypes) {
  if (action.type === 'SEATING/SET_SUGGESTED_SEATS') {
    return {
      ...state,
      suggestedSeats: {
        ...state.suggestedSeats,
        [action.payload.segmentId]: action.payload.seats,
      },
    };
  }

  if (action.type === PAXSHOP_LOGOUT_ACTION_ID) {
    return {
      ...state,
      suggestedSeats: {},
    };
  }

  return state;
}
