import './index.scss';

import * as React from 'react';
import bem from 'react-bem-classes';
import MediaQuery from 'react-responsive';

import modalDecorator from '_helpers/modalDecorator';
import { Text } from 'languages/components/text';
import HtmlText from 'languages/components/htmlText';

import { Modal } from 'layouts/modal';
import Image from 'languages/components/image';
import Link from 'languages/components/link';
import { IGTMPromotion, googleTagManager } from '_helpers/googleTagManager';

export interface VaziloBannerModalProps {}
export interface ValizoBannerModal {
  block(mods?: any): string;
  element(elName: string, mods?: any): string;
}

const POINTS = [
  {
    headerText: 'VALIZO_1_POINT_HEADER',
    bodyHtml: 'VALIZO_1_POINT_BODY',
  },
  {
    headerText: 'VALIZO_2_POINT_HEADER',
    bodyHtml: 'VALIZO_2_POINT_BODY',
  },
  {
    headerText: 'VALIZO_3_POINT_HEADER',
    bodyHtml: 'VALIZO_3_POINT_BODY',
  },
];

interface AdvProps {
  index: number;
  headerText: string;
  bodyHtml: string;
}

interface Adv {
  block(mods?: any): string;
  element(elName: string, mods?: any): string;
}

@bem({
  block: 'valizoList',
})
class Adv extends React.Component<AdvProps, undefined> {
  render() {
    const { index, headerText, bodyHtml } = this.props;

    return (
      <div className={this.block()}>
        <div className={this.element('number')}>{index}</div>
        <div className={this.element('body')}>
          <div className={this.element('header')}>
            <Text>{headerText}</Text>
          </div>
          <HtmlText className={this.element('text')}>{bodyHtml}</HtmlText>
        </div>
      </div>
    );
  }
}

const promotion: IGTMPromotion = {
  name: 'VaziloBanner',
};

@bem({
  block: 'vaziloBannerModal',
})
@modalDecorator
export class ValizoBannerModal extends React.Component<
  VaziloBannerModalProps,
  undefined
> {
  // TODO: Fix, this is always fired!
  // componentDidMount() {
  //   googleTagManager.pushToDataLayer({
  //     event: 'promotionView',
  //     ecommerce: {
  //       promoView: {
  //         promotions: [promotion]
  //       }
  //     }
  //   });
  // }

  goToSite() {
    console.log('send', 'event', 'Page', 'Baggage', 'Valizo banner click');
    googleTagManager.pushToDataLayer({
      event: 'promotionClick',
      ecommerce: {
        promoClick: {
          promotions: [promotion],
        },
      },
    });
  }

  render() {
    return (
      <Modal ref="modal" plain popupRootClass="vaziloModal">
        <div className={this.block()}>
          <div className={this.element('header')}>
            <Text className={this.element('title')}>VALIZO_MODAL_TITLE</Text>
            <div className={this.element('logo')}>
              <Text className={this.element('provided')}>
                VALIZO_PROVIDED_BY
              </Text>
              <Text className={this.element('valizo')}>VALIZO_VALIZO</Text>
            </div>
          </div>
          <div className={this.element('body')}>
            <MediaQuery query={SCREENS_MEDIA.portraitTabletAndLarger}>
              <Image className={this.element('image')}>VALIZO_MODAL_IMG</Image>
            </MediaQuery>
            <MediaQuery query={SCREENS_MEDIA.mobileAndSmaller}>
              <Image className={this.element('image')}>
                VALIZO_MODAL_MOBILE_IMG
              </Image>
            </MediaQuery>

            <div className={this.element('content')}>
              <div className={this.element('valizoList')}>
                {POINTS.map((point, index) => (
                  <Adv {...point} index={index + 1} key={index} />
                ))}
              </div>
              <div
                className={this.element('toSiteBtn')}
                onClick={this.goToSite}
              >
                <Link
                  className={this.element('vaziloLink')}
                  href="VALIZO_TO_SITE_HREF"
                >
                  VALIZO_TO_SITE_BTN
                </Link>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}
