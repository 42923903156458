import http from 'axios';
import { appConfig } from 'config';
import { clearBasket } from 'basket/actions';
import { confirmOrder, getOrders } from 'payment/actions';
import { goToPage } from 'routing/routing';
import { getAllServices } from 'services/actions';
import { store } from 'store';
import { googleTagManager } from '_helpers/googleTagManager';
import { fetchSeatingRelatedState } from '../pageSeating/actions';
import { selectJwtToken } from '../authorization/selectors';
import { IReqParams } from '../globals';
import { sleep } from '../_helpers/asyncSleep';

type PurchaseState = 'OPEN' | 'INVALIDATED' | 'PAYMENTS_PENDING' | 'PAID';
type FabStatus =
  | 'CREATED'
  | 'PROCESSING'
  | 'BOOKED'
  | 'FAILED'
  | 'REFUNDED'
  | 'REFUND_FAILED';

interface IPurchaseStatusResponse {
  purchaseId: number;
  state: PurchaseState;
  fab?: {
    status: FabStatus;
    basketId: string;
  };
}

function isPurchaseSucceed(status: IPurchaseStatusResponse) {
  if (!status.fab) {
    return status.state === 'PAID';
  }
  return status.state === 'PAID' && status.fab.status === 'BOOKED';
}

function getPurchaseStatus(purchaseId: string, jwt: string) {
  return http.get<IPurchaseStatusResponse>(
    `/enterprise-paxbox-rest/api/tools/payment/check/${purchaseId}`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    }
  );
}

async function waitForConfirm(purchaseId: string, jwt: string) {
  let answer = await getPurchaseStatus(purchaseId, jwt);

  while (answer.data.state === 'PAYMENTS_PENDING') {
    // eslint-disable-next-line no-await-in-loop
    await sleep(300);
    // eslint-disable-next-line no-await-in-loop
    answer = await getPurchaseStatus(purchaseId, jwt);
  }

  return answer;
}

export async function handlePaymentSuccess(reqParams: IReqParams) {
  const state = store.getState();
  if (reqParams.paymentLinkSent !== 'true') {
    const result = await waitForConfirm(
      reqParams.orderId,
      selectJwtToken(state)
    );
    if (!isPurchaseSucceed(result.data)) {
      window.paxPaymentFailed('Fab error');
      return;
    }
  }

  goToPage('CONFIRMATION', {
    orderId: `${reqParams.orderId}`,
    paymentLinkSent: `${reqParams.paymentLinkSent}`,
  });
  window.history.pushState(
    null,
    null,
    `${appConfig.baseUrl}confirmation?orderId=${reqParams.orderId}&paymentLinkSent=${reqParams.paymentLinkSent}`
  );

  store.dispatch(confirmOrder(reqParams.orderId));
  store.dispatch(clearBasket());
  store.dispatch(
    getOrders({ includeNotPaid: reqParams.paymentLinkSent === 'true' })
  );
  store.dispatch(getAllServices(state.auth.authData.data.bookingId));
  store.dispatch(fetchSeatingRelatedState());
  googleTagManager.pushToDataLayer({ event: 'paymentSuccess' });

  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}

/*
 * These functions will be called from payment iframe
 */
export function initBreakOut(): void {
  window.paxPaymentSuccess = handlePaymentSuccess;

  window.paxPaymentFailed = (reason: string) => {
    goToPage('PAYMENT_FAILED');
    googleTagManager.pushToDataLayer({ event: 'paymentFailure' }, { reason });
  };
}
