import http, { AxiosError } from 'axios';
import { appConfig } from 'config';
import { axiosConfigSelector } from 'authorization/selectors';
import { Dispatch } from 'react-redux';
import { makeRequest } from '_middlewares/http';
import { IStoreState } from 'types/store';
import { enhancedBasketSelector } from 'serviceInBasket/helpers/selectors';
import { compilePurchasesForBackend } from '../helpers';
import {
  IAvailableCountry,
  IPaymentVariant,
  ITermsAndConditionsEntry,
} from '../types';
import {
  backendErrorFromAxiosError,
  defaultBackendErrorHandler,
} from '../../_helpers/backendError';
import { goToPage } from '../../routing/routing';

interface IAPIToolsBasketResponse {
  psp: IPaymentVariant[];
  countries: IAvailableCountry[];
  basketId: string;
  termsAndConditions: ITermsAndConditionsEntry[];
}

interface IAPIToolsBasketFailResponse {
  cmsKeyForUserError: string;
  developerMessageError: string;
  errorCode: string;
}

interface IGetOrdersRequestOptions {
  includeNotPaid?: boolean;
}

export function fetchPaymentDetails() {
  return (dispatch: Dispatch<any>, getState: () => IStoreState) => {
    const state = getState();
    const basket = enhancedBasketSelector(state);
    const totalAmount = basket.totalPrice;

    dispatch(
      makeRequest<IAPIToolsBasketResponse>({
        method: 'POST',
        url: `/retail-gateway/api/tools/basket`,
        data: {
          purchases: compilePurchasesForBackend(basket),
          totalAmount,
        },
        onSuccess: fetchedPaymentData,
        onFailure: handlePaymentDataError,
      })
    );
  };
}

export function handlePaymentDataError(e: AxiosError) {
  const error = backendErrorFromAxiosError(e);
  defaultBackendErrorHandler(error);
  goToPage('CART');

  return {
    type: 'PAYMENT/ERROR_CREATING_BASKET',
    error,
  } as const;
}

export function fetchedPaymentData(response: IAPIToolsBasketResponse) {
  return {
    type: 'PAYMENT/FETCHED_PAYMENT_INFO',
    paymentMethods: response.psp.map(pv => ({
      ...pv,
      name: pv.name.toLowerCase(),
    })),
    paymentCountries: response.countries,
    termsAndConditions: response.termsAndConditions,
    basketId: response.basketId,
  } as const;
}

export function setCurrentPayVariant(payVariant: IPaymentVariant) {
  return {
    type: 'SET_CURRENT_PAY_VARIANT',
    payVariant,
  } as const;
}

export function getOrders(options?: IGetOrdersRequestOptions) {
  return (dispatch, getState) => {
    const state = getState();

    const bookingId = state.auth.authData.data
      ? state.auth.authData.data.bookingId
      : null;

    return http
      .get(
        `${appConfig.apiUrl}/bookings/${bookingId}/orders${
          options?.includeNotPaid ? '?includeNotPaid=true' : ''
        }`,
        axiosConfigSelector(getState())
      )
      .then(res => {
        dispatch(setOrders(res.data));
      });
  };
}

export function setOrders(orders) {
  return {
    type: 'SET_ORDERS',
    orders,
  } as const;
}

export function paymentInit() {
  return {
    type: 'PAYMENT_INIT',
  } as const;
}

export function confirmOrder(id) {
  return {
    type: 'CONFIRM_ORDER',
    id,
  } as const;
}

export function setPaymentMethod(payload: any) {
  return {
    type: 'PAYMENT_SUBMIT',
    payload,
  } as const;
}

export function fetchAvailableCountries() {
  return (dispatch, getState) => {
    dispatch({
      type: 'GET_CHECKOUT_COUNTRIES',
      promise: http.get(
        `${appConfig.apiUrl}/rest/psp/availableCountries`,
        axiosConfigSelector(getState())
      ),
    });
  };
}
