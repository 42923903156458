import { IPaxReferenceData } from 'types/backendProtocol';
import { appConfig } from 'config';
import { IServiceInBasket } from 'types/services';
import {
  ILocalStorageBookingSrcData,
  ILocalStorageState,
  IPaxPartnerData,
} from 'types/store';

const localStorageKey = appConfig.baseUrl;

function getLocalStorageData(): ILocalStorageState {
  const s = window.localStorage.getItem(localStorageKey);
  return s ? JSON.parse(s) : {};
}

function updateLocalStorageData(data: any): void {
  try {
    window.localStorage.setItem(localStorageKey, JSON.stringify(data));
  } catch (_ignored) {}
}

const localStorageHelper = {
  get: <T>(name: string) => getLocalStorageData()[name] as T,
  set: <T>(name: string, obj: T): void => {
    const data = getLocalStorageData();
    data[name] = obj;
    updateLocalStorageData(data);
  },
  remove: (name: string): void => {
    const data = getLocalStorageData();
    if (data.hasOwnProperty(name)) {
      delete data[name];
      updateLocalStorageData(data);
    }
  },

  resetAllButLanguage() {
    updateLocalStorageData({ language: this.getLanguage() });
  },

  // property-specific
  getLanguage: (): string => {
    const lang = getLocalStorageData().language;
    if (!lang) {
      return undefined;
    }

    if (typeof lang === 'string') {
      return lang;
    }
    if (lang.key && typeof lang.key === 'string') {
      return lang.key;
    }

    return undefined;
  },

  setLanguage(lang: string): void {
    this.set('language', lang);
  },

  getBookingSrcData(): ILocalStorageBookingSrcData {
    return getLocalStorageData().bookingSrcData;
  },

  setBookingSrcData(data: ILocalStorageBookingSrcData): void {
    this.set('bookingSrcData', data);
  },

  getPaxPartnerData(): IPaxPartnerData {
    return getLocalStorageData().paxPartnerData;
  },

  setPaxPartnerData(data: IPaxPartnerData): void {
    this.set('paxPartnerData', data);
  },

  getReferenceData(): IPaxReferenceData {
    return getLocalStorageData().referenceData;
  },

  setReferenceData(data: IPaxReferenceData) {
    this.set('referenceData', data);
  },

  getBasketKey() {
    return `basket_${JSON.stringify(this.getBookingSrcData()) || ''}`;
  },

  setBasket(data: IServiceInBasket[]) {
    this.set(this.getBasketKey(), data);
  },

  getBasket(): IServiceInBasket[] | null {
    return getLocalStorageData()[this.getBasketKey()] || null;
  },

  getPRMMessageReopenAt(): Date {
    return getLocalStorageData().prmMessageClosedTill || null;
  },

  setPRMMessageReopenAt(date: Date) {
    this.set('prmMessageClosedTill', date);
  },
};

export default localStorageHelper;
