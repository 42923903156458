import React from 'react';
import { Modal } from 'layouts/modal';

import './index.scss';
import { Text } from '../../../../languages/components/text';

export class CookieDialog extends React.Component {
  constructor() {
    super();
    this.state = {
      cookieLink: '',
    };
  }

  open(cookieLink) {
    if (cookieLink) {
      this.setState({ cookieLink });
    }

    return this.refs.modal.open();
  }

  render() {
    return (
      <Modal ref="modal" plain>
        <Text className="cookiePolicy" id="cookiePolicy">
          {this.state.cookieLink}
        </Text>
      </Modal>
    );
  }
}
