import React from 'react';
import { connect } from 'react-redux';
import { allCMSKeysRawSelector } from 'languages/selectors';

function mapStateToProps(state) {
  return {
    cmsKeys: allCMSKeysRawSelector(state),
  };
}

class Image extends React.Component {
  render() {
    const { children, cmsKeys, className } = this.props;

    const text = cmsKeys[children] !== undefined ? cmsKeys[children] : children;

    const imageStyle = {
      backgroundImage: `url(${text})`,
    };

    return <div className={className} data-cms={children} style={imageStyle} />;
  }
}

export default connect(mapStateToProps)(Image);
