import Moment from 'moment';

type ValidationResult = null | string;
export type Validator = (value) => string;

interface IValidationRules {
  [fieldName: string]: Validator[];
}

export function isValid(error): boolean {
  if (Array.isArray(error)) {
    return error.reduce(
      (valid, errorValue) => valid && isValid(errorValue),
      true
    );
  }
  if (error && typeof error === 'object') {
    return Object.keys(error).reduce(
      (valid, key) => valid && isValid(error[key]),
      true
    );
  }
  return !error;
}

export function validate(values, rules: IValidationRules) {
  return Object.keys(rules).reduce((currentErrors, fieldName) => {
    const validationResult = rules[fieldName].reduce(
      (res, validator) => res || validator(values[fieldName]),
      null
    );

    if (validationResult) {
      return { ...currentErrors, [fieldName]: validationResult };
    }

    return currentErrors;
  }, {});
}

export function validateNotEmpty(value): ValidationResult {
  return value ? null : 'CMS_REQUIRED_FIELD';
}

export function validatePositiveNumber(value: string): ValidationResult {
  return Number.isInteger(Number(value)) && Number(value) > 0
    ? null
    : 'CMS_VALIDATION_POSITIVE_NUMBER_EXPECTED';
}

export function validateEmail(value): ValidationResult {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(value) ? null : 'CMS_INVALID_EMAIL';
}

export function validateEmails(values): ValidationResult {
  if (values.filter(value => !!value).length === 0) {
    const result = values.map(value => '');
    result.pop();
    result.push('CMS_REQUIRED_FIELD');
    return result;
  }
  return values.map(value => (value ? validateEmail(value) : null));
}

export function validatePhone(value): ValidationResult {
  const re = /^[-0-9+ /\\(\\)]{3,15}$/;
  return re.test(value) ? null : 'CMS_INVALID_PHONE';
}

export function textLengthValidator(
  minLength: number,
  maxLength: number,
  errorCMS: string = 'CMS_VALIDATION_INVALID'
) {
  return function validateValue(value) {
    if (typeof value !== 'string') {
      return null;
    }

    return value.length < minLength || value.length > maxLength
      ? errorCMS
      : null;
  };
}

export function validateNotEmptyArray(value: unknown[]): ValidationResult {
  if (!Array.isArray(value))
    throw new Error(`Provided value ${value} is not an array`);

  return value.length > 0 ? null : 'CMS_VALIDATION_SELECT_AT_LEAST_ONE';
}

export function dateFormatValidator(dateFormat: string): Validator {
  return function validateDateFormat(value) {
    const processedDate = Moment(value, dateFormat).format(dateFormat);
    if (processedDate !== value) {
      let isValid = false;
      if (dateFormat.toString().trim() === 'M/D/YY') {
        isValid = Moment(value, 'MM/DD/YY').format('MM/DD/YY') === value;
      }
      if (dateFormat.toString().trim() === 'D.M.YYYY') {
        isValid = Moment(value, 'DD.MM.YYYY').format('DD.MM.YYYY') === value;
      }

      if (!isValid) return 'CMS_INVALID_DATE_FORMAT';
    }
    return null;
  };
}

export function regexValidator(regex, shouldMatch, errorMessage): Validator {
  return function validateRegex(value) {
    const matched = regex.test(value);

    if (matched !== shouldMatch) {
      return errorMessage;
    }

    return null;
  };
}

export function genericValidator<T>(
  test: (value: T) => boolean,
  errorMessage: string
): Validator {
  return function validateValue(value) {
    if (!test(value)) {
      return errorMessage;
    }
    return null;
  };
}

function isPrimitive(value): boolean {
  return (
    value == null || (typeof value !== 'function' && typeof value !== 'object')
  );
}

function traverseObject(object, context, visitor) {
  if (Array.isArray(object)) {
    return object.map(element => visitor.visitArrayElement(context, element));
  }
  if (isPrimitive(object)) {
    return visitor.visitPrimitive(context, object);
  }
  return Object.keys(object).reduce((res, key) => {
    const visitorRes = visitor.visitProperty(context, object[key], key);
    if (typeof visitorRes !== 'undefined') {
      res[key] = visitorRes;
    }
    return res;
  }, {});
}

/*
 values - redux form values
 errors - array of errors received from backend, for example:
 [
   {field: 'contactInfo.phone', message: 'CMS_ERROR_MESSAGE'},
   {field: 'collectorInfo.personalId', message: 'CMS_ERROR_MESSAGE'},
 ]
 mapValuePathToRequestJsonPath - as the name states

 returns object that has the same structure as 'values', with error messages on its leafs
 */
export function mapBackendErrorsToFields(
  values,
  errors,
  mapValuePathToRequestJsonPath
) /* : {validationErrors, unprocessedErrors} */ {
  const processedErrors = [];

  const visitor = {
    visitProperty(path, value, key) {
      const mappedPath = mapValuePathToRequestJsonPath(
        (path ? `${path}.` : '') + key
      );
      return traverseObject(value, mappedPath, this);
    },

    visitArrayElement(path, value, index) {
      return traverseObject(value, `${path}[${index}]`, this);
    },

    visitPrimitive(path) {
      const error = errors.find(err => err.field === path);
      if (error) {
        processedErrors.push(error);
        return error.message;
      }

      return undefined;
    },
  };

  const validationErrors = traverseObject(
    values,
    mapValuePathToRequestJsonPath(''),
    visitor
  );

  const overallError = errors.find(err => err.field === null);

  if (overallError) {
    validationErrors._error = overallError.message;
    processedErrors.push(overallError);
  }

  const unprocessedErrors = errors.filter(
    err => !processedErrors.includes(err)
  );
  if (!overallError && unprocessedErrors.length > 0) {
    validationErrors._error = unprocessedErrors[0].message;
  }

  return {
    validationErrors,
    unprocessedErrors,
  };
}

/*
 name,
 onBlur: createOnBlur(value => dispatch(blur(name, value)), {
 normalize: boundNormalize,
 parse: boundParse,
 after: asyncValidate.bind(null, name)
 }),
 onChange,
 onDragStart: createOnDragStart(name, fieldValue),
 onDrop: createOnDrop(name, boundChange),
 onFocus: createOnFocus(name, () => dispatch(focus(name))),
 value: format ? format(fieldValue, name) : fieldValue
 */

const INPUT_PROPS_NAMES = [
  'name',
  'onBlur',
  'onChange',
  'onDragStart',
  'onDrop',
  'onFocus',
  'value',
  'checked',
];

export function filterInputProps(props) {
  const inputProps = {};

  INPUT_PROPS_NAMES.forEach(prop => {
    if (Object.hasOwnProperty.call(props, prop)) {
      inputProps[prop] = props[prop];
    }
  });

  return inputProps;
}
