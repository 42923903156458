import { PAXSHOP_LOGOUT_ACTION_ID } from 'authorization/actions/constants';

const defaultState = {
  loading: true,
  seatsServices: [],
  passengers: [],
  boughtSeats: [],
  forceLoadCounter: 0,
};

export default function(state = defaultState, action) {
  switch (action.type) {
    case 'GET_SEATS_INFO_FROM_SEATING_WIDGET':
      return { ...defaultState, ...action.data, loading: false };
    case 'SWITCH_SEGMENT':
      return { ...defaultState, passengers: state.passengers };
    case 'FORCE_WIDGET_RELOAD':
      return { ...state, forceLoadCounter: state.forceLoadCounter + 1 };
    case PAXSHOP_LOGOUT_ACTION_ID:
      return defaultState;
    default:
      return state;
  }
}
