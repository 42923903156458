import * as actions from '../actions';
import { InferValueTypes } from 'types/store';
import { IPaymentVariant } from '../types';

type IActionTypes = ReturnType<InferValueTypes<typeof actions>>;

const defaultState: IPaymentVariant[] = [];

export default function servicesReducer(
  state = defaultState,
  action: IActionTypes
) {
  if (action.type === 'PAYMENT/FETCHED_PAYMENT_INFO') {
    return action.paymentMethods;
  }
  return state;
}
