import { createSelector } from 'reselect';
import { ICheckInSegment } from 'types/checkIn';
import { IStoreState } from 'types/store';

export const selectCheckInSegments = (state: IStoreState) => state.checkIn.data;

export const alreadyCheckedInSegmentsSelector = createSelector(
  selectCheckInSegments,
  (segments: ICheckInSegment[]) =>
    segments.filter(segment => segment.checkinStatus === 'ALREADY_CHECKED_IN')
);
