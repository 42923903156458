import * as segmentActions from 'segments/actions';
import {
  currentSegmentSelector,
  segmentsSelector,
} from 'segments/helpers/selectors';
import { store } from 'store';
import { ISegment } from 'types/segments';
import { IStoreState } from 'types/store';

class SegmentService {
  selectSegment(segmentId: number) {
    store.dispatch(segmentActions.switchSegment(segmentId));
  }

  selectAnotherSegment() {
    const state: IStoreState = store.getState();

    const segments = segmentsSelector(state);

    const currentSegment = currentSegmentSelector(state);
    this.selectSegment(
      segments.find(segment => segment.segmentId !== currentSegment.segmentId)
        .segmentId
    );
  }

  getSegmentById(segmentId): ISegment {
    return segmentsSelector(store.getState()).filter(
      s => s.segmentId === segmentId
    )[0];
  }
}
export const segmentService = new SegmentService();
