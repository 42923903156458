import React from 'react';
import bem from 'react-bem-classes';
import onClickOutside from 'react-onclickoutside';
import './index.scss';

@onClickOutside
@bem({
  block: 'fixedPositionTooltip',
})
export class FixedPostionTooltip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
    };
  }

  handleClickOutside() {
    this.setState({ visible: false });
  }

  showTooltip(showForElement) {
    const showForRect = showForElement.getBoundingClientRect();

    const viewportWidth = window.innerWidth;

    const viewportHeight = window.innerHeight;

    const tooltipElement = this.refs.root;

    const tooltipWidth = tooltipElement.clientWidth;

    const tooltipHeight = tooltipElement.clientHeight;

    let tooltipLeft;

    if (tooltipWidth + 30 > viewportWidth) {
      tooltipLeft = (viewportWidth - tooltipWidth) * 0.5;
    } else {
      tooltipLeft =
        showForRect.left + showForRect.width * 0.5 - tooltipWidth * 0.5;
      const excessLeft = tooltipLeft + tooltipWidth - (viewportWidth - 8);
      if (excessLeft > 0) {
        tooltipLeft -= excessLeft;
      }
    }

    // Top: try placing under the element
    let tooltipTop = showForRect.bottom + 5;
    const excessBottom = tooltipHeight + tooltipTop - (viewportHeight - 2);
    if (excessBottom > 0) {
      // try above
      const excessTop = showForRect.top - (tooltipHeight + 7);
      if (excessTop > 0) {
        tooltipTop -= excessBottom;
      } else {
        tooltipTop = showForRect.top - 5 - tooltipHeight;
      }
    }

    tooltipElement.style.top = `${tooltipTop}px`;
    tooltipElement.style.left = `${tooltipLeft}px`;

    this.setState({ visible: true });
  }

  hide = () => this.setState({ visible: false });

  render() {
    const { visible } = this.state;
    return (
      <div className={this.block({ hidden: !visible })} ref="root">
        <div className={this.element('closeButton')} onClick={this.hide} />
        {this.props.children}
      </div>
    );
  }
}
