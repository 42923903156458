class SegmentsList {
  constructor(list) {
    this.list = list;
  }

  getSegmentById(segmentId) {
    return this.list.find(segment => segment.segmentId === segmentId);
  }
}

export default SegmentsList;
