import cn from 'classnames';
import React from 'react';
import Spinner from 'react-spinner';
import './index.scss';

interface IProps {
  loading: boolean;
  children?: JSX.Element;
  className?: string;
}

export function Loader({ className }: { className?: string }) {
  return <Spinner className={cn('commonSpinner', className)} />;
}

export default function LoaderContainer({
  loading,
  children,
  className,
}: IProps) {
  if (loading) {
    return <Loader className={className} />;
  }

  return children;
}
