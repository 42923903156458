import { createSelector } from 'reselect';
import { IServiceInBasket } from '../../types/services';
import { selectServiceFinder } from '../../services/selectors/serviceFinder';

/*
 * Services in basket could be outdated when price changes
 * This way we merge basket services with up-to-date service
 * information in the store
 */

export const selectUpToDateBasket = createSelector(
  selectServiceFinder,
  state => state.basket,
  (findService, basket): IServiceInBasket[] =>
    basket.map(service => {
      const foundService = findService(service.serviceId, service.segmentId);
      return {
        ...service,
        ...foundService,
        quantity: service.quantity ? service.quantity : 1,
      };
    })
);
