import './index.scss';

import React from 'react';
import { connect } from 'react-redux';
import bem from 'react-bem-classes';

import { Text } from 'languages/components/text';
import { Modal } from 'layouts/modal';
import MailConfirmationModal from 'check-in/components/mailConfirmationModal';
import * as actions from 'check-in/actions';
import { allCMSKeysRawSelector } from 'languages/selectors';
@connect(
  state => ({
    cmsKeys: allCMSKeysRawSelector(state),
  }),
  dispatch => ({ dispatch })
)
@bem({
  block: 'currentOrdersList',
})
class CurrentOrdersList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMailLoader: false,
      successMail: null,
    };
  }

  // Mail form modal
  showMailModal() {
    this.mailModal.open();
    this.setState({
      successMail: false,
    });
  }

  submitMailForm = values => {
    this.setState({ sendMailLoader: true });

    this.props
      .dispatch(
        actions.emailConfirmation(values.email, this.props.currentLanguage)
      )
      .then(
        () => {
          this.setState({
            sendMailLoader: false,
            successMail: true,
          });
        },
        () => {
          this.setState({ sendMailLoader: false });
        }
      );
  };

  renderOrdersBySegment(orders, segment) {
    const { passengers, segments, cmsKeys } = this.props;

    const passengersById = {};
    passengers.forEach(
      passenger => (passengersById[passenger.passengerId] = passenger)
    );

    const segmentsById = {};
    segments.forEach(segment => (segmentsById[segment.segmentId] = segment));

    const ordersForSegment = orders.filter(
      order => order.segmentId === segment.segmentId && !order.journeyBased
    );

    return ordersForSegment.length > 0 ? (
      <section key={segment.direction}>
        {segment.direction === 'OUT' ? (
          <Text as="header" className={this.element('listHeader')}>
            ALL_PAGES_OUTBOUND_BLOCK_TEXT
          </Text>
        ) : (
          <Text as="header" className={this.element('listHeader')}>
            ALL_PAGES_INBOUND_BLOCK_TEXT
          </Text>
        )}
        <div className={this.element('list')} key={segment.segmentId}>
          {ordersForSegment.map(orderItem => (
            <div
              key={`${orderItem.serviceId}-${segment.segmentId}-${orderItem.passengerId}`}
              className={this.element('item')}
            >
              <Text
                useSpan
                fallback={orderItem.name}
                className={this.element('orderName')}
              >
                {orderItem.nameCmsKey}
              </Text>
              {orderItem.seatNumber && (
                <span className={this.element('orderSeatNumber')}>
                  {' '}
                  -{' '}
                  {orderItem.seatNumber.rowNumber +
                    orderItem.seatNumber.columnLetter}
                </span>
              )}
              {orderItem.serviceId === 3000 && ( // Bio Fuel
                <span>
                  &nbsp;{' '}
                  {orderItem.quantity +
                    ' ' +
                    (cmsKeys?.BIOFUEL_QTY_HOUR || 'hour')}
                </span>
              )}
              <div>
                <div className={this.element('segmentInfo')}>
                  {passengersById[orderItem.passengerId].firstName}{' '}
                  {passengersById[orderItem.passengerId].lastName}
                </div>
                <div className={this.element('segmentInfo')}>
                  Flight: {segmentsById[orderItem.segmentId].flightNumber}
                </div>
                <div className={this.element('segmentInfo')}>
                  Order: {orderItem.orderId}
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    ) : null;
  }

  renderJourneyBasedSegment(orders) {
    const { passengers, segments } = this.props;

    const passengersById = {};

    passengers.forEach(
      passenger => (passengersById[passenger.passengerId] = passenger)
    );

    const ordersForSegment = orders.filter(
      order =>
        order.journeyBased === true && order.segmentId === segments[0].segmentId
    );

    return ordersForSegment.length > 0 ? (
      <section>
        <Text as="header" className={this.element('listHeader')}>
          ALL_PAGES_JOURNEY_BASED_BLOCK_TEXT
        </Text>
        <div className={this.element('journeyBasedList')}>
          {ordersForSegment.map(orderItem => (
            <div key={orderItem.serviceId} className={this.element('item')}>
              <Text
                useSpan
                fallback={orderItem.name}
                className={this.element('orderName')}
              >
                {orderItem.nameCmsKey}
              </Text>
              <div>
                <div className={this.element('segmentInfo')}>
                  {passengersById[orderItem.passengerId].firstName}{' '}
                  {passengersById[orderItem.passengerId].lastName}
                </div>
                <div className={this.element('segmentInfo')}>
                  Order: {orderItem.orderId}
                </div>
              </div>
            </div>
          ))}
        </div>
      </section>
    ) : null;
  }

  render() {
    const { passengers, segments, localeData } = this.props;

    const { sendMailLoader, successMail } = this.state;

    const orders = this.props.orders
      .filter(
        order =>
          !order.passengerServiceCancelled && order.state !== 'PAYMENTS_PENDING'
      )
      .sort((o1, o2) => {
        if (o1.segmentId === o2.segmentId) {
          return o1.passengerId - o2.passengerId;
        }
        return o1.segmentId - o2.segmentId;
      });

    return orders.length === 0 ? null : (
      <div className={this.block()}>
        <header>
          <Text className={this.element('title')}>
            MY_BOOKING_PAGE_CURRENT_ORDERS
          </Text>
        </header>
        <div className={this.element('ordersFlexWrapper')}>
          {segments.map(segment => this.renderOrdersBySegment(orders, segment))}
        </div>
        {this.renderJourneyBasedSegment(orders)}
        <div className={this.element('footer')}>
          <Text
            className={this.element('reprintButton')}
            onClick={() => this.showMailModal()}
          >
            CMS_REPRINT_CONFIRMATION
          </Text>
        </div>

        <Modal
          ref={c => (this.mailModal = c)}
          newDesign
          plain
          popupRootClass="mailModalPopup"
        >
          <MailConfirmationModal
            sendMailLoader={sendMailLoader}
            successMail={successMail}
            submitMailForm={this.submitMailForm}
            localeData={localeData}
          />
        </Modal>
      </div>
    );
  }
}

export default CurrentOrdersList;
