const LOG_SIZE = 100;

// circular array
const logStorage = [];
let logStorageIndex = 0;
let logStorageFlipped = false;

export function getLastReduxActions() {
  let res = [];
  if (logStorageFlipped) {
    res = res.concat(logStorage.slice(logStorageIndex));
  }

  if (logStorageIndex > 0)
    res = res.concat(logStorage.slice(0, logStorageIndex));

  return res;
}

export default function loggerMiddleware() {
  return next => action => {
    const actionToLog = { _time: new Date().toISOString(), ...action };
    if (
      actionToLog.type === 'DATA_CONTAINER_UPDATE' &&
      actionToLog.containerId
    ) {
      if (
        actionToLog.containerId.startsWith('LANG::') &&
        actionToLog.containerId.indexOf('CMS') >= 0
      ) {
        actionToLog.container = {
          ...actionToLog.container,
          data: '<trimmed by logger>',
        };
      }
    }

    logStorage[logStorageIndex] = actionToLog;
    logStorageIndex++;

    if (logStorageIndex >= LOG_SIZE) {
      logStorageIndex = 0;
      logStorageFlipped = true;
    }

    return next(action);
  };
}
