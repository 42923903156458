import {DATA_CONTAINER_UPDATE_ACTION} from '../../_helpers/datacontainer/datacontainer';
import {PAXSHOP_LOGOUT_ACTION_ID} from 'authorization/actions/constants';

const defaultState = [];

export default function (state = defaultState, action) {
    switch (action.type) {
        case DATA_CONTAINER_UPDATE_ACTION:
            if (
                action.containerId === 'AUTH::AUTH_DATA' &&
                !action.container.availability.pending
            ) {
                return action.container.data && action.container.data.orders
                    ? action.container.data.orders
                        .reduce((previousValue, current, arrayIndex, array) => {
                            const index = previousValue
                                .map(o => o.id)
                                .indexOf(current.orderId);

                            const price = parseFloat(current.price);

                            if (index === -1) {
                                previousValue.push({
                                    id: current.orderId,
                                    date: current.date,
                                    total: price,
                                    currency: current.currency,
                                    state: current.state,
                                });
                            } else {
                                previousValue[index].total += price;
                            }

                            return previousValue;
                        }, [])
                        .sort((a, b) => b.id - a.id)
                    : [];
            }
            return state;

        case 'SET_ORDERS':
            return action.orders
                .reduce((previousValue, current, arrayIndex, array) => {
                    const index = previousValue.map(o => o.id).indexOf(current.orderId);

                    const price = parseFloat(current.price);

                    if (index === -1) {
                        previousValue.push({
                            id: current.orderId,
                            date: current.date,
                            total: price,
                            currency: current.currency,
                            state: current.state,
                        });
                    } else {
                        previousValue[index].total += price;
                    }

                    return previousValue;
                }, [])
                .sort((a, b) => b.id - a.id);

        case PAXSHOP_LOGOUT_ACTION_ID:
            return defaultState;

        default:
            return state;
    }
}
