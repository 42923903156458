import { Action, applyMiddleware, compose, createStore } from 'redux';
import { createEpicMiddleware } from 'redux-observable';
import rootReducer from './reducer';
import rootEpic from './epic';
import middlewares from './_middlewares';
import { IStoreState } from './types/store';

// Enable redux-dev-tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const epicMiddleware = createEpicMiddleware<Action, Action, IStoreState>();

const createStoreWithMiddlewares = composeEnhancers(
  applyMiddleware(...middlewares, epicMiddleware)
)(createStore);

export const store = createStoreWithMiddlewares(rootReducer);

epicMiddleware.run(rootEpic);
