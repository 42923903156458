import localStorageHelper from '_helpers/localStorage';
import * as basketActions from 'basket/actions';
import { store } from 'store';

class BasketModule {
  public init() {
    const savedBasket = localStorageHelper.getBasket();
    if (savedBasket) {
      store.dispatch(basketActions.addServicesToBasket(savedBasket));
    }
  }
}

export const basketModule = new BasketModule();
