import React from 'react';
import { connect } from 'react-redux';

function getDisplayName(component) {
  return component.displayName || component.name || 'Component';
}

function selectAuthData(state) {
  return state.auth.authData.data;
}

export function authOnly() {
  return function decorate(Component) {
    function Decorated({ authData, ...props }) {
      if (authData) {
        return <Component {...props} />;
      }

      return null;
    }

    Decorated.dispayName = `AuthOnly${getDisplayName(Component)}`;

    return connect(state => ({
      authData: selectAuthData(state),
    }))(Decorated);
  };
}
