import React from 'react';
import { Provider } from 'react-redux';
import { hot } from 'react-hot-loader/root';
import { store } from 'store';
import CheckInLiteWrapper from 'check-in/containers/checkInLiteWrapper';
import LocaleContext from 'languages/_helpers/localeContext';

function CheckInWidgetWrapper() {
  return (
    <Provider store={store}>
      <LocaleContext>
        <div className="paxshopApp__mainContent">
          <CheckInLiteWrapper />
        </div>
      </LocaleContext>
    </Provider>
  );
}

export default hot(CheckInWidgetWrapper);
