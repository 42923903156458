import './index.scss';
import React, { InputHTMLAttributes, useEffect, useRef } from 'react';
import { Bem } from 'react-bem-classes';
import {
  BaseFieldProps,
  Field,
  WrappedFieldMetaProps,
  WrappedFieldProps,
} from 'redux-form';
import { filterInputProps } from '_helpers/reduxFormHelpers';
import { useLocale } from '../../../languages/_helpers/localeContext';

const textInputBem = new Bem('textInput');
const textFieldBem = new Bem('field');

interface IProps {
  name: string;
  type?: string;
  small?: boolean;
  validate?: (val: string) => string | null;
  disabled?: boolean;
  emptyIfDisabled?: boolean;
  onFieldRendered?: (meta: WrappedFieldMetaProps, ref: HTMLDivElement) => any;
  placeholder?: string;
  rightAddon?: string;
}

function TextField(props: WrappedFieldProps & IProps) {
  const {
    input,
    meta: { touched, error, invalid },
    meta,
    placeholder,
    onFieldRendered,
    type = 'text',
    small,
    rightAddon,
    ...rest
  } = props;
  const { t } = useLocale();

  const rootRef = useRef(null);

  useEffect(() => {
    if (typeof onFieldRendered === 'function') {
      onFieldRendered(meta, rootRef.current);
    }
  }, []);

  const shouldDisplayError = touched && invalid;

  return (
    <div
      ref={rootRef}
      className={textFieldBem.block({ invalid: shouldDisplayError })}
      data-error={shouldDisplayError && error ? t(error) || error : ' '}
    >
      <div className={textInputBem.element('inputWrapper')}>
        <input
          {...rest}
          type={type}
          className={textInputBem.block({ small })}
          disabled={rest.disabled}
          placeholder={placeholder}
          {...filterInputProps(input)}
          value={rest.disabled && rest.emptyIfDisabled ? '' : input.value}
        />
        {rightAddon && (
          <div className={textInputBem.element('rightAddon')}>{rightAddon}</div>
        )}
      </div>
    </div>
  );
}

export default function TextInput(
  props: IProps & BaseFieldProps & InputHTMLAttributes<{}>
) {
  return <Field {...props} component={TextField} />;
}
