import http from 'axios';
import { appConfig } from 'config';
import { compileBasketForServiceContext } from 'payment/helpers';
import { IAuthBookingData, IAuthRes } from 'types/auth';
import {
  IAuthContext,
  IAuthResult,
  IBookingAuthSrcData,
  IBookingResponse,
  IFlightResponse,
  INorecAuthSrcData,
} from 'types/backendProtocol';
import { IServiceInBasket } from 'types/services';
import { IStoreState } from 'types/store';
import languages from '../../../languages.json';
import { createAxiosAuthConfig } from '../selectors';
import { selectUpToDateBasket } from '../../basket/selectors/selectBasket';

const CARRIERS_WITH_CENTERED_LOGOS = [
  97, // Evelop
];
const CARRIERS_WITH_SPECIAL_ALIGNED_LOGOS = {
  201: 5, // Jettime
  2: 4, // TUIFly
  194: 5, // Sunweb
  199: 5, // AirEuropa
  2745: 5, // DAT
  38: 5, // Novair
  2837: 9, // Airbatic
};

const CARRIERS_WITH_SPECIAL_ALIGNED_PORTRAIT_TABLET_LOGOS = {
  2: 8, // TUIFly
  2837: 12, // Airbatic
};

const CARRIERS_WITH_SPECIAL_ALIGNED_LANDSCAPE_TABLET_LOGOS = [
  38, // Novair
];

const CARRIERS_WITH_BOXED_LOGOS = [
  32, // SAS
  2653, // Atlantic Airways
  180, // BHAir
  185, // FreeBird
  9, // Aegean
  3, // BRA
];

export function authRequest(src: IBookingAuthSrcData & IAuthContext) {
  return http
    .post<IAuthResult>('/retail-gateway/api/v4/auth', src)
    .then(res => res.data);
}

function norecAuthRequest(src: INorecAuthSrcData & IAuthContext) {
  return http
    .post<IAuthResult>(`${appConfig.apiUrl}/v2/norec-auth`, src)
    .then(res => res.data);
}

function getBooking(
  bookingId: number,
  basket: IServiceInBasket[] = [],
  jwt: string
): Promise<IBookingResponse> {
  const basketInfo = compileBasketForServiceContext(
    basket.filter(s => s.ssrsAffectToSeats.length > 0)
  );

  return http
    .post(
      `${appConfig.apiUrl}/v2/bookings/${bookingId}?orders=true&checkin=true&services=true&apis=true&serviceTypes=true`,
      { services: basketInfo },
      createAxiosAuthConfig(jwt)
    )
    .then(res => res.data) as Promise<IBookingResponse>;
}

function getFlight(flightId: number, jwt: string): Promise<IFlightResponse> {
  return http
    .get(`${appConfig.apiUrl}/flights/${flightId}`, createAxiosAuthConfig(jwt))
    .then(res => res.data) as Promise<IFlightResponse>;
}

function getBookingLanguages(salesChannelId: string): Promise<string[]> {
  const relativeUrl = `/info/languages?salesChannelId=${encodeURIComponent(
    salesChannelId
  )}`;

  return http
    .get(appConfig.apiUrl + relativeUrl)
    .then(res => res.data.filter(l => languages.indexOf(l) !== -1)) as Promise<
    string[]
  >;
}

function postAuthSequence(
  authPromise: Promise<IAuthResult>,
  state: IStoreState
): Promise<IAuthRes> {
  let authRes: IAuthResult = null;
  let bookingRes: IBookingResponse = null;

  const bookingPromise = authPromise
    .then((auth: IAuthResult) => {
      authRes = auth;
      return getBooking(auth.bookingId, selectUpToDateBasket(state), auth.jwt);
    })
    .then((booking: IBookingResponse) => {
      bookingRes = booking;

      return Promise.all(
        booking.segments.map(seg =>
          Promise.all(
            seg.departureIds.map(depId => getFlight(depId, authRes.jwt))
          )
        )
      );
    })
    .then((_flights: any) => {
      const flights = _flights as IFlightResponse[][];
      let allFlights: IFlightResponse[] = [];
      for (let i = 0; i < flights.length; i++) {
        allFlights = allFlights.concat(flights[i]);
      }

      const extendedBooking: IAuthBookingData = {
        bookingNumber: bookingRes.bookingNumber,
        infantRefInfo: bookingRes.infantRefInfo,
      };
      // HACK: for smallplanet lastname
      if (authRes.departureDate === null) {
        authRes.departureDate = allFlights[0].departureDateTime.split(' ')[0];
      }

      return {
        cmsSalesChannelId: parseInt(authRes.cmsSalesChannelId, 10),
        currency: authRes.currency,
        jwt: authRes.jwt,
        bookingId: authRes.bookingId,
        booking: extendedBooking,
        flights: allFlights,
        languages: null,
        bookingNumber: authRes.bookingNumber,
        departureDate: authRes.departureDate,
        lastName: authRes.lastName,
        segments: bookingRes.segments.map((seg, i) =>
          Object.assign({}, seg, {
            flights: flights[i],
          })
        ),
        scopes: authRes.serviceSources.map(source => source.serviceSourceName),
        passengerList: bookingRes.passengerList,
        checkin: bookingRes.checkin || [],
        apis: bookingRes.apis || {},
        orders: bookingRes.orders || [],
        services: bookingRes.services || [],
        serviceTypes: bookingRes.serviceTypes,
        autoAllocationAllowed: authRes.autoAllocationAllowed,
        carrierHasMultipleLogos: authRes.hasMultipleLogos || false,
        carrierLogoCentered:
          CARRIERS_WITH_CENTERED_LOGOS.includes(authRes.carrierCustomerId) ||
          false,
        carrierLogoSpecAligned:
          CARRIERS_WITH_SPECIAL_ALIGNED_LOGOS[authRes.carrierCustomerId],
        carrierLogoLandscapeTabletSpecAligned:
          CARRIERS_WITH_SPECIAL_ALIGNED_LANDSCAPE_TABLET_LOGOS.includes(
            authRes.carrierCustomerId
          ) || false,
        carrierLogoPortraitTabletSpecAligned:
          CARRIERS_WITH_SPECIAL_ALIGNED_PORTRAIT_TABLET_LOGOS[
            authRes.carrierCustomerId
          ],
        carrierLogoBox:
          CARRIERS_WITH_BOXED_LOGOS.includes(authRes.carrierCustomerId) ||
          false,
      } as IAuthRes;
    });

  const langsPromise = authPromise.then((auth: IAuthResult) =>
    getBookingLanguages(auth.cmsSalesChannelId)
  );

  return Promise.all([bookingPromise, langsPromise]).then(
    (res: [IAuthRes, string[]]) =>
      Object.assign({}, res[0], { languages: res[1] }) as IAuthRes
  );
}

export function auth(src: IBookingAuthSrcData, state: IStoreState) {
  return postAuthSequence(
    authRequest({
      ...src,
      paxshopId: appConfig.paxshopId,
      referenceData: state.init.paxshopInitParams.referenceData,
    }),
    state
  );
}

export function norecAuth(src: INorecAuthSrcData, state: IStoreState) {
  return postAuthSequence(
    norecAuthRequest({
      ...src,
      paxshopId: appConfig.paxshopId,
      referenceData: state.init.paxshopInitParams.referenceData,
    }),
    state
  );
}
