import { forceWidgetReload } from 'seatingWidget/actions';
import { setCheckInStatuses } from 'check-in/actions';
import { ISegment } from 'types/segments';
import { makeRequest } from '_middlewares/http';
import { basketSeatingServicesSelector } from 'serviceInBasket/helpers/selectors';
import { removeServicesFromBasket } from 'basket/actions';
import { currentBookingId } from 'authorization/selectors';
import { setPassengerList } from 'passengers/actions';
import { appConfig } from 'config';

export interface ISuggestedSeat {
  passengerId: number;
  seatNumber: string;
}

interface ISuggestedSeatsResponse {
  segmentId: number;
  seats: ISuggestedSeat[];
}

export function suggestSeatsForSegment(segment: ISegment) {
  return makeRequest<ISuggestedSeatsResponse>({
    url: `${appConfig.apiUrl}/seating/suggestAndLockSeats`,
    data: { segmentId: segment.segmentId },
    method: 'POST',
    onSuccess: setSuggestedSeats,
  });
}

export function acceptSuggestedSeats(segment: ISegment) {
  return makeRequest({
    url: `${appConfig.apiUrl}/seating/acceptSuggestedSeats`,
    data: { segmentId: segment.segmentId },
    method: 'POST',
    onSuccess: (response: ISuggestedSeatsResponse) => dispatch => {
      dispatch(forceWidgetReload());
      dispatch(fetchSeatingRelatedState());
      dispatch(handleSuggestedSeatsAccepted(response));
    },
  });
}

export function fetchSeatingRelatedState() {
  return (dispatch, getState) => {
    const bookingNumber = currentBookingId(getState());

    dispatch(
      makeRequest({
        url: `${appConfig.apiUrl}/v2/bookings/${bookingNumber}?checkin=true`,
        method: 'POST',
        data: { services: [] },
        onSuccess: ({ passengerList, checkin }) => dispatch => {
          dispatch(setCheckInStatuses(checkin));
          dispatch(setPassengerList(passengerList));
        },
      })
    );
  };
}

export function handleSuggestedSeatsAccepted(
  response: ISuggestedSeatsResponse
) {
  return (dispatch, getState) => {
    const state = getState();
    const seatsInBasket = basketSeatingServicesSelector(state);
    const seatServicesToRemove = seatsInBasket.filter(
      seat => seat.segmentId === response.segmentId
    );

    dispatch(removeServicesFromBasket(seatServicesToRemove));

    dispatch({
      type: 'SEATING/SET_ACCEPTED_SEATS',
      payload: response,
    });
  };
}

export function setSuggestedSeats(response: ISuggestedSeatsResponse) {
  return {
    type: 'SEATING/SET_SUGGESTED_SEATS',
    payload: response,
  } as const;
}
