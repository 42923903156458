import { DATA_CONTAINER_UPDATE_ACTION } from '_helpers/datacontainer/datacontainer';
import { PAXSHOP_LOGOUT_ACTION_ID } from 'authorization/actions/constants';

const defaultState = [];

export default function(state = defaultState, action) {
  switch (action.type) {
    case 'SET_ORDERS':
      return action.orders;

    case DATA_CONTAINER_UPDATE_ACTION:
      if (
        action.containerId === 'AUTH::AUTH_DATA' &&
        !action.container.availability.pending
      ) {
        return action.container.data && action.container.data.orders
          ? action.container.data.orders
          : [];
      }
      return state;

    case PAXSHOP_LOGOUT_ACTION_ID:
      return defaultState;

    default:
      return state;
  }
}
