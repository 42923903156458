import './index.scss';
import { appConfig } from 'config';
import React from 'react';
import { connect } from 'react-redux';
import Page, { Header } from 'layouts/page';
import { segmentsSelector } from 'segments/helpers/selectors';
import { passengersSelector } from 'passengers/helpers/selectors';
import bem from 'react-bem-classes';
import MediaQuery from 'react-responsive';
import cn from 'classnames';
import * as actions from 'check-in/actions';
import * as paymentActions from 'payment/actions';
import { dateOptionsSelector, currentLangSelector } from 'languages/selectors';
import { DisplayTime } from '_helpers/displayDate';
import { Modal } from 'layouts/modal';
import CheckinModal from 'check-in/components/checkinModal';
import MailModal from 'check-in/components/mailModal';
import { localeDataSelector } from 'languages/selectors';
import { seatingOrdersSelector } from 'payment/selectors';
import PropTypes from 'prop-types';
import { alreadyCheckedInOrBoughtSeatsSegmentsSelector } from 'check-in/helpers/alreadyCheckinOrBoughtSelector';
import { selectCheckInSegments } from '../../helpers/selectors';
import { selectJwtToken } from '../../../authorization/selectors';
import { removeServicesFromBasket } from '../../../basket/actions';

@connect(
  state => ({
    segmentsList: segmentsSelector(state),
    passengersList: passengersSelector(state),
    seatingOrders: seatingOrdersSelector(state),
    checkIn: selectCheckInSegments(state),
    currentLanguage: currentLangSelector(state),
    enableNewCheckin:
      state.init.paxshopData.data.enableNewCheckin &&
      state.init.paxshopData.data.wizardMenu,
    selectedLanguage: state.languages.selectedLanguage,
    authData: state.auth.authData.data,
    jwtToken: selectJwtToken(state),
    paxshopDateFormat: dateOptionsSelector(state).paxshopDateFormat,
    localeData: localeDataSelector(state),
    alreadyCheckinOrBoughtSeats: alreadyCheckedInOrBoughtSeatsSegmentsSelector(
      state
    ),
    paymentLinkSent: state.routing.urlParams.paymentLinkSent,
    services: state.services.list,
    basket: state.basket,
  }),
  dispatch => ({ dispatch })
)
@bem({
  block: 'checkIn',
  modifiers: ['isTopSegmentBar', 'isSubMenu', 'hide', 'old'],
})
class CheckIn extends React.Component {
  static contextTypes = {
    openBoardingPassesPopup: PropTypes.func,
    doOpenBoardingPasses: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.state = {
      sendCheckinLoader: false,
      sendCheckinFailed: false,
      sendMailLoader: false,
      selectedSegment: null,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.checkIn !== nextProps.checkIn) {
      this.props.dispatch(
        paymentActions.getOrders({ includeNotPaid: this.props.paymentLinkSent })
      );
    }
  }

  sendCheckin(segmentId) {
    this.setState({ sendCheckinLoader: true });

    return this.props.dispatch(actions.checkInOnSegment(segmentId)).then(
      () => {
        this.setState({ sendCheckinLoader: false });
        this.props.dispatch(actions.getCheckInInfo());

        if (!this.props.enableNewCheckin) {
          this.checkinModal.close();
        }

        // Remove Fast Track if exist in basket
        const fastTrackInBasket = this.props.basket.filter(
          e => e.serviceCode === 'FT' && e.segmentId === segmentId
        );
        if (fastTrackInBasket.length) {
          this.props.dispatch(removeServicesFromBasket(fastTrackInBasket));
        }
      },
      () => {
        if (!this.props.enableNewCheckin) {
          this.checkinModal.close();
        }
        this.setState({ sendCheckinLoader: false, sendCheckinFailed: true });
      }
    );
  }

  showCheckinModal(segment) {
    this.checkinModal.open();
    this.setState({
      sendCheckinLoader: false,
      selectedSegment: segment,
    });
  }

  // Mail form modal
  showMailModal() {
    this.mailModal.open();
    this.setState({
      // mailModalVisible: false,
      successMail: false,
    });
  }

  submitMailForm(values) {
    this.setState({ sendMailLoader: true });

    const individualEmailData = [];
    if (!!values.emails && !!values.emails.length) {
      values.emails.forEach((email, index) => {
        if (email) {
          individualEmailData.push({
            email,
            passengerId: this.props.passengersList[index].passengerId,
            segments: ['OUT', 'IN'],
          });
        }
      });
    }

    (individualEmailData.length
      ? this.props.dispatch(
          actions.emailPkPass(individualEmailData, this.props.currentLanguage)
        )
      : this.props.dispatch(
          actions.emailBoardingPass(values.email, this.props.currentLanguage)
        )
    ).then(
      () => {
        this.setState({
          sendMailLoader: false,
          successMail: true,
        });
      },
      () => {
        this.setState({ sendMailLoader: false });
      }
    );
  }

  renderSegment = segment => {
    const {
      selectedLanguage,
      jwtToken,
      localeData,
      enableNewCheckin,
      alreadyCheckinOrBoughtSeats,
    } = this.props;

    const checkin = this.props.checkIn.find(
      c => c.segmentId === segment.segmentId
    );

    const direction =
      segment.direction === 'OUT'
        ? 'ALL_PAGES_OUTBOUND_BLOCK_TEXT'
        : 'ALL_PAGES_INBOUND_BLOCK_TEXT';

    const isSegmentDeparted =
      segment.cmsMessages.length > 0 &&
      segment.cmsMessages[0].indexOf(
        `CMS_FLIGHT_DEPARTED_${segment.direction}`
      ) !== -1;

    const departureTime = segment.flights[0].departureDateTimeMoment;

    const arrivalTime = segment.flights[0].arrivalDateTimeMoment;

    return (
      <div key={segment.segmentId} className={this.element('segment')}>
        <div
          className={this.element('status', {
            done: checkin.checkinStatus == 'ALREADY_CHECKED_IN',
            disabled:
              checkin.checkinStatus == 'CLOSED' ||
              checkin.checkinStatus == 'UNSUPPORTED' ||
              checkin.checkinStatus === 'AVAILABLE_WITH_APIS',
          })}
        >
          {localeData.printCmsText(
            isSegmentDeparted
              ? `${segment.cmsMessages[0]}_TITLE`
              : checkin.messageKey
          )}
        </div>
        <div className={this.element('segmentData')}>
          <div className={this.element('field', { date: true })}>
            <div className={this.element('fieldTitle')}>
              {localeData.printCmsText(direction)}
            </div>
            <div className={this.element('fieldValue')}>
              {localeData.formatMomentDate(
                segment.flights[0].departureDateTimeMoment
              )}
            </div>
          </div>
          <div className={this.element('field', { airport: true })}>
            <div className={this.element('fieldTitle')}>
              {localeData.printCmsText(
                'MY_BOOKING_PAGE_OUTBOUND_BLOCK_DEPARTURE_TEXT'
              )}
            </div>
            <div className={this.element('fieldValue')}>
              <DisplayTime inline utc date={departureTime}>
                {formattedDate => `${formattedDate} / `}
              </DisplayTime>
              {segment.flights[0].departureAirportName}
            </div>
          </div>
          <div className={this.element('field', { airport: true })}>
            <div className={this.element('fieldTitle')}>
              {localeData.printCmsText(
                'MY_BOOKING_PAGE_OUTBOUND_BLOCK_ARRIVAL_TEXT'
              )}
            </div>
            <div className={this.element('fieldValue')}>
              <DisplayTime inline utc date={arrivalTime}>
                {formattedDate => `${formattedDate} / `}
              </DisplayTime>
              {segment.flights[segment.flights.length - 1].arrivalAirportName}
            </div>
          </div>
          <div className={this.element('field', { flightNumber: true })}>
            <div className={this.element('fieldTitle')}>
              {localeData.printCmsText('FLIGHT_NUMBER')}
            </div>
            <div className={this.element('fieldValue')}>
              {segment.flights[segment.flights.length - 1].flightNumber}
            </div>
          </div>
          <div className={this.element('field', { button: true })}>
            <div className={this.element('fieldValue')}>
              <button
                onClick={() => this.showCheckinModal(segment)}
                className={this.element('btn', {
                  checkin: true,
                  hide:
                    checkin.checkinStatus === 'ALREADY_CHECKED_IN' ||
                    (checkin.checkinStatus === 'CLOSED' &&
                      alreadyCheckinOrBoughtSeats),
                  disabled:
                    checkin.checkinStatus === 'CLOSED' ||
                    checkin.checkinStatus === 'UNSUPPORTED' ||
                    checkin.checkinStatus === 'AVAILABLE_WITH_APIS',
                })}
              >
                Check-in
              </button>
              <a
                href={`${appConfig.apiUrl}/boarding-pass/download/${selectedLanguage}?jwt=${jwtToken}`}
                className={this.element('btn', {
                  download: true,
                  hide:
                    !checkin.allStatuses.includes('ALREADY_CHECKED_IN') ||
                    isSegmentDeparted,
                })}
                target="_blank"
              />
              <a
                onClick={this.showMailModal.bind(this)}
                className={this.element('btn', {
                  mail: true,
                  hide:
                    !checkin.allStatuses.includes('ALREADY_CHECKED_IN') ||
                    isSegmentDeparted,
                })}
              />
            </div>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      segmentsList,
      passengersList,
      seatingOrders,
      checkIn,
      localeData,
      enableNewCheckin,
      selectedLanguage,
      jwtToken,
      isTopSegmentBar,
      isSubMenu,
      services,
    } = this.props;

    const { doOpenBoardingPasses, openBoardingPassesPopup } = this.context;

    const {
      selectedSegment,
      sendCheckinLoader,
      sendCheckinFailed,
      sendMailLoader,
      successMail,
    } = this.state;

    if (checkIn.filter(c => c.checkinStatus !== 'UNSUPPORTED').length === 0)
      return null;

    const openCheckin = checkIn.find(c => c.checkinStatus === 'AVAILABLE');

    const alreadyCheckIn = checkIn.find(
      c => c.checkinStatus === 'ALREADY_CHECKED_IN'
    );

    const checkinAvailableSegmentIds = this.props.checkIn
      .filter(c => c.checkinStatus === 'AVAILABLE')
      .map(checkin => checkin.segmentId);

    const checkinAvailableSegments = segmentsList.filter(segment =>
      checkinAvailableSegmentIds.includes(segment.segmentId)
    );

    let openCheckinSegment = null;
    if (openCheckin) {
      openCheckinSegment = segmentsList.find(
        segment => segment.segmentId === openCheckin.segmentId
      );
    }

    return (
      <div className={this.block({ old: !enableNewCheckin })}>
        {!(isTopSegmentBar || isSubMenu) && (
          <Page>
            {!enableNewCheckin && (
              <Header>
                {localeData.printCmsText('MY_BOOKING_PAGE_CHECKIN_TITLE')}
              </Header>
            )}
            <div>
              {localeData.printCmsText(
                enableNewCheckin
                  ? 'MY_BOOKING_PAGE_BOARDING_PASSES_SUB_TITLE'
                  : 'MY_BOOKING_PAGE_CHECKIN_SUB_TITLE'
              )}
            </div>
            <div className={this.element('segments')}>
              {segmentsList.map(this.renderSegment)}
            </div>
          </Page>
        )}

        <Modal
          ref={c => (this.checkinModal = c)}
          newDesign
          mobileFullScreen
          popupRootClass="checkinModalPopup"
        >
          <CheckinModal
            sendCheckin={this.sendCheckin.bind(this)}
            jwtToken={jwtToken}
            selectedLanguage={selectedLanguage}
            showMailModal={this.showMailModal.bind(this)}
            selectedSegment={selectedSegment}
            sendCheckinLoader={sendCheckinLoader}
            sendCheckinFailed={sendCheckinFailed}
            localeData={localeData}
            enableNewCheckin={enableNewCheckin}
            checkinAvailableSegments={checkinAvailableSegments}
            boughtSeats={seatingOrders}
            passengersList={passengersList}
            services={services}
          />
        </Modal>

        <Modal
          ref={c => (this.mailModal = c)}
          newDesign
          popupRootClass="mailModalPopup"
        >
          <MailModal
            sendMailLoader={sendMailLoader}
            successMail={successMail}
            submitMailForm={this.submitMailForm.bind(this)}
            localeData={localeData}
            passengers={passengersList}
          />
        </Modal>

        {isTopSegmentBar &&
          enableNewCheckin &&
          openCheckin &&
          openCheckinSegment && (
            <MediaQuery query={SCREENS_MEDIA.mobileAndSmaller}>
              <div className={this.element('segmentBar', { mobile: true })}>
                <div className={this.element('barPaxIcon')} />
                <button
                  className={this.element('checkinButton', {
                    next: true,
                    mobile: true,
                  })}
                  onClick={() => {
                    this.showCheckinModal(openCheckinSegment);
                  }}
                >
                  {localeData.printCmsText('OPEN_CHECKIN_BUTTON_TEXT')}
                </button>
              </div>
            </MediaQuery>
          )}

        {isTopSegmentBar &&
          enableNewCheckin &&
          openCheckin &&
          openCheckinSegment && (
            <MediaQuery query={SCREENS_MEDIA.portraitTabletAndLarger}>
              <div
                className={this.element('segmentBar', {
                  hide: false, // openCheckin.checkinStatus === 'ALREADY_CHECKED_IN',
                  disabled:
                    openCheckin.checkinStatus === 'CLOSED' ||
                    openCheckin.checkinStatus === 'UNSUPPORTED',
                })}
              >
                <div className={this.element('barPaxIcon')} />
                {checkinAvailableSegments.length > 1
                  ? `${localeData.printCmsText(
                      'OPEN_CHECKIN_FIRST_AVAILABLE_SEGMENT_TITLE'
                    )}
                    (${checkinAvailableSegments
                      .map(
                        segment =>
                          `#${
                            segment.flights[segment.flights.length - 1]
                              .flightNumber
                          }`
                      )
                      .join(', ')})`
                  : `${localeData.printCmsText(
                      'OPEN_CHECKIN_ON_1_FLIGHT_TITLE'
                    )} (#${
                      openCheckinSegment.flights[
                        openCheckinSegment.flights.length - 1
                      ].flightNumber
                    })`}
                <button
                  className={this.element('checkinButton', { next: true })}
                  onClick={() => {
                    this.showCheckinModal(openCheckinSegment);
                  }}
                >
                  {localeData.printCmsText('CHECKIN_BUTTON_TEXT')}
                </button>
              </div>
            </MediaQuery>
          )}
      </div>
    );
  }
}

export default CheckIn;
