import React from 'react';
import { connect } from 'react-redux';
import {
  segmentsSelector,
  allSegmentsDepartedSelector,
} from 'segments/helpers/selectors';
import { passengersSelector } from 'passengers/helpers/selectors';
import bem from 'react-bem-classes';
import * as actions from 'check-in/actions';
import {
  dateOptionsSelector,
  currentLangSelector,
  localeDataSelector,
} from 'languages/selectors';
import { Modal } from 'layouts/modal';
import StaticErrorPage from 'check-in/components/staticErrorPage';
import CheckinModal from 'check-in/components/checkinModal';
import MailModal from 'check-in/components/mailModal';
import BoardingPassesDownload from 'check-in/containers/boardingPasses';
import { seatingOrdersSelector } from 'payment/selectors';
import { selectCheckInSegments } from '../../helpers/selectors';
import { selectJwtToken } from '../../../authorization/selectors';

@connect(
  state => ({
    segmentsList: segmentsSelector(state),
    passengersList: passengersSelector(state),
    seatingOrders: seatingOrdersSelector(state),

    checkIn: selectCheckInSegments(state),
    currentLanguage: currentLangSelector(state),
    queryParams: state.init.paxshopInitParams.queryParams,

    selectedLanguage: state.languages.selectedLanguage,
    authData: state.auth.authData.data,
    jwtToken: selectJwtToken(state),
    paxshopDateFormat: dateOptionsSelector(state).paxshopDateFormat,
    allSegmentsDeparted: allSegmentsDepartedSelector(state),
    localeData: localeDataSelector(state),
  }),
  dispatch => ({ dispatch })
)
@bem({
  block: 'checkInLite',
})
export default class CheckInLiteWrapper extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sendCheckinLoader: false,
      sendCheckinFailed: false,
      sendMailLoader: false,
      successCheckin: false,
    };
  }

  sendCheckin = segmentId => {
    this.setState({ sendCheckinLoader: true });

    return this.props.dispatch(actions.checkInOnSegment(segmentId)).then(
      () => {
        this.props.dispatch(actions.getCheckInInfo());
        this.setState({ sendCheckinLoader: false });
      },
      () => {
        this.setState({ sendCheckinLoader: false, sendCheckinFailed: true });
      }
    );
  };

  // Mail form modal
  showMailModal = () => {
    this.mailModal.open();
    this.setState({
      // mailModalVisible: false,
      successMail: false,
    });
  };

  submitMailForm = values => {
    this.setState({ sendMailLoader: true });
    this.props
      .dispatch(
        actions.emailBoardingPass(values.email, this.props.currentLanguage)
      )
      .then(
        () => {
          this.setState({
            sendMailLoader: false,
            successMail: true,
          });
        },
        () => {
          this.setState({ sendMailLoader: false });
        }
      );
  };

  onSuccessCheckin = () => {
    this.setState({ successCheckin: true });
  };

  render() {
    const {
      segmentsList,
      passengersList,
      seatingOrders,
      checkIn,
      allSegmentsDeparted,
      localeData,
      selectedLanguage,
      jwtToken,
      queryParams,
    } = this.props;

    const {
      sendCheckinLoader,
      sendCheckinFailed,
      sendMailLoader,
      successMail,
      successCheckin,
    } = this.state;

    const alreadyCheckIn = checkIn.find(
      c => c.checkinStatus === 'ALREADY_CHECKED_IN'
    );

    const checkinAvailableSegmentIds = checkIn
      .filter(c => c.checkinStatus === 'AVAILABLE')
      .map(checkin => checkin.segmentId);

    const checkinAvailableSegments = segmentsList.filter(segment =>
      checkinAvailableSegmentIds.includes(segment.segmentId)
    );

    const showErrorPage =
      allSegmentsDeparted ||
      (!alreadyCheckIn &&
        checkIn.length > 0 &&
        checkinAvailableSegments.length === 0 &&
        !successCheckin);
    return (
      <div className={this.block()}>
        {(checkinAvailableSegments.length > 0 || successCheckin) && (
          <CheckinModal
            sendCheckin={this.sendCheckin}
            jwtToken={jwtToken}
            selectedLanguage={selectedLanguage}
            showMailModal={this.showMailModal}
            selectedSegment={checkinAvailableSegments[0]}
            sendCheckinLoader={sendCheckinLoader}
            sendCheckinFailed={sendCheckinFailed}
            localeData={localeData}
            enableNewCheckin
            checkinAvailableSegments={checkinAvailableSegments}
            boughtSeats={seatingOrders}
            passengersList={passengersList}
            isMobileWidget
            onSuccessCheckin={this.onSuccessCheckin}
          />
        )}

        {alreadyCheckIn && !successCheckin && !showErrorPage && (
          <BoardingPassesDownload />
        )}
        {showErrorPage && <StaticErrorPage />}
        <Modal
          ref={c => (this.mailModal = c)}
          checkin
          popupRootClass="mailModalPopup"
        >
          <MailModal
            sendMailLoader={sendMailLoader}
            successMail={successMail}
            submitMailForm={this.submitMailForm}
            queryParamEmail={queryParams.email}
            localeData={localeData}
          />
        </Modal>
      </div>
    );
  }
}
