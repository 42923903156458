import React from 'react';
import bem from 'react-bem-classes';
import './index.scss';
import { connect } from 'react-redux';
import { Text } from 'languages/components/text';
import { Price } from 'commonBlocks/components/price';
import { allCMSKeysRawSelector } from 'languages/selectors';

@connect(state => ({
  cmsKeys: allCMSKeysRawSelector(state),
}))
@bem({
  block: 'orderModal',
})
export default class OrderModal extends React.Component {
  render() {
    const {
      ordersById,
      orderId,
      passengers,
      segments,
      localeData,
      cmsKeys,
    } = this.props;

    const passengersById = {};

    const segmentsById = {};
    for (const passenger of passengers) {
      passengersById[passenger.passengerId] = passenger;
    }
    for (const segment of segments) {
      segmentsById[segment.segmentId] = segment;
    }

    return (
      <div className={this.block()}>
        <div className={this.element('box')}>
          <div className={this.element('title')}>
            <Text useSpan>ORDER_POPUP_ORDER_NUMBER</Text>:{' '}
            <span className={this.element('titleValue')}>{orderId}</span>
          </div>
          <div className={this.element('subTitle')}>
            <Text useSpan>ORDER_POPUP_ORDER_DATE</Text>:
            <span className={this.element('titleValue')}>
              {localeData.formatMomentDate(
                ordersById.length > 0 ? ordersById[0].dateMoment : null
              )}
            </span>
          </div>
          <div className={this.element('list')}>
            {ordersById
              .reduce(mergeJourneyBased, [])
              .map((orderItem, index) => (
                <div className={this.element('item')} key={index}>
                  <div className={this.element('column')}>
                    <Text className={this.element('columnTitle')} useSpan>
                      ORDER_POPUP_SERVICE_INFO
                    </Text>
                    <div className={this.element('columnContent')}>
                      <Text useSpan fallback={orderItem.name}>
                        {orderItem.nameCmsKey}
                      </Text>
                      {orderItem.seatNumber && (
                        <span
                          className={this.element('columnContent', {
                            value: true,
                          })}
                        >
                          {' '}
                          -{' '}
                          {orderItem.seatNumber.rowNumber +
                            orderItem.seatNumber.columnLetter}
                        </span>
                      )}
                      {orderItem.serviceId === 3000 && ( // Bio Fuel
                        <span>
                          &nbsp;{' '}
                          {orderItem.quantity +
                            ' ' +
                            (cmsKeys?.BIOFUEL_QTY_HOUR || 'hour')}
                        </span>
                      )}
                      <div>
                        <div className={this.element('segmentInfo')}>
                          {passengersById[orderItem.passengerId].firstName}{' '}
                          {passengersById[orderItem.passengerId].lastName}
                        </div>
                        {!orderItem.journeyBased && (
                          <div className={this.element('segmentInfo')}>
                            Flight:{' '}
                            {segmentsById[orderItem.segmentId].flightNumber}
                          </div>
                        )}
                      </div>
                      {passengers.map(passenger => {
                        passenger.passengerId == orderItem.passengerId && (
                          <span
                            className={this.element('columnContent', {
                              value: true,
                            })}
                          >
                            - {passenger.firstName} {passenger.lastName}
                          </span>
                        );
                      })}
                    </div>
                  </div>
                  <div className={this.element('column')}>
                    <Text className={this.element('columnTitle')} useSpan>
                      ORDER_POPUP_SERVICE_PRICE
                    </Text>
                    <div className={this.element('columnContent')}>
                      <Price
                        price={orderItem.price * orderItem.quantity}
                        currency={orderItem.currency}
                      />
                    </div>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    );
  }
}

// Callback for .reduce method
// second reduce argument expected to be empty array
function mergeJourneyBased(acc, service) {
  if (!service.journeyBased) return [...acc, service];

  const existingServiceInOrder = acc.find(
    addedService =>
      addedService.serviceId === service.serviceId &&
      addedService.passengerId === service.passengerId
  );

  if (!existingServiceInOrder) {
    return [...acc, { ...service }];
  }

  existingServiceInOrder.price = (
    Number(existingServiceInOrder.price) + Number(service.price)
  ).toFixed(2);

  return acc;
}
