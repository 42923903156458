import { createSelector } from 'reselect';
import { passengersForSeatingPageSelector } from 'passengers/helpers/selectors';
import { selectCheckInSegments } from './selectors';

export const alreadyCheckedInOrBoughtSeatsSegmentsSelector = createSelector(
  selectCheckInSegments,
  passengersForSeatingPageSelector,
  (segments, passengers) =>
    segments.filter(segment =>
      segment.allStatuses.includes('ALREADY_CHECKED_IN')
    ).length > 0
);
