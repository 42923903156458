import { createSelector } from 'reselect';
import { IBackedCommError } from 'types/backendProtocol';
import { IDataAvailability, IDataContainer } from 'types/datacontainer';

export function createMemoizedAvailabilityAggregator(): (
  src: IDataAvailability[]
) => IDataAvailability {
  let lastSrcValues: IDataAvailability[] = [];

  let lastStatus: IDataAvailability = {
    pending: false,
    errors: [],
    lastSuccess: null,
  };

  const newValues: IDataAvailability[] = [];

  return (src: IDataAvailability[]) => {
    let valuesChanged = false;

    for (let i = 0; i < src.length; i++) {
      newValues[i] = src[i];
      if (newValues[i] !== lastSrcValues[i]) {
        valuesChanged = true;
      }
    }

    if (!valuesChanged) {
      return lastStatus;
    }

    let pending = false;

    const errors: IBackedCommError[] = [];

    let lastSuccess: number | null = null;

    for (let i = 0; i < newValues.length; i++) {
      const pv = newValues[i];
      if (pv.pending) {
        pending = true;
      }

      if (pv.errors) {
        for (let j = 0; j < pv.errors.length; j++) {
          errors.push(newValues[i].errors[j]);
        }
      }

      if (pv.lastSuccess) {
        if (!lastSuccess || lastSuccess < pv.lastSuccess) {
          lastSuccess = pv.lastSuccess;
        }
      }
    }

    lastStatus = { pending, errors, lastSuccess };
    lastSrcValues = [...newValues];
    return lastStatus;
  };
}

export function createExtractedDataSelector<T_SRC, T_DST>(
  srcSelector: (state: any) => IDataContainer<T_SRC>,
  converter: (src: T_SRC) => T_DST
): (state: any) => IDataContainer<T_DST> {
  const s = createSelector(
    srcSelector,
    (srcContainer: IDataContainer<T_SRC>) => ({
      data: converter(srcContainer.data),
      availability: srcContainer.availability,
    })
  );

  return s;
}

export function converterSelector2<T_SRC1, T_SRC2, T_DST>(
  srcSelector1: (state: any) => IDataContainer<T_SRC1>,
  srcSelector2: (state: any) => IDataContainer<T_SRC2>,
  converter: (src1: T_SRC1, src2: T_SRC2) => T_DST
): (state: any) => IDataContainer<T_DST> {
  const dataSelector = createSelector(
    srcSelector1,
    srcSelector2,
    (c1: IDataContainer<T_SRC1>, c2: IDataContainer<T_SRC2>) =>
      converter(c1.data, c2.data)
  );

  const availAggregator = createMemoizedAvailabilityAggregator();

  const availabilitySelector = createSelector(
    srcSelector1,
    srcSelector2,
    (c1: IDataContainer<T_SRC1>, c2: IDataContainer<T_SRC2>) =>
      availAggregator([c1.availability, c2.availability])
  );

  return createSelector(
    dataSelector,
    availabilitySelector,
    (data: T_DST, availability: IDataAvailability) => ({ data, availability })
  );
}

export function converterSelector3<T_SRC1, T_SRC2, T_SRC3, T_DST>(
  srcSelector1: (state: any) => IDataContainer<T_SRC1>,
  srcSelector2: (state: any) => IDataContainer<T_SRC2>,
  srcSelector3: (state: any) => IDataContainer<T_SRC3>,
  converter: (src1: T_SRC1, src2: T_SRC2, src3: T_SRC3) => T_DST
): (state: any) => IDataContainer<T_DST> {
  const dataSelector = createSelector(
    srcSelector1,
    srcSelector2,
    srcSelector3,
    (
      c1: IDataContainer<T_SRC1>,
      c2: IDataContainer<T_SRC2>,
      c3: IDataContainer<T_SRC3>
    ) => converter(c1.data, c2.data, c3.data)
  );

  const availAggregator = createMemoizedAvailabilityAggregator();

  const availabilitySelector = createSelector(
    srcSelector1,
    srcSelector2,
    srcSelector3,
    (
      c1: IDataContainer<T_SRC1>,
      c2: IDataContainer<T_SRC2>,
      c3: IDataContainer<T_SRC3>
    ) => availAggregator([c1.availability, c2.availability, c3.availability])
  );

  return createSelector(
    dataSelector,
    availabilitySelector,
    (data: T_DST, availability: IDataAvailability) => ({ data, availability })
  );
}

export function containerize<T>(
  src: (state: any) => T
): (state: any) => IDataContainer<T> {
  return createSelector(
    src,
    (data: T) => ({
      data,
      availability: {
        pending: false,
        errors: null,
        lastSuccess: new Date().getTime(),
      },
    })
  );
}
