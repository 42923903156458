import './index.scss';

import React, { Component } from 'react';
import bem from 'react-bem-classes';

import { Text } from 'languages/components/text';
import GroupedOrdersItem from 'payment/components/groupedOrdersItem';

@bem({
  block: 'groupedOrdersList',
})
class GroupedOrdersList extends React.Component {
  render() {
    const {
      orders,
      groupedOrders,
      serviceGroups,
      showModal,
      localeData,
    } = this.props;

    return groupedOrders.length === 0 ? null : (
      <div className={this.block()}>
        <Text className={this.element('title')}>
          MY_BOOKING_PAGE_COMPLETED_PURCHASES
        </Text>
        <div className={this.element('list')}>
          {groupedOrders.map(groupedOrder => (
            <GroupedOrdersItem
              key={groupedOrder.id}
              serviceGroups={serviceGroups}
              ordersByGroup={orders.filter(o => o.orderId === groupedOrder.id)}
              showModal={showModal}
              groupedOrder={groupedOrder}
              localeData={localeData}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default GroupedOrdersList;
