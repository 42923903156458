import http from 'axios';
import { appConfig } from 'config';
import { axiosConfigSelector } from 'authorization/selectors';

export function getSeatsInfoFromWidget(data) {
  return {
    type: 'GET_SEATS_INFO_FROM_SEATING_WIDGET',
    data,
  };
}

export function forceWidgetReload() {
  return { type: 'FORCE_WIDGET_RELOAD' };
}

export function checkSeatsSet(occupiedSeats, timeout) {
  return (dispatch, getState) => {
    dispatch({
      type: 'CHECK_SEATS_SET',
      occupiedSeats,
      timeout,
      promise: () =>
        http.post(
          `${appConfig.apiUrl}/seating/check`,
          occupiedSeats,
          axiosConfigSelector(getState())
        ),
      preFireAction: {
        type: 'CHECK_SEATS_SET_TIMEOUT_START',
        occupiedSeats,
      },
    });
  };
}
