import { PAXSHOP_LOGOUT_ACTION_ID } from 'authorization/actions/constants';

const defaultState: string | null = null;

export default function(state = defaultState, action) {
  switch (action.type) {
    case 'SET_CURRENT_SERVICE_GROUP':
      return (action.group as string) || null;

    case PAXSHOP_LOGOUT_ACTION_ID:
      return defaultState;

    default:
      return state;
  }
}
