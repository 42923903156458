import { Observable } from 'rxjs';
import { distinctUntilChanged, filter, map, pluck } from 'rxjs/operators';
import { IDataContainerUpdateAction } from 'types/datacontainer';
import { combineEpics } from 'redux-observable';
import { IAuthRes } from 'types/auth';
import { fetchFabServices } from '../actions';

type StringButNot<T extends string> = string extends T ? never : T;
interface INotDataContainerActions {
  type: StringButNot<'DATA_CONTAINER_UPDATE'>;
}
type Actions = INotDataContainerActions & IDataContainerUpdateAction<IAuthRes>;

function extractServicesUpdatesFromAuthContainer($action: Observable<Actions>) {
  return $action.pipe(
    filter(
      action =>
        action.type === 'DATA_CONTAINER_UPDATE' &&
        action.containerId === 'AUTH::AUTH_DATA' &&
        !action.container.availability.pending &&
        !!action.container.data
    ),
    pluck('container', 'data', 'services'),
    distinctUntilChanged(),
    map(services => ({
      type: 'SERVICES/LIST_UPDATED',
      payload: services,
    }))
  );
}

function fetchFABServicesOnAuth($action: Observable<Actions>) {
  return $action.pipe(
    filter(
      action =>
        action.type === 'DATA_CONTAINER_UPDATE' &&
        action.containerId === 'AUTH::AUTH_DATA' &&
        !action.container.availability.pending &&
        !!action.container.data
    ),
    pluck('container', 'data', 'scopes'),
    distinctUntilChanged(),
    map(fetchFabServices)
  );
}

export default combineEpics(
  extractServicesUpdatesFromAuthContainer,
  fetchFABServicesOnAuth
);
