import { PAXSHOP_LOGOUT_ACTION_ID } from 'authorization/actions/constants';

const defaultState = null;

export default function segmentsReducer(state = defaultState, action) {
  switch (action.type) {
    case 'SWITCH_SEGMENT':
      if (action.segmentId !== state) {
        return action.segmentId;
      }
      return state;

    case PAXSHOP_LOGOUT_ACTION_ID:
      return defaultState;

    default:
      return state;
  }
}
