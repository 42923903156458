import { IApiService, IService, IServiceFromApi } from 'types/services';

export default class ServiceList {
  public list: IServiceFromApi[] = [];

  constructor(list: IServiceFromApi[] = []) {
    this.list = list;
  }

  findService(service: IService): IServiceFromApi {
    return this.list.find(
      item =>
        item.serviceId === service.serviceId &&
        item.segmentId === service.segmentId
    );
  }

  getPriceForPassengerByService(
    service: IServiceFromApi,
    passengerId: number
  ): number {
    if (!service) {
      return 0;
    }

    const source = [].concat(
      service.eligiblePassengersAfterSeating || [],
      service.eligiblePassengers || []
    );
    return (
      source.find(passenger => passenger.passengerId === passengerId) || {
        price: 0,
      }
    ).price;
  }

  getPriceForPassenger(service: IService, passengerId: number): number {
    return this.getPriceForPassengerByService(
      this.findService(service),
      passengerId
    );
  }

  isPassangerEligibleForService(passengerId: number, service: IApiService) {
    return !!(service.eligiblePassengers || []).find(
      passenger => passenger.passengerId === passengerId
    );
  }
}
