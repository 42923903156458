import { connect } from 'react-redux';
import { Provider } from 'react-redux';
import { store } from 'store';
import { localeDataSelector } from 'languages/selectors';
import './index.scss';

import React, { Component } from 'react';
import bem from 'react-bem-classes';

@connect(state => ({
  localeData: localeDataSelector(state),
}))
@bem({
  block: 'staticErrorPage',
})
class StaticErrorPage extends Component {
  render() {
    const { localeData } = this.props;

    return (
      <Provider store={store}>
        <div className={this.block()}>
          <div className={this.element('errorPic')} />
          <h2>
            {localeData.printCmsText('CHECKIN_ERROR_PAGE_TITLE') || 'Whoops!'}
          </h2>
          <div className={this.element('errorText')}>
            {localeData.printCmsText('CHECKIN_ERROR_PAGE_TEXT') ||
              "Sorry, we couldn't find the page you were looking for!"}
          </div>
        </div>
      </Provider>
    );
  }
}

export default StaticErrorPage;
