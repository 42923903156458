import { fetchingContainerDefaultState } from '_helpers/datacontainer/datacontainer';
import * as actions from '../actions';
import { InferValueTypes } from '../../types/store';
import { PAXSHOP_LOGOUT_ACTION_ID } from '../actions/constants';

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

const defaultState = fetchingContainerDefaultState(false);

export default function authSrcData(state = defaultState, action: ActionTypes) {
  if (action.type === 'PAXSHOP_INITIATE_LOGIN') {
    return {
      ...defaultState,
      data: action.authSrc,
    };
  }
  if (action.type === PAXSHOP_LOGOUT_ACTION_ID) {
    return {
      data: null,
      availability: {
        errors: null,
        pending: false,
      },
    };
  }

  return state;
}
