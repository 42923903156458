import './index.scss';

import React from 'react';
import { Bem } from 'react-bem-classes';
import { filterInputProps } from '_helpers/reduxFormHelpers';
import moment from 'moment';
import { haveNativeDatepicker } from '_helpers/nativeDatepickerSupport';
import { tokenizeMomentFormat } from '_helpers/dateFormat';
import MaskedInput from 'react-input-mask';

import 'react-day-picker/lib/style.css';

const NATIVE_DATEPICKER_FORMAT = 'YYYY-MM-DD';

const TOKENS_FOR_MASKING = {
  M: 'MM',
  m: 'mm',
  d: 'dd',
  D: 'DD',
  Y: 'YY',
};

const TOKENS_MASK = {
  MM: '99',
  mm: '99',
  dd: '99',
  DD: '99',
  YYYY: '9999',
  YY: '99',
};

function expandTokenizedFormatForMasking(tokens) {
  return tokens.map(t =>
    t.type !== 'TOKEN'
      ? t
      : {
          type: t.type,
          data: TOKENS_FOR_MASKING[t.data] || t.data,
        }
  );
}

export class DateInput2 extends React.Component {
  getDateFormatOptions() {
    const { dateFormat } = this.props;

    const tokenizedRaw = tokenizeMomentFormat(dateFormat);

    const tokenizedForMask = expandTokenizedFormatForMasking(tokenizedRaw);

    const inputDateFormat = tokenizedForMask.map(t => t.data).join('');

    const inputMask = tokenizedForMask
      .map(t => (t.type === 'TOKEN' ? TOKENS_MASK[t.data] : t.data))
      .join('');

    return {
      inputDateFormat,
      inputMask,
    };
  }

  nativePickerOnChange = evt => {
    const { input, dateFormat } = this.props;

    const newDate = moment(evt.target.value, NATIVE_DATEPICKER_FORMAT).format(
      dateFormat
    );

    input.onChange(newDate);
    input.onBlur();
  };

  nativePickerOnBlur = () => {
    const { input } = this.props;
    input.onBlur();
  };

  onChange = evt => {
    const { dateFormat, input } = this.props;

    const val = evt.target.value;

    const fmtOptions = this.getDateFormatOptions();

    const date = moment(val, fmtOptions.inputDateFormat);

    const finished = date.format(fmtOptions.inputDateFormat) === val;

    input.onChange(finished ? date.format(dateFormat) : val);
  };

  render() {
    const {
      isNativePicker,
      input,
      meta: { touched, error, invalid, active },
      disabled,
      label,
      dateFormat,
      ...rest
    } = this.props;

    const fmtOptions = this.getDateFormatOptions();

    const currentDateStr = input.value;

    const shouldDisplayError = touched && invalid;

    const isEmpty = !input.value || disabled;

    const currentDateMoment = currentDateStr
      ? moment(currentDateStr, dateFormat)
      : null;

    const validDate =
      currentDateMoment &&
      currentDateMoment.format(dateFormat) === currentDateStr;

    const nativePickerValue = currentDateStr
      ? moment(currentDateStr, dateFormat).format(NATIVE_DATEPICKER_FORMAT)
      : '';

    const dateToDisplay = validDate
      ? currentDateMoment.format(fmtOptions.inputDateFormat)
      : currentDateStr;

    let hasNativeDatePicker = isNativePicker;
    if (isNativePicker === undefined) {
      hasNativeDatePicker = haveNativeDatepicker;
    }

    const bem = new Bem('dateInput2');

    return (
      <div className={bem.block()}>
        <div
          className={`${bem.element('inputBlock', {
            hasValue: !isEmpty,
            active,
            error: !!(shouldDisplayError && error),
          })} uiInput2`}
        >
          {false ? ( // hasNativeDatePicker not working on mobile if refresh page
            <input
              type="date"
              className={bem.element('nativePickerOverlay')}
              value={nativePickerValue}
              onChange={this.nativePickerOnChange}
              onBlur={this.nativePickerOnBlur}
            />
          ) : (
            [
              <MaskedInput
                key="mask_input"
                type="text"
                mask={fmtOptions.inputMask}
                className={bem.element('input')}
                disabled={disabled}
                {...filterInputProps(input)}
                value={dateToDisplay}
                onChange={this.onChange}
              />,
              <label
                key="mask_label"
                className={bem.element('dateInputLabel', {
                  hasValue: Boolean(input.value),
                })}
              >
                {label} ({fmtOptions.inputDateFormat})
              </label>,
            ]
          )}
        </div>
        <span className={bem.element('errorMessage')}>
          {shouldDisplayError && error ? this.props.getErrorMsg(error) : ' '}
        </span>
      </div>
    );
  }
}
