import React from 'react';
import { connect } from 'react-redux';
import { allCMSKeysRawSelector } from 'languages/selectors';

function mapStateToProps(state) {
  return {
    cmsKeys: allCMSKeysRawSelector(state),
  };
}

class Link extends React.Component {
  render() {
    const { children, cmsKeys, href, className } = this.props;

    const text = cmsKeys[children] !== undefined ? cmsKeys[children] : children;

    const hrefSrc = cmsKeys[href] !== undefined ? cmsKeys[href] : href;

    return (
      <a
        className={className}
        data-cms={children}
        target="_blank"
        href={hrefSrc}
      >
        {' '}
        {text}{' '}
      </a>
    );
  }
}

export default connect(mapStateToProps)(Link);
