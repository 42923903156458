import React from 'react';
import { Modal } from 'layouts/modal';
import PageMybooking from 'pageMybooking/containers';
import modalDecorator from '_helpers/modalDecorator';

@modalDecorator
export class MyBookingDialog extends React.Component {
  render() {
    return (
      <Modal ref="modal" plain>
        <PageMybooking />
      </Modal>
    );
  }
}
