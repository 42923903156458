import http from 'axios';
import { appConfig } from 'config';
import { axiosConfigSelector } from 'authorization/selectors';
import { currentLangSelector } from 'languages/selectors';
import { segmentsSelector } from 'segments/helpers/selectors';
import { alreadyCheckedInSegmentsSelector } from '../helpers/selectors';

export const getCheckInInfo = () => (dispatch, getState) => {
  const state = getState();
  return dispatch({
    type: 'GET_CHECK_IN_INFO',
    promise: http.post(
      `${appConfig.apiUrl}/v2/bookings/${state.auth.authData.data.bookingId}?checkin=true`,
      { services: [] },
      axiosConfigSelector(state)
    ),
  });
};

export const checkInOnSegment = segmentId => (dispatch, getState) => {
  const state = getState();
  return dispatch({
    type: 'CHECK_IN_ON_SEGMENT',
    promise: http.post(
      `${appConfig.apiUrl}/tools/checkin`,
      { segmentId },
      axiosConfigSelector(state)
    ),
  });
};

export const emailBoardingPass = (recipient, locale) => (
  dispatch,
  getState
) => {
  const state = getState();
  return dispatch({
    type: 'EMAIL_BOARDING_PASS',
    promise: http.get(
      `${appConfig.apiUrl}/boarding-pass/send/${recipient}/${locale}`,
      axiosConfigSelector(state)
    ),
  });
};

export const emailPkPass = (data, locale) => (dispatch, getState) => {
  const state = getState();
  return dispatch({
    type: 'EMAIL_BOARDING_PASS',
    promise: http.post(
      `${appConfig.apiUrl}/boarding-pass/send/${locale}`,
      data,
      axiosConfigSelector(state)
    ),
  });
};

export const sendEmailWithPkPass = (email, passengerId) => (
  dispatch,
  getState
) => {
  const state = getState();
  const lang = currentLangSelector(state);
  const segments = segmentsSelector(state);
  const checkedInSegments = alreadyCheckedInSegmentsSelector(state);

  const checkedInSegmentsDirections = segments
    .filter(segment => checkedInSegments.find(a => a.id === segment.id))
    .map(segment => segment.direction);

  return dispatch(
    emailPkPass(
      [
        {
          email,
          passengerId,
          segments: checkedInSegmentsDirections,
        },
      ],
      lang
    )
  );
};

export const resetPkPassSendingStatus = () => ({
  type: 'RESET_PKPASS_SENDING_STATUS',
});

export const downloadPkPass = (
  flightNumber,
  departureDate,
  bookingNumber,
  currentPassengerId,
  currentLang
) => (dispatch, getState) => {
  const state = getState();
  const requestConfig = Object.assign({}, axiosConfigSelector(state), {
    responseType: 'arraybuffer',
  });
  requestConfig.headers = Object.assign({}, requestConfig.headers, {
    Accept: 'application/vnd.apple.pkpass',
  });

  return dispatch({
    type: 'DOWNLOAD_PK_PASS',
    promise: http.get(
      `${appConfig.contextUrl}/pkpass/${flightNumber}/${departureDate}/${bookingNumber}/${currentPassengerId}/${currentLang}`,
      requestConfig
    ),
  });
};

export const emailConfirmation = (recipient, locale) => (
  dispatch,
  getState
) => {
  const state = getState();
  return dispatch({
    type: 'EMAIL_CONFIRMATION',
    promise: http.post(
      `${appConfig.apiUrl}/purchase-confirmation/send/${locale}`,
      { email: recipient },
      axiosConfigSelector(state)
    ),
  });
};

export function setCheckInStatuses(statuses) {
  return {
    type: 'CHECK-IN/SET_STATUSES',
    payload: statuses,
  };
}
