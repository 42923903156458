import moment from 'moment';
import {
  currentPassengerId,
  getSampleChildAndAdultIds,
  getWidgetSeatMap,
  passengersFromSeatingWidgetSelector,
} from 'passengers/helpers/selectors';
import SERVICE_GROUPS_EXCEPTIONS from 'services/constants/serviceGroupsExceptions';
import { createSelector } from 'reselect';
import {
  IActiveServiceWithOfferStatus,
  IDoublePricedService,
  IServiceFromApi,
} from 'types/services';
import { IStoreState, PaxSelector } from 'types/store';
import ServiceListHelper from 'services/_helpers/ServiceListHelper';
import { isSeatBought } from 'seatingWidget/helpers/selectors';
import {
  currentSegmentSelector,
  inboundSegmentSelector,
  outboundSegmentSelector,
  segmentsSelector,
} from 'segments/helpers/selectors';
import { getServiceOfferStatus } from '../helpers';
import { selectFabScopes } from './fab';
import { selectUpToDateBasket } from '../../basket/selectors/selectBasket';

export const selectAllServices = createSelector(
  (state: IStoreState) => state.services.list,
  (services): IServiceFromApi[] => services
);

export function selectSubgroups(
  state: IStoreState,
  primaryGroup: 'baggage' | 'otherservices'
): (string | undefined)[] {
  const allServices = selectAllServices(state);

  // Other services doesn't have any primary group. Instead, they are just single-tagged with some category.
  // We specify all categories we consider "other" here.
  const OTHER_SERVICES_SUBGROUPS = [
    'celebration',
    'lounge',
    'other',
    'vip',
    'GPST',
    'meal',
    // Here comes the FAB tags
    'lounges',
  ];

  if (primaryGroup === 'otherservices') {
    return Array.from(
      new Set(
        allServices
          .map(service => service.tags[0])
          .filter(tag => OTHER_SERVICES_SUBGROUPS.includes(tag))
      )
    );
  }

  // Array.from(new Set(arr)) removes duplicates in arr
  return Array.from(
    new Set(
      allServices
        .map(service => service.tags)
        // If service belongs to the specified group
        .filter(
          tags =>
            tags[1] === primaryGroup ||
            (tags.length === 1 && tags[0] === primaryGroup)
        )
        // We expect first tag to be a subgroup, and the second to be primary
        .map(tags => tags[0])
    )
  );
}

export const servicesGroupsSelector: PaxSelector<string[]> = createSelector(
  (state: IStoreState) => selectAllServices(state),
  (state: IStoreState) => selectFabScopes(state),
  (services, scopes) => {
    const fabGroups = Object.keys(scopes);
    const retailGroups = Array.from(
      new Set(services.map(service => service.tags).flat())
    );
    return [...fabGroups, ...retailGroups];
  }
);

export const otherGroupsSelector: PaxSelector<string[]> = createSelector(
  servicesGroupsSelector,
  (groups: string[]) =>
    groups.filter(group => SERVICE_GROUPS_EXCEPTIONS.indexOf(group) === -1)
);

export const baggageServicesSelector: PaxSelector<IServiceFromApi[]> = createSelector(
  (state: IStoreState) => selectAllServices(state),
  services => services.filter(service => service.tags.includes('baggage'))
);

export const seatingServicesSelector: PaxSelector<IServiceFromApi[]> = createSelector(
  (state: IStoreState) => selectAllServices(state),
  services => services.filter(service => service.tags.includes('seating'))
);

export const getSalesWindowOpenDate = createSelector(
  seatingServicesSelector,
  services =>
    services
      .filter(service => service.salesOpenAt)
      .map(service => new Date(service.salesOpenAt))
      .sort((a, b) => a.valueOf() - b.valueOf())
);

export const otherServicesSelector: PaxSelector<IServiceFromApi[]> = createSelector(
  (state: IStoreState) => selectAllServices(state),
  services =>
    services.filter(
      service =>
        !!service.tags.find(
          serviceTag => SERVICE_GROUPS_EXCEPTIONS.indexOf(serviceTag) === -1
        )
    )
);

export const hasBaggageServicesSelector: PaxSelector<boolean> = createSelector(
  baggageServicesSelector,
  services => services.length > 0
);

export const hasOtherServicesSelector: PaxSelector<boolean> = createSelector(
  otherServicesSelector,
  services => services.length > 0
);

export const servicesForCurrentGroupAndPassengerSelector = createSelector(
  (state: IStoreState) => state.services.currentGroup,
  currentPassengerId,
  (state: IStoreState) => selectAllServices(state),
  (
    currentGroup: string,
    currentPassengerId: number,
    services: IServiceFromApi[]
  ) =>
    services
      .filter(service => service.tags.includes(currentGroup))
      .map(service =>
        Object.assign({}, service, {
          activeServicesInfo: [
            ...service.eligiblePassengers,
            ...service.eligiblePassengersAfterSeating,
          ].find(passenger => passenger.passengerId === currentPassengerId),
          needSeatToBuy: !!service.eligiblePassengersAfterSeating.find(
            passenger => passenger.passengerId === currentPassengerId
          ),
        })
      )
      .filter(service => !!service.activeServicesInfo)
);

export const seatServicesWithPricesSelector = createSelector(
  (state: IStoreState) => selectAllServices(state),
  (state: IStoreState) => state.seating.widgetInfo.seatsServices,
  (state: IStoreState) => state.segments.currentSegmentId,
  (services, widgetSeatServices, currentSegmentId) =>
    services
      .filter(
        service =>
          service.tags.indexOf('seating') >= 0 &&
          widgetSeatServices.find(i => i.id === service.serviceId) &&
          service.segmentId === currentSegmentId
      )
      .map(service => {
        const infoFromWidget = widgetSeatServices.find(
          i => i.id === service.serviceId
        );
        return Object.assign({}, service, {
          prices: infoFromWidget.prices,
        });
      })
);

export const hasInfantSelector = createSelector(
  passengersFromSeatingWidgetSelector,
  passengers => !!passengers.find(p => p.type === 'INF')
);

const isServiceAvailableForPassenger = (seatMap, service, testPassengerId) =>
  seatMap
    .filter(seat => seat.allocation.service_ref === service.serviceId)
    .filter(
      seat =>
        !!seat.allocation.eligible_passengers.find(
          passengerId => passengerId === testPassengerId
        )
    ).length !== 0;

export const doublePricedServices = createSelector(
  getWidgetSeatMap,
  seatServicesWithPricesSelector,
  currentPassengerId,
  passengersFromSeatingWidgetSelector,
  getSampleChildAndAdultIds,
  isSeatBought,
  currentSegmentSelector,
  (
    seatMap,
    services,
    currentPassengerId,
    passengers,
    sampleChildAndAdultIds,
    isSeatBought,
    segment
  ): IDoublePricedService[] =>
    Array.from(services).map(service => {
      const currentPassenger = passengers.find(
        p => p.id === currentPassengerId
      );

      const showPrice =
        isServiceAvailableForPassenger(seatMap, service, currentPassengerId) &&
        !(
          isSeatBought ||
          moment(segment.flights[0].departureDateTime).toDate() < new Date()
        ) &&
        !segment.cmsMessages.includes('CMS_SALES_BLOCKED_OUT');

      let price: any = service.prices.find(
        k => k.passengerId === currentPassenger.id
      );
      const adultPrice = service.prices.find(
        k => k.passengerId === sampleChildAndAdultIds.adultId
      );
      const childPrice = service.prices.find(
        k => k.passengerId === sampleChildAndAdultIds.childId
      );

      if (
        currentPassenger.type === 'ADT' &&
        childPrice !== undefined &&
        isServiceAvailableForPassenger(
          seatMap,
          service,
          sampleChildAndAdultIds.childId
        ) &&
        price.amount !== childPrice.amount
      ) {
        price = [price, childPrice];
      } else if (
        currentPassenger.type === 'CHD' &&
        adultPrice !== undefined &&
        isServiceAvailableForPassenger(
          seatMap,
          service,
          sampleChildAndAdultIds.adultId
        ) &&
        price.amount !== adultPrice.amount
      ) {
        price = [price, adultPrice];
      } else {
        price = [price];
      }

      return {
        service,
        showPrice,
        available: showPrice,
        price,
      } as any;
    })
);

const segmentDirectionsSelector: PaxSelector<any> = createSelector(
  segmentsSelector,
  outboundSegmentSelector,
  inboundSegmentSelector,
  (segments, outboundSegment, inboundSegment) =>
    segments.reduce(
      (result, segment) =>
        result.set(
          segment.segmentId,
          segment.direction === 'IN' ? inboundSegment : outboundSegment
        ),
      new Map()
    )
);

export const activeServicesWithStatusGroupByIdSelector = createSelector(
  servicesForCurrentGroupAndPassengerSelector,
  segmentDirectionsSelector,
  segmentsSelector,
  selectUpToDateBasket,
  (state: IStoreState) => state.passengers.currentPassengerId,
  (
    services: IActiveServiceWithOfferStatus[],
    segmentDirections,
    allSegments,
    basket,
    currentPassengerId
  ) => {
    const h = new ServiceListHelper(services);
    return services.reduce((result, service) => {
      const serviceIndex = result.findIndex(
        s => s.serviceId === service.serviceId
      );

      const {
        segmentId,
        activeServicesInfo,
        offerStatus,
        ...restItem
      } = service;

      const segment = segmentDirections.get(segmentId);

      const segmentFieldName =
        segment.direction === 'IN' ? 'inboundSegment' : 'outboundSegment';

      const anotherSegmentFieldName =
        segment.direction !== 'IN' ? 'inboundSegment' : 'outboundSegment';

      const segments = {
        [segmentFieldName]: {
          serviceTemplate: h.findService({
            segmentId: segment.segmentId,
            serviceId: service.serviceId,
          }),
          ...segment,
          activeServicesInfo,
          offerStatus: getServiceOfferStatus(
            services,
            basket,
            segment.segmentId,
            currentPassengerId,
            service
          ),
        },
        [anotherSegmentFieldName]: result[serviceIndex]
          ? result[serviceIndex][anotherSegmentFieldName] || {
              ...allSegments.find(s => s.segmentId !== segmentId),
              serviceTemplate: h.findService({
                segmentId,
                serviceId: service.serviceId,
              }),
            }
          : {
              ...allSegments.find(s => s.segmentId !== segmentId),
              serviceTemplate: h.findService({
                segmentId,
                serviceId: service.serviceId,
              }),
            },
      };

      const countInBasket = basket.filter(
        i =>
          (i.segmentId === segments.outboundSegment.segmentId ||
            i.segmentId === segments.inboundSegment.segmentId) &&
          i.serviceId === service.serviceId
      ).length;

      if (serviceIndex === -1) {
        result.push({
          ...restItem,
          ...segments,
          areSegmentsSoldOutAndUnavailable: service.allSectorRequiredToBuy
            ? countInBasket === 0 &&
              (['SOLD_OUT', 'SOLD_OUT_SHARED'].includes(
                segments.outboundSegment.offerStatus
              ) ||
                ['SOLD_OUT', 'SOLD_OUT_SHARED'].includes(
                  segments.inboundSegment.offerStatus
                )) &&
              service.eligiblePassengers.length === 0
            : segments.inboundSegment.segmentId &&
              !segments.inboundSegment.offerStatus &&
              service.eligiblePassengers.length === 0,
        });
      } else {
        result[serviceIndex] = {
          ...result[serviceIndex],
          ...segments,
          areSegmentsSoldOutAndUnavailable: service.allSectorRequiredToBuy
            ? countInBasket === 0 &&
              (['SOLD_OUT', 'SOLD_OUT_SHARED'].includes(
                segments.outboundSegment.offerStatus
              ) ||
                ['SOLD_OUT', 'SOLD_OUT_SHARED'].includes(
                  segments.inboundSegment.offerStatus
                )) &&
              service.eligiblePassengers.length === 0
            : segments.inboundSegment.segmentId &&
              !segments.inboundSegment.offerStatus &&
              service.eligiblePassengers.length === 0,
        };
      }
      return result;
    }, []);
  }
);
