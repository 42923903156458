const timeouts = {};

export default function timeoutMiddleware(store) {
  return next => action => {
    const { timeout, timeoutKey = '', type, preFireAction, ...rest } = action;

    const uniqueKey = `${type}${timeoutKey}`;

    if (!timeout) return next(action);

    if (timeouts[uniqueKey]) clearTimeout(timeouts[uniqueKey]);

    if (timeout > 0) {
      timeouts[uniqueKey] = setTimeout(() => {
        store.dispatch({
          type,
          ...rest,
        });
      }, timeout);

      if (preFireAction) {
        store.dispatch(preFireAction);
      }
    } else {
      store.dispatch({
        type,
        ...rest,
      });
    }
  };
}
