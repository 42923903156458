import React from 'react';

interface IProps {
  src: string;
  children: JSX.Element;
}

export default function BackgroundImage(props: IProps): JSX.Element {
  const { src, ...rest } = props;

  const styles = {
    backgroundImage: `url(${src})`,
  };

  return (
    <div {...rest} style={styles}>
      {props.children}
    </div>
  );
}
