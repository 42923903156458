import { createSelector } from 'reselect';
import {
  IInfantRefInfo,
  IPassenger,
  IPassengerFromWidget,
  ISampleChildAndAdultIds,
} from 'types/passengers';
import { IStoreState } from 'types/store';
import { IPaxshopPage, PaxshopPageId } from '../../types/routing';

export const passengersSelector = (state: IStoreState): IPassenger[] =>
  state.passengers ? state.passengers.passengersList : [];

export const infantRefInfoSelector = (state: IStoreState): IInfantRefInfo =>
  state.auth.authData.data
    ? state.auth.authData.data.booking.infantRefInfo
    : { allInfantsHaveRef: true, cmsKey: null };

export const noInfantSelector = createSelector(
  passengersSelector,
  (list: IPassenger[]) =>
    list
      .filter(p => p.passengerType !== 'INF')
      .sort((p1, p2) => {
        if (p2.lastName === p1.lastName) {
          return p1.firstName === p2.firstName
            ? 0
            : p1.firstName > p2.firstName
            ? 1
            : -1;
        }

        return p1.lastName > p2.lastName ? 1 : -1;
      })
);

export const currentPassengerId = (state: IStoreState) =>
  state.passengers.currentPassengerId;

export const infantSelector = createSelector(
  passengersSelector,
  (list: IPassenger[]) => list.filter(p => p.passengerType === 'INF')
);

export const infantWithoutRefSelector = createSelector(
  infantSelector,
  (list: IPassenger[]) => list.filter(p => p.parentRefId === null)
);

export const currentPassengerIdSelector = (state: IStoreState): number =>
  state.passengers.currentPassengerId;

export const currentPassengerSelector: (
  state: IStoreState
) => IPassenger = createSelector(
  passengersSelector,
  currentPassengerIdSelector,
  (passengers: IPassenger[], curId: number) =>
    passengers.find(p => p.passengerId === curId)
);

export const passengersFromSeatingWidgetSelector = (state: IStoreState) =>
  state.seating.widgetInfo.passengers;

export const hasChildAndAdult: (state: IStoreState) => boolean = createSelector(
  passengersFromSeatingWidgetSelector,
  (passengers: IPassengerFromWidget[]) =>
    Boolean(
      passengers.find(p => p.type === 'ADT') &&
        passengers.find(p => p.type === 'CHD')
    )
);

export const getWidgetSeatMap = (state: IStoreState) =>
  state.seating.widgetInfo.seatMap;

export const isSeatAvaible = (seatMap, service, testPassengerId) =>
  seatMap
    .filter(seat => seat.allocation.service_ref === service.serviceId)
    .filter(
      seat =>
        !!seat.allocation.eligible_passengers.find(
          passengerId => passengerId === testPassengerId
        )
    ).length !== 0;

export const getSampleChildAndAdultIds: (
  state: IStoreState
) => ISampleChildAndAdultIds = createSelector(
  passengersFromSeatingWidgetSelector,
  (passengers: IPassengerFromWidget[]) => {
    const adult = passengers.find(p => p.type === 'ADT' && !p.infant_ref);

    const child = passengers.find(p => p.type === 'CHD');
    return {
      adultId: adult && adult.id,
      childId: child && child.id,
    };
  }
);

export const passengersForSeatingPageSelector: (
  state: IStoreState
) => IPassenger[] = createSelector(
  noInfantSelector,
  infantSelector,
  passengersFromSeatingWidgetSelector,
  (state: IStoreState) => state.routing.paxshopPage.pageId,
  (
    passengers: IPassenger[],
    infants: IPassenger[],
    passengersWidgetInfo: IPassengerFromWidget[],
    pageId: PaxshopPageId
  ) => {
    const passengersProcessed = passengers.map(passenger => {
      const infantRef = (
        passengersWidgetInfo.find(p => p.id === passenger.passengerId) || {
          infant_ref: false,
        }
      ).infant_ref;
      return infantRef
        ? Object.assign({}, passenger, {
            infant: infants.find(p => p.passengerId === infantRef),
          })
        : passenger;
    });

    if (pageId === 'SVC_OTHER') {
      return passengersProcessed
        .filter(p => typeof p.infant === 'object')
        .concat(
          passengersProcessed.filter(
            p => p.passengerType === 'ADT' && typeof p.infant === 'undefined'
          )
        )
        .concat(passengersProcessed.filter(p => p.passengerType === 'CHD'));
    }
    return passengersProcessed
      .filter(p => typeof p.infant === 'object')
      .concat(passengersProcessed.filter(p => p.passengerType === 'CHD'))
      .concat(
        passengersProcessed.filter(
          p => p.passengerType === 'ADT' && typeof p.infant === 'undefined'
        )
      );
  }
);

export const adultWithoutInfantRefSelector: (
  state: IStoreState
) => IPassenger[] = createSelector(
  passengersSelector,
  infantSelector,
  (list: IPassenger[], infants: IPassenger[]) => {
    const adultWithAttachedInfantsIds: number[] = infants
      .filter(p => p.parentRefId !== null)
      .map(p => p.parentRefId);

    return list.filter(
      p =>
        p.passengerType === 'ADT' &&
        !adultWithAttachedInfantsIds.includes(p.passengerId)
    );
  }
);
