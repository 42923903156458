import { DATA_CONTAINER_UPDATE_ACTION } from '_helpers/datacontainer/datacontainer';
import { PAXSHOP_LOGOUT_ACTION_ID } from 'authorization/actions/constants';
import { ICheckInSegment } from 'types/checkIn';

export interface ICheckInState {
  data: ICheckInSegment[];
  pkPassSendingStatus: null | 'load' | 'success' | 'fail';
}

const defaultState: ICheckInState = {
  data: [],
  pkPassSendingStatus: null,
};

export default function checkInReducer(
  state: ICheckInState = defaultState,
  action
): ICheckInState {
  switch (action.type) {
    case DATA_CONTAINER_UPDATE_ACTION:
      if (
        action.containerId === 'AUTH::AUTH_DATA' &&
        !action.container.availability.pending &&
        action.container.data &&
        action.container.data.checkin
      ) {
        return {
          ...state,
          data: action.container.data.checkin,
        };
      }

      return state;

    case 'CHECK-IN/SET_STATUSES':
      return {
        ...state,
        data: action.payload,
      };

    case 'GET_CHECK_IN_INFO':
      if (action.res.data && action.res.data.checkin) {
        return {
          ...state,
          data: action.res.data.checkin,
        };
      }
      return state;

    case 'RESET_PKPASS_SENDING_STATUS':
      return {
        ...state,
        pkPassSendingStatus: null,
      };

    case 'EMAIL_BOARDING_PASS_REQUEST':
      return {
        ...state,
        pkPassSendingStatus: 'load',
      };

    case 'EMAIL_BOARDING_PASS':
      return {
        ...state,
        pkPassSendingStatus: 'success',
      };

    case 'EMAIL_BOARDING_PASS_FAILURE':
      return {
        ...state,
        pkPassSendingStatus: 'fail',
      };

    case PAXSHOP_LOGOUT_ACTION_ID:
      return defaultState;

    default:
      return state;
  }
}
