const defaultState = null;

export default function deeplinkReducer(state = defaultState, action) {
  switch (action.type) {
    case 'GET_DEEP_LINK':
      return action.res.data.dutyFreeLink;
    default:
      return state;
  }
}
