import { IDataContainer } from './datacontainer';

export interface IApisState {
  paxApis: IDataContainer<IApisInfo>;
}

/*
 F("Female", "CMS_FEMALE"),
 M("Male", "CMS_MALE"),
 FI("Female infant", "CMS_FEMALE_INFANT"),
 MI("Male infant", "CMS_MALE_INFANT"),
 U("Undisclosed", "CMS_UNDISCLOSED_GENDER");
 */
export type IApisGender = 'F' | 'M' | 'FI' | 'MI' | 'U';

export interface IPassengerApisInfo {
  primaryDoc_number?: string | null;
  primaryDoc_expiryDate?: string | null;
  primaryDoc_issuingCountry?: string | null;

  primaryDoc_firstName?: string;
  primaryDoc_secondName?: string;
  primaryDoc_surname?: string;
  primaryDoc_dateOfBirth?: string; // internal format
  primaryDoc_gender?: IApisGender | null;

  primaryDoc_nationality?: string | null;
  primaryDoc_confirmed?: boolean;

  destAddr_country?: string | null;
  destAddr_city?: string | null;
  destAddr_address?: string | null;
  destAddr_state?: string | null;
  destAddr_zip?: string | null;

  resdAddr_country?: string | null;
  resdAddr_city?: string | null;
  resdAddr_address?: string | null;
  resdAddr_state?: string | null;
  resdAddr_zip?: string | null;

  common_redressNumber?: string | null;
}

export interface IPassengerApisMetadata {
  usedFieldsNames: string[];
  templateCode: string;
}

export interface IPassengerApisState
  extends IPassengerApisInfo,
    IPassengerApisMetadata {}

export interface IApisInfo {
  [passengerId: string]: IPassengerApisState;
}

export type IApisFieldGroup =
  | 'PRIMARY_DOC'
  | 'VISA_DOC'
  | 'ADDR_RSD'
  | 'ADDR_DST'
  | null;

/* APIS form description */
export interface IApisFormFieldDesc<T> {
  visible: boolean;
  required: boolean;
  convertToInternal: (val: string) => T;
  convertToForm: (val: T) => string;
  getValidators: () => any[];
  cmsLabel: string;
  type: 'STRING' | 'DATE' | 'SELECT';
  group: IApisFieldGroup;

  selectOptions?: IApisSelectFormFieldProps;
}

export interface IApisSelectFormFieldProps {
  getKeys: () => string[];
  getOptions: (a?: any) => any[];
  getOptionId: (o: any) => string;
  getOptionText: (o: any, cmsKeys: { [id: string]: string }) => string;
}

export type IApisFormFieldsDesc = {
  [P in keyof IPassengerApisInfo]: IApisFormFieldDesc<IPassengerApisInfo[P]>;
};

export type IApisFormState = { [P in keyof IPassengerApisInfo]?: string };

export type ApisFormField = keyof IApisFormFieldsDesc;

export interface IApisFormDesc {
  fieldsDesc: IApisFormFieldsDesc;
  activeFields: string[];
  templateCode: string;
}

export interface IApisValidationResult {
  valid: boolean;
  fieldErrors: { [P in keyof IPassengerApisInfo]?: string[] };
}

export interface IEnhancedPaxApisInfo {
  apisData: IPassengerApisInfo;
  formDesc: IApisFormDesc | null;
  filled: boolean;
  canBeFilled: boolean;
}

export interface IEnhancedApisInfo {
  [passengerId: string]: IEnhancedPaxApisInfo;
}

export interface IStatesListEntry {
  name: string;
  alpha2: string;
}

export interface ICountriesListEntry {
  name: string;
  alpha2: string;
  alpha3: string;
  countryCode: string;
  region: string;
  subRegion: string;
  regionCode: string;
  subregionCode: string;
  nationality: string;
}

export enum PassengerTypeEnum {
  ADT = 'ADT',
  CHD = 'CHD',
  INF = 'INF',
}
export interface IGenderListEntry {
  id: string;
  cms: string;
  type: PassengerTypeEnum[];
}

export interface IDocumentTypeListEntry {
  id: string;
  cms: string;
}
