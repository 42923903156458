import React, { Component, ReactNode } from 'react';
import * as Sentry from '@sentry/browser';
import { Bem } from 'react-bem-classes';
import './index.scss';

const bem = new Bem('globalError');

export class GlobalErrorBoundary extends Component<
  { children: ReactNode },
  { eventId: null | string; hasError: boolean }
> {
  constructor(props) {
    super(props);
    this.state = { eventId: null, hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventId = Sentry.captureException(error);
      this.setState({ eventId });
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className={bem.block()}>
          <h1 className={bem.element('text')}>An error happened in Paxshop</h1>
          <p className={bem.element('text', { shade: true })}>
            We will try to fix it soon and really appreciate your feedback
          </p>

          <button
            type="button"
            className={bem.element('button')}
            onClick={() =>
              Sentry.showReportDialog({ eventId: this.state.eventId })
            }
          >
            Tell us what happened
          </button>
          <button
            type="button"
            className={bem.element('refreshButton')}
            onClick={() => {
              window.location.reload();
            }}
          >
            Refresh page
          </button>
        </div>
      );
    }

    return this.props.children;
  }
}
