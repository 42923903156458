import React from 'react';
import bem from 'react-bem-classes';
import './index.scss';

import { Text } from 'languages/components/text';
import BackImg from '_helpers/backgroundImage';
import { AddingToBasket } from 'services/components/AddingToBasket';
import { Modal } from 'layouts/modal';
import PropTypes from 'prop-types';

@bem({
  block: 'serviceModal',
})
export class ServiceModal extends React.Component {
  static propTypes = {
    addToBasket: PropTypes.func,
    service: PropTypes.object,
    addCartProps: PropTypes.object,
  };

  render() {
    const {
      service,
      service: {
        imageName,
        nameCmsKey,
        name,
        infoFullCmsKey,
        infoCmsKey,
        text,
        extInfo,
      },
    } = this.props;

    return (
      <div
        className={this.block({
          isExternalService: service.isExternalService,
        })}
      >
        <div className={this.element('header')}>
          <BackImg src={imageName} className={this.element('image')} />
          <div>
            {nameCmsKey ? (
              <Text className={this.element('title')}>{nameCmsKey}</Text>
            ) : (
              <div className={this.element('title')}>{name}</div>
            )}
            {!service.isExternalService ? (
              <AddingToBasket
                service={service}
                handleAdd={this.props.addToBasket}
                toggleInboundSegmentCheckbox={
                  this.props.addCartProps.toggleInboundSegmentCheckbox
                }
                toggleOutboundSegmentCheckbox={
                  this.props.addCartProps.toggleOutboundSegmentCheckbox
                }
                getInboundSegment={this.props.addCartProps.getInboundSegment}
                getOutboundSegment={this.props.addCartProps.getOutboundSegment}
              />
            ) : (
              <a
                href={extInfo.link}
                target="_blank"
                className={this.element('button')}
              >
                <Text>SERVICE_DETAILS_READ_MORE</Text>
              </a>
            )}
          </div>
        </div>
        <div className={this.element('desc')}>
          {infoFullCmsKey || infoCmsKey ? (
            <Text className={this.element('info')}>
              {infoFullCmsKey || infoCmsKey}
            </Text>
          ) : (
            <p className={this.element('info')}>{text}</p>
          )}
        </div>
      </div>
    );
  }
}

export class ServiceModalDialog extends React.Component {
  open(service, addToBasket, addCartProps) {
    this.refs.rootModal.open({
      service,
      addToBasket,
      addCartProps,
    });
  }

  hide() {
    this.refs.rootModal.close();
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <Modal ref="rootModal" popupRootClass="serviceModalPopup">
        <ServiceModal />
      </Modal>
    );
  }
}
