export function systemDetectLanguage(languages: string[]): string {
  return languages.find(lang => {
    let condition;

    if (window.navigator && window.navigator.languages) {
      condition = window.navigator.languages.indexOf(lang) !== -1;
    } else if (window.navigator && window.navigator.language) {
      condition = window.navigator.language.substr(0, 2) === lang;
    } else {
      condition = false;
    }

    return condition;
  });
}
