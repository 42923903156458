import './index.scss';
import React from 'react';
import bem from 'react-bem-classes';
import MediaQuery from 'react-responsive';
import { connect } from 'react-redux';
import {
  passengersForSeatingPageSelector,
  passengersSelector,
} from 'passengers/helpers/selectors';
import { chosenSeatsSelectorForCurrentSegment } from 'seatingWidget/helpers/selectors';
import * as actions from 'passengers/actions';
import icon from './images/icon.svg';

const sortByIdAdultsFirst = (p1, p2) => {
  const countPassengerWeight = passenger => {
    let weight = 0;
    if (passenger.infant) weight += 100;
    if (passenger.passengerType === 'ADT') weight += 10;
    return weight;
  };

  return (
    countPassengerWeight(p2) - countPassengerWeight(p1) ||
    p2.passengerId - p1.passengerId
  );
};

@connect(
  (state, ownProps) => ({
    passengersList: ownProps.mini
      ? passengersSelector(state)
      : passengersForSeatingPageSelector(state),
    currentPassengerId: state.passengers.currentPassengerId,
    chosenSeats: chosenSeatsSelectorForCurrentSegment(state),
  }),
  dispatch => ({ dispatch })
)
@bem({
  block: 'passengerSelector',
})
export class PassengerSelector extends React.Component {
  componentDidMount() {
    const defaultPassengerId =
      this.props.defaultPassengerId || this.props.passengersList[0].passengerId;

    this.props.dispatch(actions.switchPassenger(defaultPassengerId));
  }

  handleSelectPassenger(passengerId) {
    this.props.dispatch(actions.switchPassenger(passengerId));
  }

  renderDesktopSelector() {
    const {
      passengersList,
      currentPassengerId,
      chosenSeats,
      useOverriddenSeats,
      overriddenSeats,
      adultsFirst,
    } = this.props;

    const passengers = adultsFirst
      ? passengersList.sort(sortByIdAdultsFirst)
      : passengersList;

    return (
      <div className={this.block()}>
        {passengers.map(passenger => {
          const seatsSource = useOverriddenSeats
            ? overriddenSeats
            : chosenSeats;
          const seat =
            seatsSource.find(
              seat => seat.passengerId === passenger.passengerId
            ) || null;

          return (
            <div
              key={passenger.passengerId}
              onClick={() => this.handleSelectPassenger(passenger.passengerId)}
              className={this.element('item', {
                isCurrent: passenger.passengerId === currentPassengerId,
                hasSeat: !!seat,
              })}
            >
              <img src={icon} className={this.element('icon')} />
              <div className={this.element('info')}>
                <div className={this.element('name')}>
                  {passenger.firstName} {passenger.lastName}
                </div>
                <div className={this.element('extraInfo')}>
                  <div className={this.element('type')}>
                    {passenger.passengerType}
                  </div>
                  {passenger.infant && (
                    <div className={this.element('infant')}>+</div>
                  )}
                </div>
              </div>
              {seat && (
                <div className={this.element('seat')}>{seat.seatNumber}</div>
              )}
            </div>
          );
        })}
      </div>
    );
  }

  renderMobilSelector() {
    const { currentPassengerId, passengersList } = this.props;

    const currentPassengerIndex = passengersList.findIndex(
      p => p.passengerId === currentPassengerId
    );

    return (
      <div className={this.block({ collapsed: true })}>
        {currentPassengerIndex !== -1 && (
          <div className={this.element('item')}>
            {currentPassengerIndex !== 0 && (
              <div
                className={this.element('arrow', { isPrev: true })}
                onClick={() =>
                  this.handleSelectPassenger(
                    passengersList[currentPassengerIndex - 1].passengerId
                  )
                }
              />
            )}
            {currentPassengerIndex !== passengersList.length - 1 && (
              <div
                className={this.element('arrow', { isNext: true })}
                onClick={() =>
                  this.handleSelectPassenger(
                    passengersList[currentPassengerIndex + 1].passengerId
                  )
                }
              />
            )}
            <div>
              <div className={this.element('name')}>
                {passengersList[currentPassengerIndex].firstName}{' '}
                {passengersList[currentPassengerIndex].lastName}
                {passengersList[currentPassengerIndex].infant && (
                  <div className={this.element('infant')}>+</div>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }

  render() {
    const { mini, mobileBreakpoint } = this.props;

    const layoutBreakPoint = mobileBreakpoint || LAYOUT_BREAKS.portraitTablet;

    return (
      <div>
        <MediaQuery minWidth={layoutBreakPoint}>
          {mini ? this.renderMobilSelector() : this.renderDesktopSelector()}
        </MediaQuery>
        <MediaQuery maxWidth={layoutBreakPoint - 1}>
          {this.renderMobilSelector()}
        </MediaQuery>
      </div>
    );
  }
}
