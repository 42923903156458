import React from 'react';
import { Modal } from 'layouts/modal';
import ContactInfo from 'payment/components/contactInfoContent';
import modalDecorator from '_helpers/modalDecorator';

@modalDecorator
export class ContactInfoDialog extends React.Component {
  render() {
    return (
      <Modal ref="modal" plain>
        <ContactInfo popup />
      </Modal>
    );
  }
}
