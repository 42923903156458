import React from 'react';
import { Bem } from 'react-bem-classes';
import './bootstrapError.scss';

const bem = new Bem('bootstrapError');

export function BootstrapError(props: { errorText: string }) {
  const { errorText } = props;

  return (
    <div className={bem.block()}>
      <div>An error happened during initialization</div>
      <div>{errorText || 'Please try again later'}</div>
    </div>
  );
}
