import { createUrlParams, parseUrlParams } from '_helpers/urlHelper';
import { appConfig } from 'config';
import {
  homePageIdSelector,
  isLoggedInSelector,
} from 'authorization/selectors';
import { Action, createBrowserHistory, History, Location } from 'history';
import { store } from 'store';
import { IPaxshopPage, PaxshopPageId } from 'types/routing';
import { updateRouting } from './actions';
import { ALL_PAGES_MAP, matchPaxshopPage } from './pages';

export let history: History = null;

export function goToPage(
  id: PaxshopPageId,
  params?: { [id: string]: string }
): void {
  const page = ALL_PAGES_MAP[id.toUpperCase()];

  const urlParams = params ? createUrlParams(params) : '';

  if (!page) {
    throw new Error(`Invalid page id ${id}`);
  }

  history.push(page.url + urlParams);
}

export function goToLoginPage() {
  const paxPartner = store.getState().init.paxshopAppCtx.paxPartner;

  goToPage('LOGIN', {
    resellerData: paxPartner ? paxPartner.resellerData : undefined,
  });
}

function filterLocationChange(
  newPage: IPaxshopPage,
  newParams: { [key: string]: string },
  newLocation: Location
): boolean {
  const state = store.getState();

  const loggedId = isLoggedInSelector(state);

  if (!newPage) {
    goToLoginPage();
    return false;
  }

  if (newPage.pageId === 'LOGIN' && loggedId) {
    goToPage(homePageIdSelector(state));
    return false;
  }

  if (!loggedId && newPage.auth.requiresLogin) {
    goToLoginPage();
    return false;
  }

  return true;
}

function onLocationChanged(location: Location, action: Action) {
  const paxshopPage = matchPaxshopPage(location.pathname);

  const urlParams = parseUrlParams(location.search);

  if (!filterLocationChange(paxshopPage, urlParams, location)) {
    return;
  }

  store.dispatch(
    updateRouting({
      paxshopPage,
      urlParams,
      currentUrl: location.pathname,
    })
  );
}

export function setupRouting() {
  if (history) {
    throw new Error('Trying to set up routing more than once');
  }

  history = createBrowserHistory({
    basename: appConfig.baseUrl,
  });
  history.listen(onLocationChanged);

  onLocationChanged(history.location, history.action);
}
