import './index.scss';
import React, { Component } from 'react';
import { Bem } from 'react-bem-classes';
import MediaQuery from 'react-responsive';
import { connect } from 'react-redux';
import { localeDataSelector } from 'languages/selectors';

@connect(state => ({
  localeData: localeDataSelector(state),
}))
class ContactInfo extends Component {
  render() {
    const { localeData } = this.props;

    const bem = new Bem('contactInfo', this.props, ['popup']);

    return (
      <div className={bem.block()}>
        <MediaQuery query={SCREENS_MEDIA.mobileAndSmaller}>
          <a
            className={bem.element('phoneIcon')}
            href={`tel:${localeData.printCmsText('CONTACT_INFO_PHONE')}`}
          />
        </MediaQuery>
        <MediaQuery query={SCREENS_MEDIA.portraitTabletAndLarger}>
          <div className={bem.element('phoneIcon')} />
        </MediaQuery>
        <h3 className={bem.element('questionHeader')}>
          {localeData.printCmsText('CONTACT_INFO_HEADER')}
        </h3>
        <div
          className={bem.element('text')}
          dangerouslySetInnerHTML={{
            __html: localeData.printCmsText('CONTACT_INFO_TEXT'),
          }}
        />
        {localeData.ifCmsFilled('CONTACT_INFO_LABEL', contactInfoLabel => (
          <div
            className={bem.element('text')}
            dangerouslySetInnerHTML={{ __html: contactInfoLabel }}
          />
        ))}
        <MediaQuery query={SCREENS_MEDIA.mobileAndSmaller}>
          <a
            className={bem.element('phoneNumber', {
              additional: localeData.cmsKeys.hasOwnProperty(
                'CONTACT_INFO_PHONE_2'
              ),
            })}
            href={`tel:${localeData.printCmsText('CONTACT_INFO_PHONE')}`}
          >
            {localeData.printCmsText('CONTACT_INFO_PHONE')}
          </a>
        </MediaQuery>
        <MediaQuery query={SCREENS_MEDIA.portraitTabletAndLarger}>
          <div className={bem.element('phoneNumber')}>
            {localeData.printCmsText('CONTACT_INFO_PHONE')}
          </div>
        </MediaQuery>

        {localeData.ifCmsFilled('CONTACT_INFO_LABEL_2', contactInfoLabel2 => (
          <div
            className={bem.element('label')}
            dangerouslySetInnerHTML={{ __html: contactInfoLabel2 }}
          />
        ))}
        {localeData.ifCmsFilled('CONTACT_INFO_PHONE_2', contactInfoPhone2 => [
          <MediaQuery query={SCREENS_MEDIA.mobileAndSmaller}>
            <a
              className={bem.element('phoneNumber', { additional: true })}
              href={`tel:${contactInfoPhone2}`}
            >
              {contactInfoPhone2}
            </a>
          </MediaQuery>,
          <MediaQuery query={SCREENS_MEDIA.portraitTabletAndLarger}>
            <div className={bem.element('phoneNumber', { additional: true })}>
              {contactInfoPhone2}
            </div>
          </MediaQuery>,
        ])}

        {localeData.ifCmsFilled('CONTACT_INFO_LABEL_3', contactInfoLabel3 => (
          <div
            className={bem.element('label')}
            dangerouslySetInnerHTML={{ __html: contactInfoLabel3 }}
          />
        ))}
        {localeData.ifCmsFilled('CONTACT_INFO_PHONE_3', contactInfoPhone3 => [
          <MediaQuery query={SCREENS_MEDIA.mobileAndSmaller}>
            <a
              className={bem.element('phoneNumber', { additional: true })}
              href={`tel:${contactInfoPhone3}`}
            >
              {contactInfoPhone3}
            </a>
          </MediaQuery>,
          <MediaQuery query={SCREENS_MEDIA.portraitTabletAndLarger}>
            <div className={bem.element('phoneNumber', { additional: true })}>
              {contactInfoPhone3}
            </div>
          </MediaQuery>,
        ])}
      </div>
    );
  }
}

export default ContactInfo;
