import { IServiceFromApi } from 'types/services';
import { PAXSHOP_LOGOUT_ACTION_ID } from 'authorization/actions/constants';

function monkeyPatchServices(services: IServiceFromApi[]): IServiceFromApi[] {
  return services.map((service: IServiceFromApi) => {
    if (service.tags.includes('seating') || service.tags.includes('GPST')) {
      return {
        ...service,
        uniqueNumGroup: -1,
      };
    }

    const PRIMARY_GROUPS = ['baggage'];

    // We rely on tags order to figure out what tag is primary and what is secondary
    // see: selectSubgroups to get an idea.
    // Unfortunetely we have to manually specify what tags are "primary" for us here.
    // TODO: This should be solved by adding a concept of primary and secondary groups on backend
    service.tags = service.tags.sort((tag1, tag2) => {
      if (PRIMARY_GROUPS.includes(tag1) && !PRIMARY_GROUPS.includes(tag2)) {
        return 1;
      }
      return -1;
    });
    return service;
  });
}

export default function(
  state: IServiceFromApi[] = [],
  action
): IServiceFromApi[] {
  switch (action.type) {
    case 'SERVICES/LIST_UPDATED':
      return monkeyPatchServices(action.payload);
    case PAXSHOP_LOGOUT_ACTION_ID:
      return [];
    default:
      return state;
  }
}
