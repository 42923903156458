import { combineReducers } from 'redux';
import variants from './variants';
import currentPayVariant from './currentPayVariant';
import orders from './orders';
import groupedOrders from './groupedOrders';
import payment from './payment';
import { IBookingGroupedOrder } from '../../types/payment';
import { IBookingOrder } from '../../types/backendProtocol';

type GroupedReducerType = () => IBookingGroupedOrder[];
type OrdersReducerType = () => IBookingOrder[];

export default combineReducers({
  variants,
  currentPayVariant,
  orders: orders as OrdersReducerType,
  groupedOrders: groupedOrders as GroupedReducerType,
  payment,
});
