import { Message } from '../../api';
import { IEnhancedBookingOrder } from '../../../types/payment';

/*
 * In the mess of messages finds lounge pass link
 * and matching service in orders to show lounge name
 */
export function findLoungePasses(
  messages: Message[],
  orders: IEnhancedBookingOrder[]
) {
  const confirmations = messages.filter(
    message =>
      message.messageCode === 'Booking confirmation: ' &&
      message.type === 'IMPORTANT'
  );

  const objects = confirmations.map(confirmation => {
    const matchingOrder = orders.find(
      order => order.componentId === confirmation.componentId
    );

    return {
      link: confirmation.message,
      serviceName: matchingOrder && matchingOrder.name,
    };
  });

  return objects;
}
