import {
  IServiceFromApiForPassenger,
  IServiceInBasket,
  ServiceOfferStatus,
} from 'types/services';

function canBeAddedUnsharedService(
  service: IServiceFromApiForPassenger,
  basket: IServiceInBasket[],
  currentSegmentId: number
) {
  const countInBasket = basket.filter(
    i => i.segmentId === currentSegmentId && i.serviceId === service.serviceId
  ).length;
  return (
    countInBasket <
    service.quantity.count -
      (service.allPassengersRequiredToBuy
        ? [
            ...service.eligiblePassengers,
            ...service.eligiblePassengersAfterSeating,
          ].length
        : 0)
  );
}

function canBeAddedSharedService(
  services: IServiceFromApiForPassenger[],
  currentSegmentId: number,
  service: IServiceFromApiForPassenger,
  basket: IServiceInBasket[]
) {
  const sharedServices = services
    .filter(svc => svc.segmentId === currentSegmentId && svc.quantity !== null)
    .filter(
      svc =>
        svc.serviceId === service.serviceId ||
        svc.quantity.sharedWithServiceId ===
          service.quantity.sharedWithServiceId ||
        svc.serviceId === service.quantity.sharedWithServiceId
    )
    .map(svc => svc.serviceId);

  const countInBasket = basket.filter(
    svc =>
      svc.segmentId === currentSegmentId &&
      sharedServices.indexOf(svc.serviceId) !== -1
  ).length;

  return countInBasket < service.quantity.count;
}

function isServiceInBasketCheck(
  service: IServiceFromApiForPassenger,
  currentSegmentId: number,
  currentPassengerId: number,
  basket: IServiceInBasket[]
) {
  return !!basket.find(
    svc =>
      svc.serviceId === service.serviceId &&
      svc.passengerId === currentPassengerId &&
      svc.segmentId === currentSegmentId
  );
}

function checkSeatInBasket(
  currentSegmentId: number,
  currentPassengerId: number,
  basket: IServiceInBasket[]
) {
  return (
    basket
      .filter(service => service.addedAs === 'seating')
      .filter(
        service =>
          service.segmentId === currentSegmentId &&
          service.passengerId === currentPassengerId
      ).length > 0
  );
}

function checkQuantity(
  list: IServiceFromApiForPassenger[],
  basket: IServiceInBasket[],
  currentSegmentId: number,
  service: IServiceFromApiForPassenger
) {
  if (service.quantity === null || service.quantity.count === null)
    return 'CAN_BUY';
  return service.quantity.sharedWithServiceId === null
    ? canBeAddedUnsharedService(service, basket, currentSegmentId)
      ? 'CAN_BUY'
      : 'SOLD_OUT'
    : canBeAddedSharedService(list, currentSegmentId, service, basket)
    ? 'CAN_BUY'
    : 'SOLD_OUT_SHARED';
}

export function getServiceOfferStatus(
  list: IServiceFromApiForPassenger[],
  basket: IServiceInBasket[],
  currentSegmentId: number,
  currentPassengerId: number,
  service: IServiceFromApiForPassenger
): ServiceOfferStatus {
  const isServiceInBasket = isServiceInBasketCheck(
    service,
    currentSegmentId,
    currentPassengerId,
    basket
  );

  if (isServiceInBasket) return 'IN_CART';
  if (service.needSeatToBuy)
    return !checkSeatInBasket(currentSegmentId, currentPassengerId, basket)
      ? 'NEED_BUY_SEAT'
      : checkQuantity(list, basket, currentSegmentId, service);

  return checkQuantity(list, basket, currentSegmentId, service);
}
