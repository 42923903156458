import { IDataContainer } from 'types/datacontainer';

export const DATA_CONTAINER_UPDATE_ACTION = 'DATA_CONTAINER_UPDATE';

export function fetchingContainerDefaultState<T>(
  pending: boolean
): IDataContainer<T> {
  return {
    availability: {
      errors: null,
      lastSuccess: null,
      pending,
    },
    data: null,
  };
}
