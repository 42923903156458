import React from 'react';
import { Modal } from 'layouts/modal';
import { Text } from 'languages/components/text';

import './index.scss';

export class FaqModalDialog extends React.Component {
  open() {
    return this.refs.modal.open();
  }

  render() {
    return (
      <Modal ref="modal" plain>
        <Text className="faqModal">ALL_PAGES_FAQ</Text>
      </Modal>
    );
  }
}
