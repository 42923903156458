import React from 'react';
import { Bem } from 'react-bem-classes';
import { T } from 'languages/components/text';
import { LoungeIcon } from './LoungeIcon';
import './index.scss';
import { findLoungePasses } from './helpers';

const bem = new Bem('myBookingLoungePass');

export function LoungePass({
  item,
}: {
  item: ReturnType<typeof findLoungePasses>[0];
}) {
  return (
    <a
      className={bem.block()}
      href={item.link}
      target="_blank"
      rel="noopener noreferrer"
    >
      <LoungeIcon width="64" height="64" />
      <span className={bem.element('text')}>
        <T>MY_BOOKING_YOUR_PASS_TO</T> {item.serviceName}
      </span>
    </a>
  );
}
