import React from 'react';
import speedDate from 'speed-date';
import cn from 'classnames';
import './index.scss';
import { DEFAULT_TIME_FORMAT } from '_helpers/dateFormat';
import { ILocaleData } from 'types/languages';
import { Moment } from 'moment';

const formatFn = (utc: boolean): ((format: string, date: Date) => string) =>
  utc ? speedDate.UTC.cached : speedDate.cached;

interface IDisplayDateProps {
  date: Moment;
  className?: string;
  localeData: ILocaleData;
  inline?: boolean;
  utc?: boolean;
}

export function DisplayDate(props: IDisplayDateProps): JSX.Element {
  const { date, className, localeData, inline, utc = true } = props;

  let shownDate = '';

  if (date) {
    shownDate = formatFn(utc)(
      localeData.dateOptions.paxshopDateFormat,
      date.toDate()
    );
  }

  return (
    <div className={cn(className, 'pxDate', { 'pxDate--inline': inline })}>
      {shownDate}
    </div>
  );
}

interface IDisplayTimeProps {
  date: Moment;
  className?: string;
  timeFormat?: string;
  strictlyShow?: boolean;
  inline?: boolean;
  utc?: boolean;
  children?: (string) => JSX.Element;
}

export function DisplayTime(props: IDisplayTimeProps): JSX.Element {
  const {
    date,
    className,
    timeFormat,
    strictlyShow,
    inline,
    utc = true,
    children,
  } = props;

  let shownTime: JSX.Element | string = '';
  if (date) {
    shownTime = formatFn(utc)(timeFormat || DEFAULT_TIME_FORMAT, date.toDate());
  }

  if (children) {
    shownTime = children(shownTime);
  }

  return (
    <div
      className={cn(className, 'pxTime', {
        'pxTime--inline': inline,
        'pxTime--strictlyShow': strictlyShow,
      })}
    >
      {shownTime}
    </div>
  );
}
