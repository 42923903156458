import localStorageHelper from '_helpers/localStorage';
import { ILanguageStoreState } from 'types/languages';
import * as actions from '../actions';
import { InferValueTypes } from '../../types/store';
import { PAXSHOP_LOGOUT_ACTION_ID } from '../../authorization/actions/constants';

type IActionTypes =
  | ReturnType<InferValueTypes<typeof actions>>
  | {
      type: typeof PAXSHOP_LOGOUT_ACTION_ID;
    };

const defaultState: ILanguageStoreState = {
  shopLangs: null,
  selectedLanguage: localStorageHelper.getLanguage() || null,
  shopCMSKeys: null,
  bookingCMSKeys: null,
  paxPartnerCMSKeys: null,
};

export default function(
  state: ILanguageStoreState = defaultState,
  action: IActionTypes
): ILanguageStoreState {
  switch (action.type) {
    case 'LANGUAGES/SET_LANGUAGE':
      localStorageHelper.setLanguage(action.lang);
      return {
        ...state,
        selectedLanguage: action.lang,
      };
    case 'LANGUAGES/SHOP_CMS_FETCH_SUCCESS':
      return {
        ...state,
        shopCMSKeys: action.payload,
      };
    case 'LANGUAGES/BOOKING_CMS_KEYS_FETCH_SUCCESS':
      return {
        ...state,
        bookingCMSKeys: action.payload,
      };
    case 'LANGUAGES/PAXPARTNER_CMS_KEYS_FETCH_SUCCESS':
      return {
        ...state,
        paxPartnerCMSKeys: action.payload,
      };
    case 'LANGUAGES/AVAILABLE_LANGUAGES_FETCHED':
      return {
        ...state,
        shopLangs: action.payload,
      };
    case PAXSHOP_LOGOUT_ACTION_ID:
      return {
        ...state,
        bookingCMSKeys: null,
      };
    default:
      return state;
  }
}
