import { fetchingContainerDefaultState } from '_helpers/datacontainer/datacontainer';
import { IInitStoreState, IPaxshopData } from 'types/init';
import { InferValueTypes } from 'types/store';
import { createInitParams } from '../helpers/initParams';
import { createPaxshopAppContext } from '../helpers/paxshopAppContext';
import * as actions from '../actions';

type ActionTypes = ReturnType<InferValueTypes<typeof actions>>;

const initParams = createInitParams();
const appCtx = createPaxshopAppContext(initParams);

const defaultState: IInitStoreState = {
  paxshopId: fetchingContainerDefaultState<number>(true),
  paxshopData: fetchingContainerDefaultState<IPaxshopData>(true),
  paxshopInitParams: createInitParams(),
  paxshopAppCtx: appCtx,
  isLoggedInAnotherBooking: false,
};

export default function(
  state: IInitStoreState = defaultState,
  action: ActionTypes
) {
  switch (action.type) {
    case 'PAXSHOP_INIT':
      return {
        ...state,
        paxshopId: {
          data: action.paxshopId,
          availability: {
            pending: false,
            errors: null,
          },
        },
      };
    case 'PAXSHOP/FETCH_SUCCESS':
      return {
        ...state,
        paxshopData: {
          data: action.payload,
          availability: {
            pending: false,
            errors: null,
          },
        },
        paxshopInitParams: {
          ...state.paxshopInitParams,
          myBookingAsIcon:
            action.payload.moveMyBooking || action.payload.wizardMenu,
          authFields: action.payload.authFields,
        },
      };
    case 'PAXSHOP_ANOTHER_BOOKING_TAB_LOGGED_IN':
      return {
        ...state,
        isLoggedInAnotherBooking: true,
      };
    default:
      return state;
  }
}
