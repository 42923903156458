/*
 * Middleware for handling actions related to ecommerce and emitting event to GA and GTM accordingly
 */
import { captureException } from '@sentry/browser';
import { MiddlewareAPI } from 'redux';
import { appConfig } from 'config';
import {
  enhancedBasketSelector,
  serviceListHelperSelector,
} from '../serviceInBasket/helpers/selectors';
import {
  IGTMProductWithCoupon,
  IGTMProductWithQuantity,
  googleTagManager,
} from '../_helpers/googleTagManager';
import { IServiceInBasket } from '../types/services';
import { IStoreState } from '../types/store';
import ServiceListHelper from '../services/_helpers/ServiceListHelper';
import { cartCurrencySelector } from '../authorization/selectors';

export default function enhancedEcommerceMiddleware({
  getState,
}: MiddlewareAPI) {
  return next => action => {
    try {
      if (action.type === 'ADD_SERVICE_TO_BASKET') {
        handleAddToBasketAction(action, getState());
      } else if (action.type === 'REMOVE_SERVICE_FROM_BASKET') {
        handleRemoveFromBasketAction(action, getState());
      } else if (action.type === 'CONFIRM_ORDER') {
        handleOrderConfirmAction(action, getState());
      }
    } catch (e) {
      console.error(e);
      captureException(e);
    }

    return next(action);
  };
}

function handleAddToBasketAction(
  action: { type: 'ADD_SERVICE_TO_BASKET'; items: IServiceInBasket[] },
  state: IStoreState
) {
  const helper = serviceListHelperSelector(state);
  const currency = cartCurrencySelector(state);

  action.items.forEach((item: IServiceInBasket) => {
    const basketItem = getProductEntity(helper, item);

    if (basketItem) {
      googleTagManager.pushToDataLayer({
        event: 'addToCart',
        ecommerce: {
          currencyCode: currency,
          add: { products: [basketItem] },
        },
      });
    }
  });
}

function handleRemoveFromBasketAction(
  action: { type: 'REMOVE_SERVICE_FROM_BASKET'; items: IServiceInBasket[] },
  state: IStoreState
) {
  const helper = serviceListHelperSelector(state);

  action.items.forEach((item: IServiceInBasket) => {
    const basketItem = getProductEntity(helper, item);

    if (basketItem) {
      googleTagManager.pushToDataLayer({
        event: 'removeFromCart',
        ecommerce: {
          remove: { products: [basketItem] },
        },
      });
    }
  });
}

function getProductEntity(
  helper: ServiceListHelper,
  item: IServiceInBasket
): IGTMProductWithQuantity {
  const matchingService = helper.findService(item);

  // The service can not yet be loaded and we can't handle this case asynchronously
  // so we just skip the service
  if (!matchingService) return null;

  const eligiblePassenger = matchingService.eligiblePassengers.find(
    passenger => passenger.passengerId === item.passengerId
  );

  return {
    name: matchingService.name,
    id: matchingService.serviceCode,
    price: eligiblePassenger && eligiblePassenger.price,
    category: item.addedAs,
    quantity: item.quantity,
  };
}

// Code only runs when payment is set to iframe / NS 2023-10-16
function handleOrderConfirmAction(
  action: { type: 'CONFIRM_ORDER'; id: number },
  state: IStoreState
) {
  const basket = enhancedBasketSelector(state);
  if (basket.validationStatus === 'VALID') {
    googleTagManager.pushToDataLayer({
      event: 'purchase',
      ecommerce: {
        currencyCode: basket.currency,
        purchase: {
          actionField: {
            revenue: basket.totalPrice,
            id: action.id,
            affiliation: appConfig.baseUrl,
          },
          products: Object.values(
            basket.serviceInstances
              .map<IGTMProductWithCoupon>(service => ({
                name: service.name,
                id: service.serviceCode,
                price: service.price,
                category: service.addedAs,
                quantity: service.quantity,
              }))
              .reduce<Record<string, IGTMProductWithCoupon>>(
                (mapped, service) => {
                  const { quantity, ...rest } = service;
                  const key = JSON.stringify(rest);
                  if (!mapped[key]) {
                    // eslint-disable-next-line no-param-reassign
                    mapped[key] = service;
                  } else {
                    // eslint-disable-next-line no-param-reassign
                    mapped[key].quantity += quantity;
                  }
                  return mapped;
                },
                {}
              )
          ),
        },
      },
    });
  }
}
