import { DATA_CONTAINER_UPDATE_ACTION } from '_helpers/datacontainer/datacontainer';
import { IAuthRes } from 'types/auth';
import { IDataContainerUpdateAction } from 'types/datacontainer';
import { PAXSHOP_LOGOUT_ACTION_ID } from 'authorization/actions/constants';
import { IServiceType } from '../../types/services';

type ActionTypes =
  | IDataContainerUpdateAction<IAuthRes>
  | { type: typeof PAXSHOP_LOGOUT_ACTION_ID };

type StateShape = Map<number, IServiceType>;

export default function serviceTypesReducer(
  state: StateShape = new Map(),
  action: ActionTypes
): StateShape {
  switch (action.type) {
    case DATA_CONTAINER_UPDATE_ACTION:
      if (
        action.containerId === 'AUTH::AUTH_DATA' &&
        !action.container.availability.pending &&
        action.container.data
      ) {
        const map = new Map();
        if (action.container.data.serviceTypes) {
          action.container.data.serviceTypes.forEach(type =>
            map.set(type.type_id, type)
          );
        }
        return map;
      }
      return state;
    case PAXSHOP_LOGOUT_ACTION_ID:
      return new Map();
    default:
      return state;
  }
}
