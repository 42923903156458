import React from 'react';
import ReactDOM from 'react-dom';
import PaxshopContext from './PaxshopContext';

export function renderPaxshop(rootNode): void {
  if (window.location.pathname.endsWith('terms-and-conditions')) {
    import(
      /* webpackChunkName: "termsAndConditions" */
      './pageTermsAndConditions'
    ).then(module => {
      const TermsAndConditionsView = module.default;

      ReactDOM.render(
        <PaxshopContext>
          <TermsAndConditionsView />
        </PaxshopContext>,
        rootNode
      );
    });
  } else {
    // Splitting paxshop into 2 bundles allows to quicker start initialisation
    // We don't use suspense here because we want to leave page loader untouched
    // until the page is completely loaded
    import(
      /* webpackChunkName: "rootView" */
      './RootView'
    ).then(module => {
      const RootView = module.default;

      ReactDOM.render(
        <PaxshopContext>
          <RootView />
        </PaxshopContext>,
        rootNode
      );
    });
  }
}
