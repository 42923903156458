import { Action } from 'redux';
import {
  IRemoveServiceFromBasketAction,
  IServiceInBasket,
} from 'types/services';
import { getAllServices } from '../../services/actions/index';
import { selectUpToDateBasket } from '../selectors/selectBasket';

const addServicesToBasketHelper = (items: IServiceInBasket[]) => ({
  type: 'ADD_SERVICE_TO_BASKET',
  items,
});

export function addServicesToBasket(items: IServiceInBasket[]) {
  const hasSsrs = items.some(item => item.ssrsAffectToSeats.length > 0);
  if (hasSsrs) {
    return (dispatch, getState) => {
      dispatch(addServicesToBasketHelper(items));
      const state = getState();
      if (state.auth.authData.data) {
        return dispatch(
          getAllServices(
            state.auth.authData.data.bookingId,
            selectUpToDateBasket(state)
          )
        );
      }
    };
  }
  return addServicesToBasketHelper(items);
}

export function removeServicesFromBasket(
  items: IServiceInBasket[]
): IRemoveServiceFromBasketAction {
  return {
    type: 'REMOVE_SERVICE_FROM_BASKET',
    items,
  };
}

export function clearBasket(): Action {
  return {
    type: 'CLEAR_BASKET',
  };
}
