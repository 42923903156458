import React, { createContext, ReactNode, useContext } from 'react';
import { allCMSKeysRawSelector } from 'languages/selectors';
import { connect } from 'react-redux';
import { IStoreState } from '../../types/store';
import { ICMSKeysMap } from '../../types/languages';

interface ILocaleUtilities {
  t: (cmsKey: string, fallback?: string) => string;
  isFilled: (cmsKey: string, allowEmpty: boolean) => boolean;
}

const noopUtilities: ILocaleUtilities = {
  t: () => {
    throw new Error('Tried to translate outside of the LocaleContext!');
  },
  isFilled: () => false,
};

const ModernLocaleContext = createContext<ILocaleUtilities>(noopUtilities);

interface IProps {
  cmsMappings: ICMSKeysMap;
  children: ReactNode;
}

const unfilledKeys = new Set();

if (__DEV__) {
  (window as any).collectUnfilledKeys = () => {
    console.log('Collected unfilled keys list');
    console.log(
      Array.from(unfilledKeys)
        .sort()
        .join('\n')
    );
  };
}

function LocaleContext({ children, cmsMappings }: IProps) {
  const localeUtilities: ILocaleUtilities = {
    isFilled: (cmsKey: string, allowEmpty: boolean = false) => {
      const hasKey = cmsMappings.hasOwnProperty(cmsKey);
      return allowEmpty ? hasKey : hasKey && cmsMappings[cmsKey].length > 0;
    },
    t: (cmsKey: string, fallbackText: string) => {
      const isFilled = cmsMappings.hasOwnProperty(cmsKey);

      if (__DEV__) {
        const keysLoaded = Object.keys(cmsMappings).length > 0;

        if (!isFilled && keysLoaded) {
          unfilledKeys.add(cmsKey);
          console.warn(`Unfilled CMS key: ${cmsKey}`);
        }
      }

      if (isFilled) return cmsMappings[cmsKey];
      if (fallbackText) return fallbackText;
      return cmsKey;
    },
  };

  return (
    <ModernLocaleContext.Provider value={localeUtilities}>
      {children}
    </ModernLocaleContext.Provider>
  );
}

export default connect((state: IStoreState) => ({
  cmsMappings: allCMSKeysRawSelector(state),
}))(LocaleContext);

export function useLocale() {
  return useContext(ModernLocaleContext);
}
