// import { parseUrlParams } from '_helpers/urlHelper';
import { IPaxReferenceData } from 'types/backendProtocol';
import { IPaxshopInitParams } from 'types/init';

function getRelativePath(): string {
  const baseElem = document.querySelector('base');
  if (!baseElem) {
    return null;
  }

  const basePath = baseElem.getAttribute('href');

  const fullPath = location.pathname;

  if (!fullPath.startsWith(basePath)) {
    return null;
  }

  return fullPath.substr(basePath.length);
}

export function createInitParams(): IPaxshopInitParams {
  // const queryParamsOld = parseUrlParams(location.search);

  // ----------- New impl start
  const url = new URL(window.location.href);
  const queryParams: Record<string, any> = {};

  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of url.searchParams.entries()) {
    queryParams[key] = value;
  }
  // ----------- new impl ends

  const refData: IPaxReferenceData = {
    referrer: document.referrer || null,
    utmSource: queryParams.utm_source || null,
    utmCampaign: queryParams.utm_campaign || null,
    utmMedium: queryParams.utm_medium || null,
  };

  const ctx: IPaxshopInitParams = {
    queryParams,
    deepLink: null,
    paxPartner: null,
    initialPath: getRelativePath(),
    referenceData: null,
    myBookingAsIcon: false,
    authFields: [],
    paymentFinish: queryParams.paymentFinish
      ? queryParams.paymentFinish.toUpperCase() === 'OK'
        ? 'OK'
        : 'FAIL'
      : null,
    paymentFailStatus: queryParams.paymentFailStatus || null,
  };

  if (
    (queryParams.bookingNumber && queryParams.departureDate) ||
    (queryParams.bookingNumber && queryParams.lastName)
  ) {
    ctx.deepLink = {
      bookingNumber: queryParams.bookingNumber,
      departureDate: queryParams.departureDate,
      lastName: queryParams.lastName,
    };
  }

  if (queryParams.resellerData) {
    ctx.paxPartner = {
      resellerData: queryParams.resellerData,
    };
  }

  if (
    refData.referrer ||
    refData.utmCampaign ||
    refData.utmMedium ||
    refData.utmSource
  ) {
    ctx.referenceData = refData;
  }

  return ctx;
}
