import './baseStyles';
import { store } from 'store';
import { initBreakOut } from 'payment/breakOut';
import { initSeatingWidget } from 'seatingWidget';

import * as payment from 'payment/init';
import * as init from 'init';
import * as initWidget from 'init/checkinWidgetLite';
import { initLanguages } from 'languages/selectors';
import * as auth from 'authorization/selectors';
import { fetchInitialPaxshopData } from './init/actions';
import { fetchShopLanguages } from './languages/actions';

const isCheckinWidget = location.pathname.includes('checkin-widget');

store.dispatch(fetchInitialPaxshopData());
store.dispatch(fetchShopLanguages());
initLanguages(store);
auth.initialize(store);

if (isCheckinWidget) {
  initWidget.onAppInit(store);
} else {
  initSeatingWidget();
  initBreakOut();
  payment.onAppInit(store);
  init.onAppInit(store);
}
