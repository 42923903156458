import {
  backendErrorFromAxiosError,
  defaultBackendErrorHandler,
} from '_helpers/backendError';

export default function apiMiddleware(store) {
  return next => action => {
    const {
      api,
      successHandler,
      errorHandler,
      staleCheck,
      staleHandler,
      defaultErrorHandler,
      type,
      ...rest
    } = action;

    if (!api) return next(action);

    const httpPromise = api(store.getState);

    const TYPE_SUCCESS = type;
    const TYPE_REQUEST = `${type}_REQUEST`;
    const TYPE_FAILURE = `${type}_FAILURE`;

    next({ type: TYPE_REQUEST, ...rest });

    const apiPromise = new Promise((resolve, reject) => {
      const customResolve = __resolutionKind => data =>
        resolve({ data, __resolutionKind });
      const customReject = __resolutionKind => data =>
        reject({ data, __resolutionKind });

      httpPromise.then(
        res => {
          if (
            staleCheck &&
            staleCheck(store, customResolve('stale'), customReject('stale'))
          ) {
            return;
          }

          if (successHandler) {
            successHandler(
              res,
              store,
              customResolve('handler'),
              customReject('handler')
            );
          } else {
            customResolve('defaultSuccess')(res);
          }
        },
        error => {
          if (
            staleCheck &&
            staleCheck(store, customResolve('stale'), customReject('stale'))
          ) {
            return;
          }
          const backendError = backendErrorFromAxiosError(error);
          let handled = false;
          if (errorHandler) {
            handled = errorHandler(
              backendError,
              store,
              customResolve('handler'),
              customReject('handler')
            );
          }

          if (!handled) {
            defaultBackendErrorHandler(backendError);
            customReject('defaultError')(backendError);
          }
        }
      );
    });

    return new Promise((resolve, reject) => {
      apiPromise.then(
        res => {
          switch (res.__resolutionKind) {
            case 'stale':
              if (staleHandler) {
                staleHandler(resolve, reject);
              } else {
                resolve(res.data);
              }
              break;

            default:
              next({ type: TYPE_SUCCESS, res: res.data, ...rest });
              resolve(res.data);
              break;
          }
        },
        error => {
          switch (error.__resolutionKind) {
            case 'stale':
              if (staleHandler) {
                staleHandler(resolve, reject);
              } else {
                reject(error.data);
              }
              break;

            case 'defaultError':
              next({ type: TYPE_FAILURE, error: error.data, ...rest });
              if (defaultErrorHandler) {
                defaultErrorHandler(resolve, reject);
              } else {
                reject(error.data);
              }
              break;

            default:
              next({ type: TYPE_FAILURE, error: error.data, ...rest });
              reject(error.data);
              break;
          }
        }
      );
    });
  };
}
