import localStorageHelper from '_helpers/localStorage';
import { logout } from 'authorization/actions';
import { clearBasket } from 'basket/actions';
import { store } from 'store';

export function paxShopLogout() {
  store.dispatch(clearBasket());
  localStorageHelper.resetAllButLanguage();
  store.dispatch(logout());
}
