import {
  currentSegmentSelector,
  notCurrentSegmentSelector,
} from 'segments/helpers/selectors';
import { createSelector } from 'reselect';
import { ISeatBind } from 'types/seatingWidget';
import { IServiceInBasket } from 'types/services';
import { IStoreState } from 'types/store';
import { IEnhancedSegment, ISegment } from 'types/segments';
import { IPassenger } from 'types/passengers';
import {
  passengersSelector,
  currentPassengerSelector,
} from 'passengers/helpers/selectors';
import { selectUpToDateBasket } from '../../basket/selectors/selectBasket';

const selectOrders = (state: IStoreState): any[] => state.payment.orders;

const selectSelectedSeats = createSelector(
  selectUpToDateBasket,
  (serviceInBasket: IServiceInBasket[]) =>
    serviceInBasket.filter(service => service.addedAs === 'seating')
);

export const selectSeatsForOtherSegment = createSelector(
  passengersSelector,
  notCurrentSegmentSelector,
  selectSettledSeatsForSegment
);

export const selectSeatsForCurrentSegment = createSelector(
  passengersSelector,
  currentSegmentSelector,
  selectSettledSeatsForSegment
);

export const chosenSeatsSelectorForCurrentSegment = createSelector(
  (state: IStoreState) => state.segments.currentSegmentId,
  selectSelectedSeats,
  selectSeatsForCurrentSegment,
  (
    currentSegmentId: number,
    selectedSeats: IServiceInBasket[],
    settledSeats: ISeatBind[]
  ) =>
    settledSeats.length > 0
      ? settledSeats
      : selectedSeats
          .filter(seat => seat.segmentId === currentSegmentId)
          .map(seat => ({
            seatNumber: `${seat.seatNumber.rowNumber}${seat.seatNumber.columnLetter}`,
            passengerId: seat.passengerId,
          }))
);

export const selectAreSeatsBought = createSelector(
  selectSeatsForCurrentSegment,
  (settledSeats: ISeatBind[]) => settledSeats.length > 0
);

export {
  selectAreSeatsBought as IsSeatsBought,
  selectAreSeatsBought as isSeatBought,
};

export function selectSettledSeatsForSegment(
  passengers: IPassenger[],
  segment: IEnhancedSegment
): ISeatBind[] {
  return segment
    ? passengers
        .map(passenger => ({
          passengerId: passenger.passengerId,
          seatNumber: passenger.passengerInfo.find(
            info => info.segmentId === segment.segmentId
          ).seatNumber,
        }))
        .filter(info => info.seatNumber)
    : [];
}

export const selectCurrentPassengerSegmentSeat = createSelector(
  currentPassengerSelector,
  currentSegmentSelector,
  (currentPassenger, currentSegment) => {
    const infoForCurrentSegment = currentPassenger.passengerInfo.find(
      info => info.segmentId === currentSegment.segmentId
    );
    if (infoForCurrentSegment && infoForCurrentSegment.seatNumber) {
      return infoForCurrentSegment.seatNumber;
    }
    return null;
  }
);

const haveAvailabilityToSelectSeatsForSegment = (
  segment: ISegment,
  servicesInBasket: IServiceInBasket[],
  orders: any[],
  attachedSeats: ISeatBind[]
) =>
  !!segment &&
  ![
    `CMS_SEATING_SALES_WINDOWS_CLOSED_${segment.direction}`,
    `CMS_FLIGHT_DEPARTED_${segment.direction}`,
    `CMS_SALES_BLOCKED_${segment.direction}`,
  ].includes(segment.cmsMessages[0]) &&
  !servicesInBasket.find(
    s => s.addedAs === 'seating' && s.segmentId === segment.segmentId
  ) &&
  !orders.find(
    s =>
      s.serviceTag === 'seating' &&
      s.segmentId === segment.segmentId &&
      !s.passengerServiceCancelled
  ) &&
  !attachedSeats.length;

export const haveAvailabilityToSelectSeatsForCurrentSegment = createSelector(
  currentSegmentSelector,
  selectUpToDateBasket,
  selectOrders,
  selectSeatsForCurrentSegment,
  haveAvailabilityToSelectSeatsForSegment
);

export const haveAvailabilityToSelectSeatsForOtherSegment = createSelector(
  notCurrentSegmentSelector,
  selectUpToDateBasket,
  selectOrders,
  selectSeatsForOtherSegment,
  haveAvailabilityToSelectSeatsForSegment
);
