import { IBackedCommError } from 'types/backendProtocol';
import { getDialogs } from '../dialogs/helpers';

export function anyToBackendError(err): IBackedCommError {
  if (err instanceof Error) {
    return {
      errorKind: 'ERROR_REQ_FAILED',
      statusCode: null,
      data: null,
      wrappedError: err,
    };
  }
  return backendErrorFromAxiosError(err);
}

export function backendErrorFromAxiosError(axiosError): IBackedCommError {
  axiosError = axiosError.wrappedError
    ? axiosError.wrappedError.response
    : axiosError.response
    ? axiosError.response
    : axiosError;
  if (axiosError.data && axiosError.data.errorCode) {
    return {
      errorKind: 'ERROR_STRUCTURED',
      statusCode: axiosError.status,
      data: axiosError.data,
    };
  }
  if (axiosError.status) {
    return {
      errorKind: 'ERROR_BAD_RESPONSE',
      statusCode: axiosError.status,
      data: null,
    };
  }
  return {
    errorKind: 'ERROR_REQ_FAILED',
    statusCode: null,
    data: null,
  };
}

export function defaultBackendErrorHandler(backendErr: IBackedCommError) {
  const dialogs = getDialogs();
  if (!dialogs) return;
  switch (backendErr.errorKind) {
    case 'ERROR_STRUCTURED':
      dialogs.showTextInfoDialog(
        'error',
        'CMS_GENERIC_ERROR_TITLE',
        backendErr.data.cmsKeyForUserError
      );
      return;

    case 'ERROR_BAD_RESPONSE':
      dialogs.showTextInfoDialog(
        'error',
        'CMS_GENERIC_ERROR_TITLE',
        'ERROR_BAD_RESPONSE'
      );
      return;

    case 'ERROR_REQ_FAILED':
      dialogs.showTextInfoDialog(
        'error',
        'CMS_GENERIC_ERROR_TITLE',
        'CMS_GENERIC_ERROR_REQ_FAILED'
      );
      return;

    default:
      dialogs.showTextInfoDialog(
        'error',
        'CMS_GENERIC_ERROR_TITLE',
        'CMS_GENERIC_ERROR_REQ_FAILED'
      );
  }
}
