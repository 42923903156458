import localStorageHelper from '_helpers/localStorage';
import { IPaxshopAppContext, IPaxshopInitParams } from 'types/init';
import { IPaxPartnerData } from 'types/store';

function createPaxPartnerCtx(initParams: IPaxshopInitParams): IPaxPartnerData {
  if (initParams.paxPartner) {
    return { resellerData: initParams.paxPartner.resellerData, lastName: null };
  }
  const savedPaxPartnerData = localStorageHelper.getPaxPartnerData();
  if (savedPaxPartnerData && initParams.initialPath === '') {
    // if user opened home page without paxPartner params, remove it from localStore
    localStorageHelper.setPaxPartnerData(null);
    return null;
  }

  return savedPaxPartnerData;
}

export function createPaxshopAppContext(
  initParams: IPaxshopInitParams
): IPaxshopAppContext {
  return {
    paxPartner: createPaxPartnerCtx(initParams),
  };
}
