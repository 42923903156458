import React from 'react';

export function LoungeIcon(props: React.SVGAttributes<SVGElement>) {
  return (
    <svg height={256} viewBox="0 0 64 64" width={256} {...props}>
      <path d="M30 45H8a1 1 0 00-1 1v2c0 1.654 1.346 3 3 3h18c1.654 0 3-1.346 3-3v-2a1 1 0 00-1-1zm-1 3c0 .551-.448 1-1 1H10c-.552 0-1-.449-1-1v-1h20zM14 30h10v2H14z" />
      <path d="M60.5 35a2.503 2.503 0 00-2.5 2.5v.5h-2.296l-1.455-9.457A2.985 2.985 0 0051.284 26H38.716a2.985 2.985 0 00-2.965 2.544L34.296 38h-4.592l-1.455-9.457A2.985 2.985 0 0025.284 26H12.716a2.985 2.985 0 00-2.965 2.544L8.296 38H6v-.5C6 36.122 4.879 35 3.5 35S1 36.122 1 37.5v23C1 61.878 2.121 63 3.5 63S6 61.878 6 60.5V54h52v6.5c0 1.378 1.121 2.5 2.5 2.5s2.5-1.122 2.5-2.5v-23c0-1.378-1.121-2.5-2.5-2.5zM36 40.077l1.728-11.229a.995.995 0 01.988-.848h12.568c.497 0 .912.357.988.848L54 40.077V41c0 .551-.448 1-1 1H37c-.552 0-1-.449-1-1zm-26 0l1.728-11.229a.995.995 0 01.988-.848h12.568c.497 0 .912.357.988.848L28 40.077V41c0 .551-.448 1-1 1H11c-.552 0-1-.449-1-1zM4 60.5a.5.5 0 01-1 0v-23a.5.5 0 011 0zM6 52V40h2v1c0 1.654 1.346 3 3 3h16c1.654 0 3-1.346 3-3v-1h4v1c0 1.654 1.346 3 3 3h16c1.654 0 3-1.346 3-3v-1h2v12zm55 8.5a.5.5 0 01-1 0v-23a.5.5 0 011 0z" />
      <path d="M56 45H34a1 1 0 00-1 1v2c0 1.654 1.346 3 3 3h18c1.654 0 3-1.346 3-3v-2a1 1 0 00-1-1zm-1 3c0 .551-.448 1-1 1H36c-.552 0-1-.449-1-1v-1h20zM40 30h10v2H40zM32 19c4.963 0 9-4.038 9-9s-4.037-9-9-9-9 4.038-9 9 4.037 9 9 9zm0-16c3.859 0 7 3.14 7 7s-3.141 7-7 7-7-3.14-7-7 3.141-7 7-7z" />
      <path d="M32 11h4V9h-3V4h-2v6a1 1 0 001 1z" />
    </svg>
  );
}
