import { from, Observable, of } from 'rxjs';
import { Action } from 'redux';
import { combineEpics, StateObservable } from 'redux-observable';
import {
  catchError,
  concatAll,
  distinctUntilChanged,
  filter,
  map,
  pluck,
} from 'rxjs/operators';
import { IStoreState } from 'types/store';
import { DATA_CONTAINER_UPDATE_ACTION } from '_helpers/datacontainer/datacontainer';
import { anyToBackendError } from '_helpers/backendError';
import { auth, norecAuth } from '../helpers/backendAuth';

function authorizeOnCredentialsChange(
  $action: Observable<Action>,
  $state: StateObservable<IStoreState>
) {
  return $state.pipe(
    pluck('auth', 'authSrcData', 'data'),
    distinctUntilChanged(),
    filter(authSrcData => authSrcData !== null),
    map(authSrcData =>
      authSrcData.authType === 'BOOKING'
        ? auth(authSrcData.bookingSrc, $state.value)
        : norecAuth(authSrcData.norecSrc, $state.value)
    ),
    map(promise => from(promise)),
    map(response =>
      response.pipe(
        map(result => ({
          type: DATA_CONTAINER_UPDATE_ACTION,
          containerId: 'AUTH::AUTH_DATA',
          container: {
            availability: {
              errors: null,
              lastSuccess: new Date().getTime(),
              pending: false,
            },
            data: result,
          },
        })),
        catchError(error =>
          of({
            type: DATA_CONTAINER_UPDATE_ACTION,
            containerId: 'AUTH::AUTH_DATA',
            container: {
              availability: {
                errors: [anyToBackendError(error)],
                lastSuccess: null,
                pending: false,
              },
              data: null,
            },
          })
        )
      )
    ),
    concatAll()
  );
}

export default combineEpics(authorizeOnCredentialsChange);
