import { appConfig } from 'config';
import { compileBasketForServiceContext } from 'payment/helpers';
import { IFabService, IServiceFromApi, IServiceInBasket } from 'types/services';
import { makeRequest } from '_middlewares/http';
import { Action, Dispatch } from 'redux';
import { IStoreState } from '../../types/store';
import { selectFabScopeList } from '../selectors/fab';

export function getAllServices(bookingId, basket: IServiceInBasket[] = []) {
  return (dispatch: Dispatch<any>, getState: () => IStoreState) => {
    dispatch(fetchRetailServices(bookingId, basket));
    const fabScopes = selectFabScopeList(getState());
    dispatch(fetchFabServices(fabScopes));
  };
}

export function fetchRetailServices(
  bookingId: number,
  basket: IServiceInBasket[] = []
) {
  const basketInfo = compileBasketForServiceContext(
    basket.filter(s => (s.ssrsAffectToSeats || []).length > 0)
  );

  return makeRequest<{ services: IServiceFromApi[] }>({
    method: 'POST',
    url: `${appConfig.apiUrl}/v2/bookings/${bookingId}?services=true`,
    data: { basket: basketInfo },
    onSuccess: data => updateServiceList(data.services),
  });
}

export function updateServiceList(services: IServiceFromApi[]) {
  return {
    type: 'SERVICES/LIST_UPDATED',
    payload: services,
  } as const;
}

export function setCurrentServiceGroup(group) {
  return {
    type: 'SET_CURRENT_SERVICE_GROUP',
    group,
  } as const;
}

export function fetchFabServices(scopes: string[]) {
  return (dispatch: Dispatch<Action>) => {
    scopes.forEach(scope =>
      dispatch(
        makeRequest<{ services: IFabService[] }>({
          method: 'GET',
          label: `SERVICES/FAB:${scope}`,
          url: `/retail-gateway/fab/api/${scope}`,
          onSuccess: ({ services }) =>
            receiveFABServicesUpdates(scope, services),
        })
      )
    );
  };
}

export function receiveFABServicesUpdates(
  scope: string,
  services: IFabService[]
) {
  return {
    type: 'SERVICES/FAB_FETCHED',
    scope,
    services: services.map(service => ({
      ...service,
      text: formatFABDescription(service.text),
    })),
  } as const;
}

function formatFABDescription(text: string) {
  return text
    .substring(1, text.length - 1)
    .replace(/\.\s+/g, '.\n\n')
    .replace(/Opening: /g, '')
    .replace(/Closing: /g, '- ')
    .replace(/[A-Z][a-z]+ \d\d:\d\d - \d\d:\d\d/g, value => `\n${value}`);
}
