import { appConfig } from 'config';
import { clearBasket } from 'basket/actions';
import { IPaxshopReduxStore } from 'types/store';
import { googleTagManager } from '_helpers/googleTagManager';

export function onAppInit(store: IPaxshopReduxStore): void {
  const storeState = store.getState();
  const initParams = storeState.init.paxshopInitParams;

  // Used to be initParams.paymentFinish
  if (['confirmation', 'payment-failed'].includes(initParams.initialPath)) {
    let query = '';

    Object.keys(initParams.queryParams).forEach(key => {
      if (
        Object.prototype.hasOwnProperty.call(initParams.queryParams, key) &&
        key !== 'paymentFinish' &&
        key !== 'paymentFailStatus'
      ) {
        if (query) {
          query += '&';
        }
        query += `${encodeURIComponent(key)}=${encodeURIComponent(
          initParams.queryParams[key]
        )}`;
      }
    });

    if (query) {
      query = `?${query}`;
    }

    history.pushState(
      null,
      null,
      `${appConfig.baseUrl}${initParams.initialPath}${query}`
    );

    if (initParams.initialPath === 'confirmation') {
      store.dispatch(clearBasket());
      googleTagManager.pushToDataLayer({ event: 'paymentSuccess' });
    } else if (initParams.initialPath === 'payment-failed') {
      googleTagManager.pushToDataLayer(
        { event: 'paymentFailure' },
        { paymentFailStatus: initParams.queryParams.paymentFailStatus || '' }
      );
    }
  }
}
