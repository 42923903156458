import immediate from 'immediate';
import { captureException } from '@sentry/browser';

export default function errorLogger(error) {
  if (error instanceof Error) {
    captureException(error);

    // We don't want to interrupt process, but throw right after
    immediate(() => {
      throw error;
    });
  }
}
