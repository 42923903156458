import React from 'react';
import { Bem } from 'react-bem-classes';
import { connect } from 'react-redux';

import { ServiceModalDialog } from 'services/components/modal/index';
import { ObservationPopupDialog } from 'services/components/observationPopup/index';
import { FaqModalDialog } from 'layouts/pageHeader/components/faqDialog/index';
import { CookieDialog } from 'layouts/pageHeader/components/cookieDialog/index';
import { MyBookingDialog } from 'layouts/pageHeader/components/myBookingDialog/index';
import { BoardingPassesDialog } from 'layouts/pageHeader/components/boardingPassesDialog/index';
import { ContactInfoDialog } from 'layouts/pageHeader/components/contactInfoDialog/index';
import { TextInfoModal } from 'dialogs/components/textInfoModal/index';

import { ValizoBannerModal } from 'services/components/vaziloBannerModal/index';
import { ConfirmDeletingDialog } from 'basket/components/confirmDeletingModal';
import { setDialogs } from 'dialogs/helpers';
import { allCMSKeysRawSelector } from 'languages/selectors';
import { isLoggedInSelector } from 'authorization/selectors';
import { totalPriceSelector } from 'serviceInBasket/helpers/selectors';
import { paxShopLogout } from '_helpers/logout';
import './index.scss';
import PropTypes from 'prop-types';
import MultiPaxApisDialog from 'apis/containers/multiPaxApisDialog';
import PaxApisDialog from 'apis/containers/paxApisDialog';
import { selectAllServices } from 'services/selectors';
import { selectUpToDateBasket } from '../../../basket/selectors/selectBasket';

@connect(state => ({
  allCmsKeys: allCMSKeysRawSelector(state),
  services: selectAllServices(state),
  basket: selectUpToDateBasket(state),
  basketTotalPrice: totalPriceSelector(state),
  moveMyBooking: state.init.paxshopInitParams.myBookingAsIcon,
  enableNewCheckin:
    state.init.paxshopData.data.enableNewCheckin &&
    state.init.paxshopData.data.wizardMenu,
  loggedIn: isLoggedInSelector(state),
  infantModalVisible:
    !!state.auth.authData.data &&
    !state.auth.authData.data.booking.infantRefInfo.allInfantsHaveRef,
}))
export class DialogsContext extends React.Component {
  static childContextTypes = {
    showPaxApisDialog: PropTypes.func.isRequired,
    showMultiPaxApisDialog: PropTypes.func.isRequired,
    showServiceInfoDialog: PropTypes.func.isRequired,
    hideServiceInfoDialog: PropTypes.func.isRequired,
    showObservationPopupDialog: PropTypes.func.isRequired,
    showFaqDialog: PropTypes.func.isRequired,
    showCookieDialog: PropTypes.func.isRequired,
    showTextInfoDialog: PropTypes.func.isRequired,
    showValizoBannerModal: PropTypes.func.isRequired,
    showConfirmationBasketDialog: PropTypes.func.isRequired,
    confirmDeletingDialog: PropTypes.object,
    paxShopLogout: PropTypes.func.isRequired,
    openMybookingPopup: PropTypes.func.isRequired,
    openBoardingPassesPopup: PropTypes.func.isRequired,
    openContactInfoPopup: PropTypes.func.isRequired,
    basket: PropTypes.array.isRequired,
    basketTotalPrice: PropTypes.number.isRequired,
    enableNewCheckin: PropTypes.bool.isRequired,
    moveMyBooking: PropTypes.bool.isRequired,
    loggedIn: PropTypes.bool.isRequired,
  };

  showPaxApisDialog = paxId =>
    this.refs.paxApisDialog.openApisDialog(`${paxId}`);

  showMultiPaxApisDialog = () =>
    this.refs.multiPaxApisDialog.openMultiPaxApisDialog();

  showServiceInfoDialog = (service, addToBasket, addCartProps) => {
    this.refs.serviceDialog.open(service, addToBasket, addCartProps);
  };

  hideServiceInfoDialog = () => {
    this.refs.serviceDialog.hide();
  };

  showObservationPopupDialog = options =>
    this.refs.observationPopupDialog.open(options);

  showFaqDialog = () => this.refs.faqDialog.open();

  showCookieDialog = cookieLink => this.refs.cookieDialog.open(cookieLink);

  showTextInfoDialog = (type, titleCms, contentCms, closeButtonCms) =>
    this.refs.textInfoDialog.open(type, titleCms, contentCms, closeButtonCms);

  showValizoBannerModal = () => this.refs.valizoBannerModal.open();

  showConfirmationBasketDialog = props =>
    this.refs.confirmDeletingDialog.open(props);

  openMybookingPopup = () => {
    this.refs.MybookingModal.open();
  };

  openBoardingPassesPopup = () => {
    this.refs.BoardingPassesModal.open();
  };

  openContactInfoPopup = () => {
    this.refs.contactInfoPopup.open();
  };

  getChildContext() {
    return {
      showPaxApisDialog: this.showPaxApisDialog,
      showMultiPaxApisDialog: this.showMultiPaxApisDialog,
      showServiceInfoDialog: this.showServiceInfoDialog,
      hideServiceInfoDialog: this.hideServiceInfoDialog,
      showObservationPopupDialog: this.showObservationPopupDialog,
      showFaqDialog: this.showFaqDialog,
      showCookieDialog: this.showCookieDialog,
      showTextInfoDialog: this.showTextInfoDialog,
      showValizoBannerModal: this.showValizoBannerModal,
      showConfirmationBasketDialog: this.showConfirmationBasketDialog,
      confirmDeletingDialog: this.refs.confirmDeletingDialog,
      paxShopLogout,
      openMybookingPopup: this.openMybookingPopup,
      openBoardingPassesPopup: this.openBoardingPassesPopup,
      openContactInfoPopup: this.openContactInfoPopup,
      basket: this.props.basket,
      basketTotalPrice: this.props.basketTotalPrice,
      enableNewCheckin: this.props.enableNewCheckin,
      moveMyBooking: this.props.moveMyBooking,
      loggedIn: this.props.loggedIn,
    };
  }

  componentDidMount() {
    const dialogs = this.getChildContext();
    setDialogs(dialogs);
  }

  render() {
    const bem = new Bem('dialogsContext');
    return (
      <div className={bem.block()}>
        {React.Children.only(this.props.children)}
        <div key="dialogs">
          <PaxApisDialog ref="paxApisDialog" />
          <MultiPaxApisDialog ref="multiPaxApisDialog" />
          <ServiceModalDialog ref="serviceDialog" />
          <ObservationPopupDialog ref="observationPopupDialog" />
          <FaqModalDialog ref="faqDialog" />
          <CookieDialog ref="cookieDialog" />
          <TextInfoModal ref="textInfoDialog" />
          <ValizoBannerModal ref="valizoBannerModal" />
          <ConfirmDeletingDialog ref="confirmDeletingDialog" />
          <MyBookingDialog ref="MybookingModal" />
          <BoardingPassesDialog ref="BoardingPassesModal" />
          <ContactInfoDialog ref="contactInfoPopup" />
        </div>
      </div>
    );
  }
}
