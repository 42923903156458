import React, { Component } from 'react';
import { connect } from 'react-redux';
import { allCMSKeysRawSelector } from 'languages/selectors';

@connect(state => ({
  cmsKeys: allCMSKeysRawSelector(state),
}))
class HtmlText extends Component {
  render() {
    const { children, cmsKeys, dispatch, ...restProps } = this.props;

    const text = cmsKeys[children] !== undefined ? cmsKeys[children] : children;

    return (
      <div
        {...restProps}
        data-cms={children}
        dangerouslySetInnerHTML={{ __html: text }}
      />
    );
  }
}

export default HtmlText;
