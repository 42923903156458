import React, { Component } from 'react';
import bem from 'react-bem-classes';
import moment from 'moment';
import { DisplayTime } from '_helpers/displayDate';
import BoardingPassesDownload from 'check-in/containers/boardingPasses';
import { Text } from 'languages/components/text';
import './index.scss';

@bem({
  block: 'modalCheckin',
})
class ModalCheckin extends Component {
  constructor(props) {
    super(props);

    this.state = {
      currentSegment:
        props.selectedSegment || props.checkinAvailableSegments[0],
      submitting: false,
      initialNextCheckinSegment:
        props.checkinAvailableSegments.length > 1
          ? props.checkinAvailableSegments[1]
          : null,
      step: 'passengers',
      termsAndConditionsAccepted: false,
      clickedDisabledNext: false,
    };
  }

  toggleAcceptTerms = event => {
    this.setState({
      termsAndConditionsAccepted: event.target.checked,
    });
  };

  clickDisabledNext = () => {
    this.setState({ clickedDisabledNext: true });
  };

  openPassengersPage() {
    this.setState({
      step: 'passengers',
    });
  }

  openConditionsPage = () => {
    this.setState({
      step: 'termsAndConditions',
    });
  };

  openSuccessPage = () => {
    console.log(
      'send',
      'event',
      'Page',
      'Check in',
      'Check in Agree and Check in'
    );
    if (
      this.props.onSuccessCheckin &&
      typeof this.props.onSuccessCheckin === 'function'
    ) {
      this.props.onSuccessCheckin();
    }

    this.props.sendCheckin(this.state.currentSegment.segmentId).then(
      () => {
        this.setState({
          step: 'success',
        });
      },
      () => {
        this.setState({
          step: 'error',
        });
      }
    );
  };

  openNextSegment(nextCheckinSegment) {
    this.setState({
      currentSegment: nextCheckinSegment,
      step: 'passengers',
    });
  }

  renderHeader(selectedSegment) {
    return !this.props.enableNewCheckin ? (
      <div className={this.element('titleOld')}>
        {this.props.localeData.printCmsText('MODAL_CHECKIN_TITLE')}
      </div>
    ) : (
      <div
        className={this.element('title', {
          direction: selectedSegment.direction.toLowerCase(),
        })}
      >
        <div className={this.element('segmentElement', { 'col-type': 'date' })}>
          <div className={this.element('titleColumnHeader')}>
            {this.props.localeData.printCmsText(
              'CHECKIN_FLIGHT_DATE_TITLE_TEXT'
            )}
          </div>
          <div className={this.element('titleColumnContent')}>
            {moment(selectedSegment.flights[0].departureDateTime).format(
              'YYYY-MM-DD'
            )}
          </div>
        </div>
        <div
          className={this.element('segmentElement', {
            'col-type': 'departure',
          })}
        >
          <div className={this.element('titleColumnHeader')}>
            {this.props.localeData.printCmsText(
              'MY_BOOKING_PAGE_OUTBOUND_BLOCK_DEPARTURE_TEXT'
            )}
          </div>
          <div className={this.element('titleColumnContent')}>
            <DisplayTime
              inline
              className={this.element('time')}
              utc
              date={selectedSegment.flights[0].departureDateTimeMoment}
            />
            <span className={this.element('airportName')}>
              {selectedSegment.flights[0].departureAirportName}
            </span>
          </div>
        </div>
        <div
          className={this.element('segmentElement', { 'col-type': 'arrival' })}
        >
          <div className={this.element('titleColumnHeader')}>
            {this.props.localeData.printCmsText(
              'MY_BOOKING_PAGE_OUTBOUND_BLOCK_ARRIVAL_TEXT'
            )}
          </div>
          <div className={this.element('titleColumnContent')}>
            <DisplayTime
              inline
              className={this.element('time')}
              utc
              date={
                selectedSegment.flights[selectedSegment.flights.length - 1]
                  .arrivalDateTimeMoment
              }
            />
            <span className={this.element('airportName')}>
              {
                selectedSegment.flights[selectedSegment.flights.length - 1]
                  .arrivalAirportName
              }
            </span>
          </div>
        </div>
      </div>
    );
  }

  renderPassengersList(passengers, seatingOrders = []) {
    return (
      passengers &&
      passengers.length > 0 && (
        <div className={this.element('passengersList')}>
          {passengers.map((passenger, index) => {
            const passengerInfoSeat = passenger.passengerInfo.find(
              info => info.segmentId === this.state.currentSegment.segmentId
            );

            const seat = passengerInfoSeat.seatNumber
              ? passengerInfoSeat
              : seatingOrders.find(
                  seat => seat.passengerId === passenger.passengerId
                );

            return (
              <div
                key={`${
                  passenger.passengerId
                }_${this.state.currentSegment.direction.toLowerCase()}`}
                className={this.element('passengerRow')}
              >
                <div className={this.element('nameColumn')}>
                  {passenger.firstName} {passenger.lastName}
                </div>
                {seat && !!seat.seatNumber && (
                  <div className={this.element('seatColumn')}>
                    {typeof seat.seatNumber === 'string'
                      ? seat.seatNumber
                      : seat.seatNumber.rowNumber +
                        seat.seatNumber.columnLetter}
                  </div>
                )}
              </div>
            );
          })}
        </div>
      )
    );
  }

  renderConditions() {
    return (
      <div className={this.element('termsAndConditions')}>
        <h3 className={this.element('termsAndConditions', { header: true })}>
          {this.props.localeData.printCmsText(
            'CHECKIN_TERMS_AND_CONDITIONS_TITLE_TEXT'
          )}
        </h3>
        <div
          className={this.element('termsAndConditions', { text: true })}
          dangerouslySetInnerHTML={{
            __html: this.props.localeData.printCmsText(
              'CHECKIN_TERMS_AND_CONDITIONS_CONTENT_TEXT'
            ),
          }}
        />
      </div>
    );
  }

  renderError() {
    return (
      <div className={this.element('errorMessage')}>
        {this.props.localeData.printCmsText('CHECKIN_ERROR_MESSAGE_TEXT')}
      </div>
    );
  }

  render() {
    const {
      sendCheckinLoader,
      sendCheckinFailed,
      sendCheckin,
      localeData,
      enableNewCheckin,
      isMobileWidget,
      boughtSeats,
      passengersList,
      services,
    } = this.props;

    const { step, currentSegment, initialNextCheckinSegment } = this.state;

    const segmentSeats = boughtSeats.filter(
      seatingOrder => seatingOrder.segmentId === currentSegment.segmentId
    );

    const step2nextAction = {
      passengers: this.openConditionsPage,
      termsAndConditions: this.openSuccessPage,
    };

    const disableContinueButton =
      this.state.step === 'termsAndConditions' &&
      !this.state.termsAndConditionsAccepted;

    const fastTrackIsAvailable = () =>
      services?.some(
        s =>
          s.segmentId === currentSegment.segmentId &&
          s.serviceCode === 'FT' &&
          s.eligiblePassengers.length
      );

    return (
      <div className={this.block()}>
        {!(isMobileWidget && step === 'success') &&
          this.renderHeader(currentSegment)}

        {!enableNewCheckin && (
          <div>
            <div
              className={this.element('text')}
              dangerouslySetInnerHTML={{
                __html: localeData.printCmsText('MODAL_CHECKIN_TEXT'),
              }}
            />
            <div className={this.element('buttonRow')}>
              <button
                className={this.element('checkinButton', {
                  old: true,
                  disabled: sendCheckinLoader,
                })}
                onClick={() => {
                  sendCheckin(currentSegment.segmentId);
                }}
              >
                {localeData.printCmsText('MODAL_CHECKIN_BUTTON')}
              </button>
            </div>
          </div>
        )}
        {enableNewCheckin && (
          <div>
            {step === 'passengers' &&
              this.renderPassengersList(passengersList, segmentSeats)}

            {step === 'termsAndConditions' && this.renderConditions()}

            {(step === 'error' || sendCheckinFailed) && this.renderError()}

            {step === 'success' && !sendCheckinFailed && (
              <BoardingPassesDownload />
            )}

            <div
              className={this.element('buttonRow', {
                hide:
                  step === 'success' &&
                  initialNextCheckinSegment &&
                  currentSegment === initialNextCheckinSegment,
              })}
            >
              {step === 'termsAndConditions' && (
                <div
                  className={this.element('acceptTermsWrapper', {
                    invalid:
                      !this.state.termsAndConditionsAccepted &&
                      this.state.clickedDisabledNext,
                  })}
                >
                  <label htmlFor="checkin-accept-terms">
                    <input
                      type="checkbox"
                      name="accept-terms"
                      id="checkin-accept-terms"
                      onChange={this.toggleAcceptTerms}
                    />
                    <Text useSpan>CHECKIN_ACCEPT_TERMS_LABEL</Text>
                  </label>
                </div>
              )}
              {step !== 'success' && (
                // Only for Jettime
                <div
                  className={this.element(
                    fastTrackIsAvailable() &&
                      step !== 'termsAndConditions' &&
                      step !== 'success'
                      ? 'checkinButtonWrapperFastTrack'
                      : 'checkinButtonWrapper'
                  )}
                >
                  {fastTrackIsAvailable() &&
                    step !== 'termsAndConditions' &&
                    step !== 'success' && (
                      <span
                        dangerouslySetInnerHTML={{
                          __html: localeData.printCmsText(
                            'MODAL_CHECKIN_FAST_TRACK_TEXT'
                          ),
                        }}
                      />
                    )}
                  <button
                    type="button"
                    className={this.element('checkinButton', {
                      next: true,
                      disabled: sendCheckinLoader || disableContinueButton,
                    })}
                    onClick={() => {
                      if (
                        step === 'termsAndConditions' &&
                        !this.state.termsAndConditionsAccepted
                      ) {
                        this.clickDisabledNext();
                        return;
                      }
                      if (typeof step2nextAction[step] === 'function') {
                        step2nextAction[step].apply(this);
                      }
                    }}
                  >
                    {localeData.printCmsText(
                      'MODAL_CHECKIN_CONTINUE_BUTTON_TEXT'
                    )}
                  </button>
                </div>
              )}
              {initialNextCheckinSegment &&
                currentSegment !== initialNextCheckinSegment &&
                step === 'success' && (
                  <button
                    className={this.element('checkinButton', {
                      next: true,
                      disabled: sendCheckinLoader,
                    })}
                    onClick={() =>
                      this.openNextSegment(initialNextCheckinSegment)
                    }
                  >
                    {localeData.printCmsText(
                      'MODAL_CHECKIN_NEXT_FLIGHT_BUTTON_TEXT'
                    )}
                  </button>
                )}
            </div>
          </div>
        )}
        <div className={`local-loader ${sendCheckinLoader ? 'active' : ''}`} />
      </div>
    );
  }
}

export default ModalCheckin;
