import { appConfig } from 'config';

export function createUrlParams(params: {
  [name: string]: string | number;
}): string {
  let res = '';
  for (const param in params) {
    if (!params.hasOwnProperty(param)) {
      continue;
    }

    if (typeof params[param] === 'undefined') {
      continue;
    }

    res += res.length > 0 ? '&' : '?';
    res += `${encodeURIComponent(param)}=${
      params[param] ? encodeURIComponent(params[param]) : ''
    }`;
  }

  return res;
}

export function parseUrlParams(paramsStr: string): { [key: string]: string } {
  const res: { [key: string]: string } = {};
  if (!paramsStr || !paramsStr.startsWith('?')) {
    return res;
  }

  const pairs = paramsStr.substr(1).split('&');
  for (let i = 0; i < pairs.length; i++) {
    const kvStr = pairs[i];

    const separatorPos = kvStr.indexOf('=');

    if (separatorPos === -1) {
      continue;
    }

    res[decodeURIComponent(kvStr.substr(0, separatorPos))] = decodeURIComponent(
      kvStr.substr(1 + separatorPos)
    );
  }

  return res;
}

export class URIBuilder {
  base: string;

  params: { [paramName: string]: string };

  private constructor(base: string, params: { [paramName: string]: string }) {
    this.base = base;
    this.params = params;
  }

  addParam(param: any, value: any) {
    this.params[`${param}`] = `${value}`;
  }

  getParamsString(): string {
    let res = '';
    for (const param in this.params) {
      if (this.params.hasOwnProperty(param)) {
        if (res) res += '&';
        res += `${encodeURIComponent(param)}=${encodeURIComponent(
          this.params[param]
        )}`;
      }
    }

    return res;
  }

  toString(): string {
    const paramsString = this.getParamsString();
    let res = this.base;
    if (paramsString) {
      res += `?${paramsString}`;
    }

    return res;
  }

  static createForURI(uri: string): URIBuilder {
    const paramsSepPos = uri.indexOf('?');

    if (paramsSepPos === -1) {
      return new URIBuilder(uri, {});
    }

    const base = uri.substr(0, paramsSepPos);
    const kvStrings = uri.substr(paramsSepPos).split('&');
    const kvs = {};

    for (let i = 0; i < kvStrings.length; i++) {
      const kv = kvStrings[i].split('=');

      const paramName = decodeURIComponent(kv[0]);

      const paramValue = kv.length > 1 ? decodeURIComponent(kv[1]) : '';

      kvs[paramName] = paramValue;
    }

    return new URIBuilder(base, kvs);
  }
}

export function getCurrentOrigin(): string {
  return `${location.protocol}//${location.host}${appConfig.baseUrl}`;
}
