import * as React from 'react';
import PropTypes from 'prop-types';
import { reduxForm } from 'redux-form';
import { validate, validateEmail } from '_helpers/reduxFormHelpers';
import { Bem } from 'react-bem-classes';
import TextInput from 'commonBlocks/components/textInput';
import { connect } from 'react-redux';
import { allCMSKeysRawSelector } from 'languages/selectors';

function validateMailForm(values) {
  return validate(values, { email: [validateEmail] });
}

const modalBem = new Bem('modalMail');

@connect(state => ({
  cmsKeys: allCMSKeysRawSelector(state),
}))
@reduxForm({
  form: 'mailConfirmationForm',
  fields: ['email'],
  validate: validateMailForm,
})
export default class MailConfirmationModal extends React.Component {
  static contextTypes = {
    store: PropTypes.object.isRequired,
  };

  renderInputs() {
    const { localeData, cmsKeys } = this.props;

    return (
      <div>
        <div className={modalBem.element('formCol')}>
          <TextInput
            name="email"
            placeholder={localeData.printCmsText('ENTER_EMAIL_ADDRESS')}
            cmsKeys={cmsKeys}
          />
        </div>
      </div>
    );
  }

  render() {
    const {
      successMail,
      submitMailForm,
      sendMailLoader,
      handleSubmit,
      localeData,
    } = this.props;

    return (
      <div className={modalBem.block()}>
        <div className={modalBem.element('title')}>
          {localeData.printCmsText('CMS_CONFIRMATION_MAIL_TITLE')}
        </div>
        {successMail && (
          <div>
            <i className={modalBem.element('successIcon')} />
            <div className={modalBem.element('text')}>
              {localeData.printCmsText('CMS_CONFIRMATION_MAIL_SUCCESS_TEXT')}
            </div>
          </div>
        )}

        {!successMail && (
          <div>
            <form
              ref="mailBoardingPassForm"
              className={modalBem.element('formRoot')}
              onSubmit={handleSubmit(submitMailForm)}
            >
              <div className={modalBem.element('mailForm')}>
                {this.renderInputs()}
              </div>
              <div className={modalBem.element('buttonRow')}>
                <button
                  className={modalBem.element('sendButton', {
                    disabled: sendMailLoader,
                  })}
                  ref="submitButton"
                  type="submit"
                >
                  <span>
                    {localeData.printCmsText('CMS_CHECKIN_MAIL_BUTTON')}
                  </span>
                </button>
              </div>
            </form>
          </div>
        )}
        <div className={`local-loader ${sendMailLoader ? 'active' : ''}`} />
      </div>
    );
  }
}
