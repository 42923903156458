import React from 'react';
import bem from 'react-bem-classes';
import SegmentItem from 'segments/components/segmentsItem/index';

@bem({
  block: 'segmentsSelector',
})
export class SegmentSelectorMobile extends React.Component {
  constructor() {
    super();

    this.state = {
      isOpen: false,
    };
  }

  onSegmentClick = segment => {
    if (this.state.isOpen) {
      this.props.onSegmentSelect(segment);
    }
    this.setState({
      isOpen: !this.state.isOpen,
    });
  };

  render() {
    const { seatingErrors } = this.props;

    const currentSegment = this.props.segmentsList.find(
      segment => segment.segmentId === this.props.currentSegmentId
    );

    const anotherSegment = this.props.segmentsList.find(
      segment => segment.segmentId !== this.props.currentSegmentId
    );

    return !currentSegment ? null : (
      <div className={this.block({ isOpen: this.state.isOpen })}>
        <SegmentItem
          errorMessageCms={
            (
              (seatingErrors || []).find(
                error => error.airPathDirection === currentSegment.direction
              ) || {}
            ).description || ''
          }
          isSingleSegment={this.props.segmentsList.length === 1}
          segment={currentSegment}
          isCurrent
          onClick={() =>
            this.props.segmentsList.length > 1 &&
            this.onSegmentClick(currentSegment)
          }
        />
        {this.state.isOpen && (
          <SegmentItem
            errorMessageCms={
              (
                (seatingErrors || []).find(
                  error => error.airPathDirection === anotherSegment.direction
                ) || {}
              ).description || ''
            }
            segment={anotherSegment}
            isCurrent={false}
            onClick={() => this.onSegmentClick(anotherSegment)}
          />
        )}
      </div>
    );
  }
}
