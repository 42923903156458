import { InferValueTypes } from 'types/store';
import * as actions from '../actions';
import { IAvailableCountry, ITermsAndConditionsEntry } from '../types';
import { IBackedCommError } from '../../types/backendProtocol';

type TPaymentState =
  | 'WAITING_FOR_INFO'
  | 'IFRAME_PAYMENT'
  | 'HTML_SNIPPET'
  | 'FILL_CONTACTS';

const defaultState = {
  status: 'WAITING_FOR_INFO' as TPaymentState,
  paymentIframeUrl: '',
  htmlSnippet: '',
  confirmationHtmlSnippet: '',
  loading: false,
  availableCountries: [] as IAvailableCountry[],
  basketId: null as string | null,
  termsAndConditions: [] as ITermsAndConditionsEntry[],
  basketError: null as IBackedCommError | null,
};

type StateType = typeof defaultState;

type IActionTypes = ReturnType<InferValueTypes<typeof actions>>;

export default function payment(
  state = defaultState,
  action: IActionTypes
): StateType {
  switch (action.type) {
    case 'PAYMENT_INIT':
      return defaultState;

    case 'PAYMENT_SUBMIT':
      if (action.payload.responseType === 'IFRAME') {
        return {
          ...state,
          status: 'IFRAME_PAYMENT',
          paymentIframeUrl: action.payload.redirectUrl,
        };
      }
      if (action.payload.responseType === 'HTML_SNIPPET') {
        return {
          ...state,
          status: 'HTML_SNIPPET',
          htmlSnippet: action.payload.klarna.html_snippet,
        };
      }
      return state;

    case 'PAYMENT/ERROR_CREATING_BASKET':
      return {
        ...state,
        basketError: action.error,
      };

    case 'PAYMENT/FETCHED_PAYMENT_INFO':
      return {
        ...state,
        status: 'FILL_CONTACTS',
        basketId: action.basketId,
        termsAndConditions: action.termsAndConditions,
        availableCountries: action.paymentCountries,
      };

    default:
      return state;
  }
}
