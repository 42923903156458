import './index.scss';

import { appConfig } from 'config';

import React from 'react';
import { connect } from 'react-redux';
import bem from 'react-bem-classes';
import * as actions from 'check-in/actions';
import { currentLangSelector } from 'languages/selectors';
import PkPass from 'check-in/components/pkPass';
import { Modal } from 'layouts/modal';
import MailModal from 'check-in/components/mailModal';
import { localeDataSelector } from 'languages/selectors';
import { passengersSelector } from 'passengers/helpers/selectors';
import { alreadyCheckedInSegmentsSelector } from 'check-in/helpers/selectors';
import { segmentsSelector } from 'segments/helpers/selectors';
import { segmentService } from 'segments/services';
import * as passengerActions from 'passengers/actions';
import { selectJwtToken } from '../../../authorization/selectors';

@connect(
  state => ({
    currentLanguage: currentLangSelector(state),
    selectedLanguage: state.languages.selectedLanguage,
    queryParams: state.init.paxshopInitParams.queryParams,

    jwtToken: selectJwtToken(state),
    localeData: localeDataSelector(state),

    alreadyCheckedInSegment: alreadyCheckedInSegmentsSelector(state),
    segmentsList: segmentsSelector(state),
    passengers: passengersSelector(state),
  }),
  dispatch => ({ dispatch })
)
@bem({
  block: 'boardingPassesDownload',
})
export default class BoardingPassesDownload extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sendMailLoader: false,
    };
    this.submitMailForm = this.submitMailForm.bind(this);
  }

  componentDidMount() {
    const { alreadyCheckedInSegment } = this.props;

    const defaultPassengerId =
      this.props.defaultPassengerId || this.props.passengers[0].passengerId;
    this.props.dispatch(passengerActions.switchPassenger(defaultPassengerId));

    if (alreadyCheckedInSegment && alreadyCheckedInSegment.length > 0) {
      segmentService.selectSegment(alreadyCheckedInSegment[0].segmentId);
    }
  }

  showMailModal() {
    this.mailModal.open();
    this.setState({
      successMail: false,
    });
  }

  submitMailForm(values) {
    this.setState({ sendMailLoader: true });

    const checkedInSegmentsIds = this.props.alreadyCheckedInSegment.map(
      segment => segment.segmentId
    );

    const checkedInSegmentsDirections = this.props.segmentsList
      .filter(segment => checkedInSegmentsIds.includes(segment.segmentId))
      .map(segment => segment.direction);

    const individualEmailData = [];
    if (!!values.emails && !!values.emails.length) {
      values.emails.forEach((email, index) => {
        if (email) {
          individualEmailData.push({
            email,
            passengerId: this.props.passengers[index].passengerId,
            segments: checkedInSegmentsDirections,
          });
        }
      });
    }

    (individualEmailData.length
      ? this.props.dispatch(
          actions.emailPkPass(individualEmailData, this.props.currentLanguage)
        )
      : this.props.dispatch(
          actions.emailBoardingPass(values.email, this.props.currentLanguage)
        )
    ).then(
      () => {
        this.setState({
          sendMailLoader: false,
          successMail: true,
        });
      },
      () => {
        this.setState({ sendMailLoader: false });
      }
    );
  }

  showPkPassModal(passenger) {
    this.pkPassModal.open(passenger);
  }

  render() {
    const {
      localeData,
      selectedLanguage,
      jwtToken,
      alreadyCheckedInSegment,
      queryParams,
      passengers,
    } = this.props;

    const { sendMailLoader, successMail } = this.state;

    return (
      <div className={this.block()}>
        <div className={this.element('successText')}>
          <div className={this.element('checkinDone', { checkmark: true })}>
            {this.props.localeData.printCmsText('CHECKIN_SUCCESS_TITLE_TEXT')}
          </div>
          <p className={this.element('checkinDone', { text: true })}>
            {this.props.localeData.printCmsText(
              'CHECKIN_SUCCESS_BOARDING_PASS_TEXT'
            )}
          </p>
        </div>

        <div className={this.element('buttonsGroup')}>
          <a
            onClick={() => this.showMailModal()}
            className={this.element('button', { mail: true })}
          >
            {localeData.printCmsText('CHECKIN_SEND_AGAIN_BUTTON_TEXT')}
          </a>
          <a
            href={`${appConfig.apiUrl}/boarding-pass/download/${selectedLanguage}?jwt=${jwtToken}`}
            className={this.element('button', { download: true })}
            target="_blank"
          >
            {localeData.printCmsText(
              'CHECKIN_DOWNLOAD_BOARDING_PASS_BUTTON_TEXT'
            )}
          </a>
        </div>

        {alreadyCheckedInSegment.length > 0 && (
          <div className={this.element('passengers')}>
            <div className={this.element('passengersListTitle')}>
              {this.props.localeData.printCmsText('SEE_THE_PASSES')}
            </div>
            {passengers.map(passenger => (
              <div
                key={passenger.id}
                className={this.element('passenger')}
                onClick={() => this.showPkPassModal(passenger)}
              >
                <div>
                  {passenger.firstName} {passenger.lastName}
                </div>
              </div>
            ))}
          </div>
        )}

        {alreadyCheckedInSegment.length > 0 && (
          <Modal ref={c => (this.pkPassModal = c)} newDesign mobileFullScreen>
            <PkPass />
          </Modal>
        )}

        <Modal
          ref={c => (this.mailModal = c)}
          newDesign
          plain
          popupRootClass="mailModalPopup"
        >
          <MailModal
            sendMailLoader={sendMailLoader}
            successMail={successMail}
            submitMailForm={this.submitMailForm}
            queryParamEmail={queryParams.email}
            localeData={localeData}
            passengers={passengers}
          />
        </Modal>
      </div>
    );
  }
}
