import { cartCurrencySelector } from 'authorization/selectors';
import { passengersSelector } from 'passengers/helpers/selectors';
import {
  currentSegmentSelector,
  segmentsSelector,
} from 'segments/helpers/selectors';
import ServiceListHelper from 'services/_helpers/ServiceListHelper';
import SERVICE_GROUPS_EXCEPTIONS from 'services/constants/serviceGroupsExceptions';
import { createSelector } from 'reselect';
import { IPassenger } from 'types/passengers';
import {
  ISeatSegmentValidationResult,
  ISeatsValidationResult,
} from 'types/seatingWidget';
import { IEnhancedSegment, ISegment } from 'types/segments';
import {
  IEnhancedBasket,
  IEnhancedBasketService,
  ILinkedServiceValidationError,
  IServiceErrorMatcher,
  IServiceInBasket,
  IServiceInBasketWithPrice,
  IServiceValidationError,
} from 'types/services';
import { IStoreState } from 'types/store';
import { selectAllServices } from 'services/selectors';
import { selectUpToDateBasket } from '../../basket/selectors/selectBasket';

export const serviceListHelperSelector = createSelector(
  selectAllServices,
  servicesFromApi => new ServiceListHelper(servicesFromApi)
);

export const servicesForBasketSelector = createSelector(
  selectUpToDateBasket,
  serviceListHelperSelector,
  (
    servicesInBasket: IServiceInBasket[],
    serviceListHelper: ServiceListHelper
  ): IServiceInBasketWithPrice[] =>
    servicesInBasket
      .map(serviceInBasket => ({
        ...serviceInBasket,
        price: serviceInBasket.eligiblePassengers.find(
          p => p.passengerId === serviceInBasket.passengerId
        ).price,
      }))
      .filter(Boolean)
);

export const totalPriceSelector = createSelector(
  servicesForBasketSelector,
  (services: IServiceInBasketWithPrice[]) =>
    services.reduce((sum, item) => sum + item.price * item.quantity, 0)
);

export function serviceMatches(
  serviceItem: IServiceInBasket,
  matcher: IServiceErrorMatcher
) {
  if (
    matcher.serviceId !== undefined &&
    matcher.serviceId !== serviceItem.serviceId
  )
    return false;
  if (
    matcher.segmentId !== undefined &&
    matcher.segmentId !== serviceItem.segmentId
  )
    return false;
  if (
    matcher.passengerId !== undefined &&
    matcher.passengerId !== serviceItem.passengerId
  )
    return false;
  if (matcher.addedAs !== undefined && matcher.addedAs !== serviceItem.addedAs)
    return false;
  return true;
}

function formatSegment(segment: ISegment): string {
  const start = segment.flights[0];

  const end = segment.flights[segment.flights.length - 1];

  return `${start.departureCityName ||
    start.departureAirportName ||
    '<Unknown location>'} — ${end.arrivalCityName ||
    end.arrivalAirportName ||
    '<Unknown location>'}`;
}

function formatPassenger(passenger: IPassenger): string {
  return `${passenger.firstName} ${passenger.lastName}`;
}

function createPrefixes(
  validationError: IServiceValidationError,
  segments: ISegment[],
  passengers: IPassenger[]
): string[] {
  const matcher = validationError.serviceMatcher;
  const res: string[] = [];
  if (matcher.segmentId !== undefined) {
    const segment = segments.find(seg => seg.segmentId === matcher.segmentId);
    res.push(segment ? formatSegment(segment) : '<Unknown flight>');
  }

  if (matcher.passengerId !== undefined) {
    const passenger = passengers.find(
      p => p.passengerId === matcher.passengerId
    );
    res.push(passenger ? formatPassenger(passenger) : '<Unknown Passenger>');
  }

  return res;
}

function convertSeatingValidationToBasketErrors(
  seatsValidationData: ISeatSegmentValidationResult[],
  segments: ISegment[]
): IServiceValidationError[] {
  return !seatsValidationData
    ? []
    : seatsValidationData
        .filter(checkData => !checkData.status)
        .map(checkData => ({
          serviceMatcher: {
            serviceTag: 'seating',
            segmentId: segments.find(
              s => s.direction === checkData.airPathDirection
            ).segmentId,
          },
          errorMessage: checkData.description,
        }));
}

function buildEnhancedBasket(
  services: IServiceInBasketWithPrice[],
  totalPrice: number,
  errors: IServiceValidationError[],
  cartError: string,
  validating: boolean,
  cartCurrency: string,
  segments: ISegment[],
  passengers: IPassenger[]
): IEnhancedBasket {
  const enhancedServices: IEnhancedBasketService[] = services.map(item =>
    Object.assign({}, item, {
      currency: cartCurrency,
      affectedByErrors: [],
      direction: (
        segments.find(s => s.segmentId === item.segmentId) || {
          direction: null,
        }
      ).direction,
    })
  );

  const linkedErrors: ILinkedServiceValidationError[] = errors.map(err =>
    Object.assign({}, err, {
      uniqueId: `${err.serviceMatcher.serviceId || '!'}--${err.serviceMatcher
        .segmentId || '!'}--${err.serviceMatcher.passengerId || '!'}--${err
        .serviceMatcher.addedAs || '!'}`,
      affectedServices: [],
      messagePrefixes: createPrefixes(err, segments, passengers),
    })
  );

  // link errors and services
  linkedErrors.forEach(err => {
    enhancedServices
      .filter(item => serviceMatches(item, err.serviceMatcher))
      .forEach(item => {
        item.affectedByErrors.push(err);
        err.affectedServices.push(item);
      });
  });

  return {
    serviceInstances: enhancedServices,
    validationErrors: linkedErrors,
    totalPrice,
    currency: cartCurrency,
    validationStatus: validating
      ? 'VALIDATING'
      : linkedErrors.length || cartError
      ? 'INVALID'
      : 'VALID',
    errorMessage: cartError,
  };
}

function groupEnhancedBasketServices(basket: IEnhancedBasket): IEnhancedBasket {
  const gServices = basket.serviceInstances.reduce(
    (acc, si: IServiceInBasketWithPrice) => {
      const fs = acc.find(s => si.serviceId === s.serviceId);
      if (fs) {
        fs.quantity += si.quantity;
      } else {
        acc.push(si);
      }
      return acc;
    },
    []
  );
  return Object.assign({}, basket, { serviceInstances: gServices });
}

export const enhancedBasketSelector = createSelector(
  servicesForBasketSelector,
  totalPriceSelector,
  segmentsSelector,
  (state: IStoreState) => state.seating.seatSetValidation,
  cartCurrencySelector,
  passengersSelector,

  (
    services,
    totalPrice,
    segments,
    seatSetValidation,
    cartCurrency,
    passengers
  ) =>
    buildEnhancedBasket(
      services,
      totalPrice,
      convertSeatingValidationToBasketErrors(
        seatSetValidation.lastValidation &&
          seatSetValidation.lastValidation.res,
        segments
      ),
      seatSetValidation.lastValidation &&
        seatSetValidation.lastValidation.networkError
        ? 'CMS_CART_VALIDATION_NET_ERROR'
        : null,
      seatSetValidation.validationProcess.validating,
      cartCurrency,
      segments,
      passengers
    )
);

export const enhancedBasketWithGroupedServicesSelector = createSelector(
  enhancedBasketSelector,
  groupEnhancedBasketServices
);

function getSsrsForService(
  basket: IServiceInBasketWithPrice[],
  segmentId,
  passegnerId
): any[] {
  return basket
    .filter(service => service.ssrsAffectToSeats.length !== 0)
    .filter(service => service.passengerId === passegnerId)
    .filter(service => service.segmentId === segmentId)
    .map(service => service.ssrsAffectToSeats)
    .reduce((result, currentValue) => result.concat(...currentValue), []);
}

export const backendSeatValidationRequestSelector = createSelector(
  segmentsSelector,
  servicesForBasketSelector,
  (segments: ISegment[], services: IServiceInBasketWithPrice[]) =>
    segments
      .map(segment => ({
        airPathDirection: segment.direction,
        passengerSeats: services
          .filter(
            item =>
              item.addedAs == 'seating' && item.segmentId === segment.segmentId
          )
          .map(item => ({
            passengerId: item.passengerId,
            seatNumber: item.seatNumber,
            ssrs: getSsrsForService(services, item.segmentId, item.passengerId),
          })),
      }))
      .filter(segData => segData.passengerSeats.length > 0)
);

export const basketEmptySelector = createSelector(
  selectUpToDateBasket,
  (servicesInBasket: IServiceInBasket[]) => servicesInBasket.length === 0
);

export const basketSeatingServicesSelector = createSelector(
  servicesForBasketSelector,
  (services: IServiceInBasketWithPrice[]) =>
    services.filter(s => s.addedAs === 'seating')
);

export const basketBaggageServicesSelector = createSelector(
  servicesForBasketSelector,
  (services: IServiceInBasketWithPrice[]) =>
    services.filter(s => s.addedAs === 'baggage')
);

export const basketOtherServicesSelector = createSelector(
  servicesForBasketSelector,
  (services: IServiceInBasketWithPrice[]) =>
    services.filter(s => SERVICE_GROUPS_EXCEPTIONS.indexOf(s.addedAs) === -1)
);

export const hasSeatingServicesInBasket = createSelector(
  basketSeatingServicesSelector,
  services => services.length > 0
);

export const selectHasSelectedSeatsForCurrentSegment = createSelector(
  currentSegmentSelector,
  basketSeatingServicesSelector,
  (segment: IEnhancedSegment, services: IServiceInBasketWithPrice[]) =>
    services.filter(service => service.segmentId === segment.segmentId).length >
    0
);

export const hasBaggageServicesInBasket = createSelector(
  basketBaggageServicesSelector,
  services => services.length > 0
);

export const hasOtherServicesInBasket = createSelector(
  basketOtherServicesSelector,
  services => services.length > 0
);

export const lastSeatingValidationSelector = (state: IStoreState) =>
  state.seating.seatSetValidation.lastValidation;

export const hasLastSeatingValidationSelector = createSelector(
  lastSeatingValidationSelector,
  (v: ISeatsValidationResult) => !!v
);

export const seatingValidatingSelector = (state: IStoreState) =>
  state.seating.seatSetValidation.validationProcess.validating;

export const seatValidationErrorSelector = createSelector(
  lastSeatingValidationSelector,
  (validation: ISeatsValidationResult) =>
    (validation &&
      validation.res &&
      validation.res.filter(segments => !segments.status)) ||
    []
);

export const hasSeatingValidationError = createSelector(
  seatValidationErrorSelector,
  (badSegments: ISeatSegmentValidationResult[]) =>
    badSegments && badSegments.length > 0
);

export const hasSeatsForCurrentSegment = createSelector(
  selectUpToDateBasket,
  (state: IStoreState) => state.segments.currentSegmentId,
  (servicesInBasket: IServiceInBasket[], currentSegmentId: number) =>
    servicesInBasket.filter(
      (service: IServiceInBasket) =>
        service.segmentId === currentSegmentId && service.addedAs === 'seating'
    ).length > 0
);

export const basketForCurrentSegmentSelector = createSelector(
  selectUpToDateBasket,
  (state: IStoreState) => state.segments.currentSegmentId,
  (state: IStoreState) => state.passengers.currentPassengerId,
  (
    servicesInBasket: IServiceInBasket[],
    currentSegmentId: number,
    currentPassengerId: number
  ) =>
    servicesInBasket.filter(
      svc =>
        svc.segmentId === currentSegmentId &&
        svc.passengerId === currentPassengerId
    )
);

export const ssrsAffectToSeatsServicesSelector = createSelector(
  selectUpToDateBasket,
  (state: IStoreState) => state.segments.currentSegmentId,
  (servicesInBasket: IServiceInBasket[], currentSegmentId) =>
    servicesInBasket.filter(
      service =>
        (service.ssrsAffectToSeats || []).length > 0 &&
        service.segmentId === currentSegmentId
    )
);
