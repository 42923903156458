import React from 'react';
import { Provider } from 'react-redux';
import { store } from 'store';
import LocaleContext from 'languages/_helpers/localeContext';
import { DialogsContext } from './commonBlocks/components/dialogs';
import { GlobalErrorBoundary } from './errorBoundary';

function Paxshop({ children }: { children: JSX.Element }) {
  return (
    <GlobalErrorBoundary>
      <Provider store={store}>
        <LocaleContext>
          <DialogsContext>{children}</DialogsContext>
        </LocaleContext>
      </Provider>
    </GlobalErrorBoundary>
  );
}

export default Paxshop;
