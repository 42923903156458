import './index.scss';
import React, { lazy, Suspense } from 'react';
import bem from 'react-bem-classes';
import BackGroundImageFromCms from '_helpers/backgroundFromCms';
import { Text } from 'languages/components/text';
import { hot } from 'react-hot-loader/root';
import MediaQuery from 'react-responsive';
import { DisplayDate, DisplayTime } from '_helpers/displayDate';

const LazyCarousel = lazy(() =>
  import(
    /* webpackChunkName: "MyBookingCarousel" */
    './Carousel'
  )
);

@bem({
  block: 'segmentsSection',
  modifiers: ['size'],
})
class SegmentsSection extends React.Component {
  renderInfo(cmsKey, airport, date) {
    return (
      <div className={this.element('flightInfo')}>
        <Text className={this.element('flightInfoTitle')}>{cmsKey}</Text>
        <DisplayTime
          className={this.element('flightInfoTime')}
          date={date}
          utc
        />
        <div className={this.element('flightInfoAirport')}>{airport}</div>
      </div>
    );
  }

  renderSegment = segment => {
    const { localeData } = this.props;

    const params =
      segment.direction === 'OUT'
        ? {
            backgroundImageCms: 'MY_BOOKING_PAGE_OUTBOUND_IMAGE',
            boundText: 'ALL_PAGES_OUTBOUND_BLOCK_TEXT',
          }
        : {
            backgroundImageCms: 'MY_BOOKING_PAGE_INBOUND_IMAGE',
            boundText: 'ALL_PAGES_INBOUND_BLOCK_TEXT',
          };

    return (
      <div
        key={segment.segmentId}
        className={this.element('segment', {
          inbound: segment.direction === 'IN',
        })}
      >
        <div className={this.element('segmentContent')}>
          <BackGroundImageFromCms
            cmsKey={params.backgroundImageCms}
            className={this.element('image')}
          />
          <div className={this.element('header')}>
            <div
              className={this.element('departure', {
                outbound: segment.direction !== 'IN',
              })}
            >
              <Text useSpan>{params.boundText}</Text>,
            </div>
            <div className={this.element('flightNumber')}>
              {segment.flightNumber}
            </div>
            <DisplayDate
              className={this.element('date')}
              date={segment.flights[0].departureDateTimeMoment}
              localeData={localeData}
            />
          </div>
          <div className={this.element('info')}>
            {this.renderInfo(
              'MY_BOOKING_PAGE_OUTBOUND_BLOCK_DEPARTURE_TEXT',
              segment.flights[0].departureAirportName,
              segment.flights[0].departureDateTimeMoment
            )}
            {this.renderInfo(
              'MY_BOOKING_PAGE_OUTBOUND_BLOCK_ARRIVAL_TEXT',
              segment.flights[segment.flights.length - 1].arrivalAirportName,
              segment.flights[segment.flights.length - 1].arrivalDateTimeMoment
            )}
          </div>
          {this.props.passengers.map(passenger => (
            <div
              key={passenger.passengerId}
              className={this.element('passenger')}
            >
              {passenger.firstName} {passenger.lastName}
            </div>
          ))}
        </div>
      </div>
    );
  };

  render() {
    const { segments, moveMyBooking } = this.props;

    return (
      <div className={this.block()}>
        {moveMyBooking && (
          <div className={this.element('segmentFlexWrapper')}>
            {segments.map(this.renderSegment)}
          </div>
        )}

        {!moveMyBooking && (
          <MediaQuery query={SCREENS_MEDIA.desktopAndLarger}>
            <div className={this.element('segmentFlexWrapper')}>
              {segments.map(this.renderSegment)}
            </div>
          </MediaQuery>
        )}
        {!moveMyBooking && (
          <MediaQuery query={SCREENS_MEDIA.landscapeTabletAndSmaller}>
            <Suspense fallback={''}>
              <LazyCarousel>{segments.map(this.renderSegment)}</LazyCarousel>
            </Suspense>
          </MediaQuery>
        )}
      </div>
    );
  }
}
export default hot(SegmentsSection);
