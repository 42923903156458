import { API_START, API_END, ACCESS_DENIED, API_ERROR, API } from './constants';

export function apiStart(label: string) {
  return {
    type: API_START,
    label,
  } as const;
}

export function apiEnd(label: string) {
  return {
    type: API_END,
    label,
  } as const;
}

export function accessDenied(url: string, label?: string) {
  return {
    type: ACCESS_DENIED,
    label,
    payload: {
      url,
    },
  } as const;
}

export function apiError(error: any, label?: string) {
  return {
    type: API_ERROR,
    label,
    error,
  } as const;
}

interface IRequestParams<T> {
  url: string;
  method?: 'GET' | 'POST' | 'PUT' | 'DELETE';
  data?: unknown;
  onSuccess: (data: T) => unknown;
  onFailure?: (err: Error) => unknown;
  label?: string;
  headersOverride?: {};
}

export function makeRequest<T>({
  url = '',
  method = 'GET',
  data = null,
  onSuccess,
  onFailure,
  label = '',
  headersOverride = null,
}: IRequestParams<T>) {
  return {
    type: API,
    payload: {
      url,
      method,
      data,
      onSuccess,
      onFailure,
      label,
      headersOverride,
    },
  } as const;
}
