import { parseInternalDateTimeToMoment } from '_helpers/dateFormat';
import { createSelector } from 'reselect';
import { ICheckInSegment } from 'types/checkIn';
import { IAirport, IEnhancedSegment, ISegment } from 'types/segments';
import { IStoreState, PaxSelector } from 'types/store';
import { selectCheckInSegments } from 'check-in/helpers/selectors';

export const rawSegmentsSelector = (state: IStoreState): ISegment[] =>
  state.segments ? state.segments.segmentsList : [];

export const segmentsSelector: PaxSelector<IEnhancedSegment[]> = createSelector(
  rawSegmentsSelector,
  (segments: ISegment[]) =>
    segments.map(segment => ({
      ...segment,
      departureDateTimeMoment: parseInternalDateTimeToMoment(
        segment.flights[0].departureDateTime
      ),
      flights: segment.flights.map(flight => ({
        ...flight,
        departureDateTimeMoment: parseInternalDateTimeToMoment(
          flight.departureDateTime
        ),
        arrivalDateTimeMoment: parseInternalDateTimeToMoment(
          flight.arrivalDateTime
        ),
      })),
    }))
);

export const firstFlightDepartureAirportSelector: PaxSelector<
  IAirport
> = createSelector(
  rawSegmentsSelector,
  (segments: ISegment[]) => {
    const fd = segments[0].flights[0];
    return {
      code: fd.departureAirportCode,
      name: fd.departureAirportName,
    };
  }
);

export const currentSegmentSelector = createSelector(
  segmentsSelector,
  (state: IStoreState) => state.segments.currentSegmentId,
  (segments: IEnhancedSegment[], curId) =>
    segments.find(segment => segment.segmentId === curId) || segments[0]
);

export const notCurrentSegmentSelector: (
  state: IStoreState
) => ISegment = createSelector(
  segmentsSelector,
  (state: IStoreState) => state.segments.currentSegmentId,
  (segments: ISegment[], curId: number) =>
    segments.find(segment => segment.segmentId !== curId) || segments[1]
);

export const outboundSegmentSelector: PaxSelector<ISegment> = createSelector(
  segmentsSelector,
  segments => segments.find(segment => segment.direction === 'OUT')
);

export const inboundSegmentSelector: PaxSelector<
  IEnhancedSegment
> = createSelector(
  segmentsSelector,
  segments => segments.find(segment => segment.direction === 'IN')
);

export const allSegmentsDepartedSelector: (
  state: IStoreState
) => boolean = createSelector(
  segmentsSelector,
  selectCheckInSegments,
  (segments: IEnhancedSegment[], checkinSegments: ICheckInSegment[]) =>
    segments.reduce((prevDeparted, currentSegment) => {
      const checkinData = checkinSegments.find(
        checkinData => checkinData.segmentId === currentSegment.segmentId
      );

      return (
        prevDeparted &&
        ((!!checkinData && checkinData.checkinStatus === 'UNSUPPORTED') ||
          (currentSegment.cmsMessages.length > 0 &&
            currentSegment.cmsMessages[0].indexOf(
              `CMS_FLIGHT_DEPARTED_${currentSegment.direction}`
            ) !== -1))
      );
    }, true)
);
