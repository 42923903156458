const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  navigator.userAgent
);
export default isMobile;

export function isAppleDevice(): boolean {
  const appleDevices = [
    'iPhone Simulator',
    'iPod Simulator',
    'iPhone',
    'iPod',
    'MacIntel',
    'MacPPC',
  ];

  return navigator.platform && appleDevices.includes(navigator.platform);
}

// specially on iPad hide downloading of pkpass-file, because iPads don't have Wallet-app installed
export function isIpad(): boolean {
  const ipadPlatforms = ['iPad Simulator', 'iPad'];

  return navigator.platform && ipadPlatforms.includes(navigator.platform);
}
