import './index.scss';
import React from 'react';
import bem from 'react-bem-classes';

@bem({
  block: 'page',
  modifiers: ['noPadding'],
})
class Page extends React.Component {
  render() {
    return <div className={this.block()}>{this.props.children}</div>;
  }
}

@bem({
  block: 'page__content',
  modifiers: ['mobileFullWidth'],
})
export class Content extends React.Component {
  render() {
    return <div className={this.block()}>{this.props.children}</div>;
  }
}

export default Page;

export { default as Header } from './header';
export { default as SubHeader } from './subHeader';
