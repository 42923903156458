import moment from 'moment';

export const INTERNAL_DATE_FORMAT = 'YYYY-MM-DD';
export const INTERNAL_DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm:ss';
export const DEFAULT_TIME_FORMAT = 'HH:mm';

function checkFormat(fmt: string, refDate: string, refFormat: string): boolean {
  const referenceDate = moment.utc(refDate, refFormat);

  const formattedDate1 = referenceDate.format(fmt);

  const parsedDate = moment.utc(formattedDate1, fmt);

  return parsedDate.format(refFormat) === referenceDate.format(refFormat);
}

export function isDateFormatValid(fmt: string) {
  if (!fmt) {
    return false;
  }

  return (
    checkFormat(fmt, '2012-03-04', 'YYYY-MM-DD') &&
    checkFormat(fmt, '2013-07-08', 'YYYY-MM-DD')
  );
}

export function paxshopDateToInternal(date: string, fmt: string): string {
  if (!date) return date;
  return moment.utc(date, fmt).format(INTERNAL_DATE_FORMAT);
}

export function internalDateToPaxshop(date: string, fmt: string): string {
  if (!date) return date;
  return moment.utc(date, INTERNAL_DATE_FORMAT).format(fmt);
}

export function isValidPaxshopDate(date: string, fmt: string): boolean {
  if (!date || !fmt) return false;
  const internalDate = paxshopDateToInternal(date, fmt);

  return date === internalDateToPaxshop(internalDate, fmt);
}

export function parseInternalDateTimeToMoment(date: string): moment.Moment {
  return moment.utc(date, INTERNAL_DATE_TIME_FORMAT);
}

export function parseInternalDateToMoment(date: string): moment.Moment {
  return moment.utc(date, INTERNAL_DATE_FORMAT);
}

export function chooseValidDateFormat(fmts: string[]): string {
  for (let i = 0; i < fmts.length; i++) {
    if (isDateFormatValid(fmts[i])) {
      return fmts[i];
    }
  }
}

const momentTokenizerRegexp = /(\[[^\[]*\])|(\\)?([Hh]mm(ss)?|Mo|MM?M?M?|Do|DDDo|DD?D?D?|ddd?d?|do?|w[o|w]?|W[o|W]?|Qo?|YYYYYY|YYYYY|YYYY|YY|gg(ggg?)?|GG(GGG?)?|e|E|a|A|hh?|HH?|kk?|mm?|ss?|S{1,9}|x|X|zz?|ZZ?|.)/g;
const momentTokens: string[] = [
  'M',
  'MM',
  'Mo',
  'MMM',
  'MMMM',
  'Y',
  'YY',
  'YYYY',
  'YYYYY',
  'YYYYYY',
  'w',
  'ww',
  'wo',
  'W',
  'WW',
  'Wo',
  'd',
  'do',
  'dd',
  'ddd',
  'dddd',
  'e',
  'E',
  'H',
  'HH',
  'h',
  'hh',
  'k',
  'kk',
  'hmm',
  'hmmss',
  'Hmm',
  'Hmmss',
  'a',
  'A',
  'Z',
  'ZZ',
  'gg',
  'GG',
  'gggg',
  'ggggg',
  'GGGG',
  'GGGGG',
  'Q',
  'Qo',
  'D',
  'DD',
  'Do',
  'DDD',
  'DDDD',
  'DDDo',
  'm',
  'mm',
  's',
  'ss',
  'S',
  'SS',
  'SSS',
  'SSSS',
  'SSSSS',
  'SSSSSS',
  'SSSSSSS',
  'SSSSSSSS',
  'SSSSSSSSS',
  'z',
  'zz',
  'X',
  'x',
];

interface IParsedMomentToken {
  type: 'TOKEN' | 'CHARS';
  data: string;
}

export function tokenizeMomentFormat(fmt: string): IParsedMomentToken[] {
  if (!fmt) return [];
  const tokens = fmt.match(momentTokenizerRegexp);

  const res: IParsedMomentToken[] = [];
  for (let i = 0; i < tokens.length; i++) {
    const t = tokens[i];
    res.push({
      type: momentTokens.indexOf(t) >= 0 ? 'TOKEN' : 'CHARS',
      data: t,
    });
  }

  return res;
}
