import React from 'react';
import { connect } from 'react-redux';
import { cartCurrencySelector } from 'authorization/selectors';

function getPriceCaption(price, currency) {
  switch (price) {
    case 'GBP':
      return `£${price.toFixed(2)}`;
    default:
      return `${price.toFixed(2)}${currency}`;
  }
}

@connect(state => ({
  defaultCurrency: cartCurrencySelector(state),
}))
export class Price extends React.Component {
  render() {
    const {
      price,
      currency,
      defaultCurrency,
      useDiv,
      dispatch,
      ...rest
    } = this.props;

    const displayCurrency = currency || defaultCurrency;

    const displayPrice = parseFloat(price) || 0;

    const caption = getPriceCaption(displayPrice, displayCurrency);

    return useDiv ? (
      <div {...rest}>{caption}</div>
    ) : (
      <span {...rest}>{caption}</span>
    );
  }
}
