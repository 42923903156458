import './index.scss';
import React from 'react';

class Header extends React.Component {
  render() {
    const { headerClass = '' } = this.props;
    return (
      <div className={`pageHeader ${headerClass}`}>{this.props.children}</div>
    );
  }
}
export default Header;
