/*
enum NorecWizardStep {
  DISABLED,
  FLIGHT_NUMBER_FORM,
  FLIGHTS_FORM,
  AIRPORTS_FORM,
  PASSENGERS_FORM
}

*/

const defaultState = {
  norecStep: 'DISABLED' /* : NorecWizardStep */,
  bookingNumber: null,
  bookingDate: null,
  lastName: null,

  carrierFlightNumber: '',
  carrierCustomerId: null,
  overriddenTourOperatorId: null,
  cabinClassTermsAccepted: false,

  bookingConfig: {
    origin: null, // { carrierCustomerId: 123, fetching: true }
    cabinClasses: null, // [ { bookingClass: 'Y' }]
    tourOperators: null, // [ { topId: 2516, name: 'Apollomatkat' }]
  },

  segments: [0, 1].map(segIdx => ({
    segmentIndex: segIdx,
    enabled: true,
    flightNumber: '',
    departureDate: '',
    bookingClassId: null,

    departures: [],
    beginningDepartureId: '',
    endingDepartureId: '',
  })),

  passengers: [
    {
      firstName: '',
      lastName: '',
      passengerType: null,
    },
  ],

  stepsCounter: 0,
};

function mergeDepartureId(oldDepartureId, newDepartures) {
  if (newDepartures.length === 1) {
    // single-leg flight; use the only departure ID
    return newDepartures[0].departureId;
  }

  if (!oldDepartureId) {
    return null;
  }

  return newDepartures.find(d => d.departureId == oldDepartureId)
    ? oldDepartureId
    : null;
}

function reducer(state = defaultState, action) {
  switch (action.type) {
    case 'NOREC_CHANGE_BOOKING_NUMBER':
      return {
        ...state,
        bookingNumber: action.payload,
      };

    case 'NOREC_WIZARD_INIT':
      return Object.assign({}, defaultState, {
        bookingNumber: action.bookingNumber,
        bookingDate: action.bookingDate,
        lastName: action.lastName,
        stepsCounter: state.stepsCounter,
      });

    case 'NOREC_FINISH_FLIGHT_NUMBER_FORM':
      return Object.assign({}, state, {
        carrierFlightNumber: action.carrierFlightNumber,
        carrierCustomerId: action.carrierCustomerId,
        segments: state.segments.map((segment, segmentIdx) =>
          Object.assign({}, segment, {
            flightNumber: segmentIdx === 0 ? action.carrierFlightNumber : '',
          })
        ),
      });

    case 'NOREC_FINISH_FLIGHT_FORM':
      return Object.assign({}, state, {
        segments: state.segments.map((oldSeg, segIdx) =>
          Object.assign({}, oldSeg, {
            enabled: action.segments[segIdx].enabled,
            flightNumber: action.segments[segIdx].flightNumber,
            departureDate: action.segments[segIdx].departureDate,
            departures: action.segments[segIdx].departures,
            bookingClassId: action.segments[segIdx].bookingClassId,
            beginningDepartureId: mergeDepartureId(
              oldSeg.beginningDepartureId,
              action.segments[segIdx].departures
            ),
            endingDepartureId: mergeDepartureId(
              oldSeg.endingDepartureId,
              action.segments[segIdx].departures
            ),
          })
        ),

        overriddenTourOperatorId: action.overriddenTourOperatorId,
        cabinClassTermsAccepted: action.cabinClassTermsAccepted,
      });

    case 'NOREC_FINISH_AIRPORTS_FORM':
      return Object.assign({}, state, {
        segments: state.segments.map((oldSeg, segIdx) =>
          Object.assign({}, oldSeg, {
            beginningDepartureId: action.segments[segIdx].beginningDepartureId,
            endingDepartureId: action.segments[segIdx].endingDepartureId,
          })
        ),
      });

    case 'NOREC_FINISH_PASSENGERS_FORM':
      return Object.assign({}, state, {
        passengers: action.passengers,
      });

    case 'NOREC_SET_STEP':
      return Object.assign({}, state, {
        norecStep: action.norecStep,
        stepsCounter: state.stepsCounter + 1,
      });

    case 'NOREC_UPDATE_BOOKING_CONFIG':
      const cabinClasses = action.bookingConfig.cabinClasses || [];
      return Object.assign({}, state, {
        bookingConfig: action.bookingConfig,
        segments: state.segments.map(oldSegment => ({
          ...oldSegment,
          bookingClassId:
            cabinClasses.length === 1 ? cabinClasses[0].bookingClass : null,
        })),
      });

    default:
      return state;
  }
}

export type INorecState = ReturnType<typeof reducer>;

export default reducer;
