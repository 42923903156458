import localStorageHelper from '_helpers/localStorage';
import { Action } from 'redux';
import {
  IAddServiceToBasketAction,
  IRemoveServiceFromBasketAction,
  IServiceInBasket,
} from 'types/services';

const defaultState: IServiceInBasket[] = [];

function syncBasketToLocalStorage(
  items: IServiceInBasket[]
): IServiceInBasket[] {
  localStorageHelper.setBasket(items);
  return items;
}

function basketReducer(
  state: IServiceInBasket[] = defaultState,
  action: Action
): IServiceInBasket[] {
  switch (action.type) {
    case 'ADD_SERVICE_TO_BASKET':
      const addAction = action as IAddServiceToBasketAction;
      return syncBasketToLocalStorage([...state, ...addAction.items]);

    case 'REMOVE_SERVICE_FROM_BASKET':
      const removeAction = action as IRemoveServiceFromBasketAction;

      const { items } = removeAction;

      return syncBasketToLocalStorage(
        state.filter(
          item =>
            !items.find(
              s =>
                item.serviceId === s.serviceId &&
                item.passengerId === s.passengerId &&
                item.segmentId === s.segmentId
            )
        )
      );

    case 'CLEAR_BASKET':
      return syncBasketToLocalStorage([]);

    default:
      return state;
  }
}

export default basketReducer;
