import { parseInternalDateToMoment } from '_helpers/dateFormat';
import { createSelector } from 'reselect';
import { IBookingOrder } from 'types/backendProtocol';
import {
  IBookingGroupedOrder,
  IEnhancedBookingOrder,
  IEnhancedGroupedOrder,
} from 'types/payment';
import { IStoreState, PaxSelector } from 'types/store';
import { IApiService } from '../../types/services';
import { IPassenger } from '../../types/passengers';
import { passengersSelector } from '../../passengers/helpers/selectors';

export const ordersRawSelector: PaxSelector<IBookingOrder[]> = (
  state: IStoreState
) => state.payment.orders || [];

export const paidOrdersRawSelector: PaxSelector<IBookingOrder[]> = (
    state: IStoreState
) => state.payment.orders.filter(order => order.state !== 'PAYMENTS_PENDING') || [];

export const ordersSelector: PaxSelector<IEnhancedBookingOrder[]> = createSelector(
  ordersRawSelector,
  (orders: IBookingOrder[]) =>
    orders.map(order => ({
      ...order,
      dateMoment: parseInternalDateToMoment(order.date),
    }))
);

export const paidOrdersSelector: PaxSelector<IEnhancedBookingOrder[]> = createSelector(
    paidOrdersRawSelector,
    (orders: IBookingOrder[]) =>
        orders.map(order => ({
            ...order,
            dateMoment: parseInternalDateToMoment(order.date),
        }))
);

export const groupedOrdersRawSelector: PaxSelector<IBookingGroupedOrder[]> = (
  state: IStoreState
) => state.payment.groupedOrders.filter(order => order.state !== 'PAYMENTS_PENDING') || [];

export const groupedOrdersSelector: PaxSelector<IEnhancedGroupedOrder[]> = createSelector(
  groupedOrdersRawSelector,
  (orders: IBookingGroupedOrder[]) =>
    orders.map(order => ({
      ...order,
      dateMoment: parseInternalDateToMoment(order.date),
    }))
);

export const seatingOrdersSelector: PaxSelector<IBookingOrder[]> = createSelector(
  ordersRawSelector,
  (orders: IBookingOrder[]) =>
    orders.filter(
      order => order.seatNumber !== null && !order.passengerServiceCancelled
    )
);

export function selectAvailablePaymentCountries(state: IStoreState) {
  return state.payment.payment.availableCountries.sort(
    (a, b) => Number(b.default) - Number(a.default)
  );
}

export const selectDefaultPaymentCountry = createSelector(
  selectAvailablePaymentCountries,
  countries => {
    if (!countries || countries.length < 1) return undefined;
    const defaultCountry =
      countries.find(country => country.default) || countries[0];

    return defaultCountry.countryCode;
  }
);

export const selectIfServiceBoughtForPassenger = (
  state: IStoreState,
  service: IApiService,
  passenger: IPassenger
) => {
  const orders = ordersRawSelector(state);

  return Boolean(
    orders.find(
      s =>
        s.serviceId === service.serviceId &&
        s.segmentId === service.segmentId &&
        s.passengerId === passenger.passengerId
    )
  );
};

export const selectIfServiceBoughtForAllPassengers = (
  state: IStoreState,
  service: IApiService
) => {
  const passengers = passengersSelector(state);
  return passengers.every(passenger =>
    selectIfServiceBoughtForPassenger(state, service, passenger)
  );
};
