import React, { Suspense, lazy } from 'react';
import { connect } from 'react-redux';
import { Text } from 'languages/components/text';
import Page, { Header, SubHeader } from 'layouts/page';
import SegmentsSection from 'segments/components/segmentsSection';
import CheckIn from 'check-in/containers/check-in';
import Purchases from 'payment/components/groupedOrdersList';
import CurrentOrdersList from 'payment/components/currentOrdersList';
import { Modal } from 'layouts/modal';
import OrderModal from 'pageMybooking/components/orderModal';
import { passengersSelector } from 'passengers/helpers/selectors';
import { segmentsSelector } from 'segments/helpers/selectors';
import { createSelector } from 'reselect';
import { authOnly } from 'authorization/helpers/authOnly';
import { servicesGroupsSelector } from 'services/selectors';
import { localeDataSelector, currentLangSelector } from 'languages/selectors';
import { paidOrdersSelector, groupedOrdersSelector } from 'payment/selectors';
import LoaderContainer from 'commonBlocks/components/loader';
import { selectApisData } from '../../apis/selectors';
import FabMessages from '../components/FabMessages';

const LazyApis = lazy(() => import('apis/containers/apis'));

const sortedOrdersSelector = createSelector(paidOrdersSelector, orders =>
  orders.sort((a, b) => a.serviceId - b.serviceId)
);

@authOnly()
@connect(
  state => ({
    apis: selectApisData(state),
    segmentsList: segmentsSelector(state),
    ordersList: sortedOrdersSelector(state),
    passengersList: passengersSelector(state),
    groupedOrders: groupedOrdersSelector(state),
    orders: paidOrdersSelector(state),
    serviceGroups: servicesGroupsSelector(state),
    moveMyBooking: state.init.paxshopInitParams.myBookingAsIcon,
    currentLanguage: currentLangSelector(state),
    localeData: localeDataSelector(state),
  }),
  null
)
class PageMyBooking extends React.Component {
  constructor(props) {
    super(props);

    this.state = { ordersById: [], orderId: null };
  }

  openOrderModal = orderId => {
    this.setState({
      ordersById: this.props.ordersList.filter(
        order => order.orderId === orderId
      ),
      orderId,
    });
    this.orderModal.open();
  };

  render() {
    const {
      segmentsList,
      passengersList,
      ordersList,
      orders,
      groupedOrders,
      serviceGroups,
      moveMyBooking,
      currentLanguage,
      localeData,
      apis,
    } = this.props;

    const { ordersById, orderId } = this.state;
    const apisShouldRender = Object.values(apis).some(api => api !== null);

    return (
      <Page noPadding={moveMyBooking}>
        <Header>
          <Text>MY_BOOKING_PAGE_HEADING</Text>
        </Header>
        <SubHeader>
          <Text>MY_BOOKING_PAGE_SUB_HEADING</Text>
        </SubHeader>

        {apisShouldRender ? (
          <Suspense fallback={<LoaderContainer loading />}>
            <LazyApis />
          </Suspense>
        ) : (
          <SegmentsSection
            moveMyBooking={moveMyBooking}
            size={`${moveMyBooking ? 'small' : ''}`}
            segments={segmentsList}
            passengers={passengersList}
            localeData={localeData}
          />
        )}

        <CheckIn />

        <FabMessages />

        <Purchases
          orders={ordersList}
          groupedOrders={groupedOrders}
          serviceGroups={serviceGroups}
          showModal={this.openOrderModal}
          localeData={localeData}
        />

        <CurrentOrdersList
          orders={orders}
          passengers={passengersList}
          segments={segmentsList}
          localeData={localeData}
          currentLanguage={currentLanguage}
        />

        <Modal
          ref={c => {
            this.orderModal = c;
          }}
        >
          <OrderModal
            ordersById={ordersById}
            orderId={orderId}
            passengers={passengersList}
            segments={segmentsList}
            localeData={localeData}
          />
        </Modal>
      </Page>
    );
  }
}

export default PageMyBooking;
