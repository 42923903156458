import React from 'react';
import PropTypes from 'prop-types';
import bem from 'react-bem-classes';
import './index.scss';
import { modalOpened, modalClosed } from '_helpers/modals';
import { Portal } from 'react-portal';
import errorLogger from '_helpers/errorLogger';

@bem({
  block: 'pxModal',
  modifiers: ['centered', 'newDesign', 'mobileFullScreen'],
})
export class Modal extends React.Component {
  static propTypes = {
    noCloseButton: PropTypes.bool,
    centered: PropTypes.bool,
    newDesign: PropTypes.bool,
    dontCloseOnOutsideClick: PropTypes.bool,
    popupRootClass: PropTypes.string,
    plain: PropTypes.bool,

    stateless: PropTypes.bool,
    opened: PropTypes.bool,
    requestClose: PropTypes.func,
  };

  constructor() {
    super();

    this.state = {
      isOpen: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.stateless !== this.props.stateless) {
      throw new Error('Runtime mode change is not allowed');
    }

    if (this.props.stateless && nextProps.opened !== this.props.opened) {
      if (nextProps.opened) modalOpened('Modal');
      else modalClosed('Modal');
    }
  }

  componentWillMount() {
    if (this.props.stateless && this.props.opened) {
      modalOpened('Modal');
    }
  }

  ensureStateful(method) {
    if (this.props.stateless) {
      throw new Error(
        `Method ${method} is not allowed to be called for stateless modal`
      );
    }
  }

  open(data) {
    this.ensureStateful('open');
    if (!this.state.isOpen) {
      modalOpened('Modal');
    }

    this.setState({
      isOpen: true,
      data,
    });

    const promise = new Promise((resolve, reject) => {
      this.promiseResolve = resolve;
      this.promiseReject = reject;
    });

    promise.catch(errorLogger);

    return promise;
  }

  close = () => {
    this.ensureStateful('close');
    if (this.state.isOpen) {
      this.promiseResolve();
      this.hideModal();
    }
  };

  cancel = () => {
    this.ensureStateful('cancel');
    if (this.state.isOpen) {
      this.promiseReject();
      this.hideModal();
    }
  };

  onOutsideClicked = e => {
    const { requestClose, dontCloseOnOutsideClick, stateless } = this.props;

    if (dontCloseOnOutsideClick) return;

    if (stateless) {
      typeof requestClose === 'function' && requestClose();
    } else {
      this.cancel(e);
    }
  };

  hideModal() {
    this.ensureStateful('hideModal');
    if (this.state.isOpen) {
      modalClosed('Modal');

      this.setState({
        isOpen: false,
      });
    }
  }

  componentWillUnmount() {
    if (this.props.stateless) {
      if (this.props.opened) {
        modalClosed('Modal');
      }
    } else if (this.state.isOpen) {
      modalClosed('Modal');
    }
  }

  requestClose = () => {
    const { requestClose } = this.props;
    typeof requestClose === 'function' && requestClose();
  };

  onCloseClicked = e => {
    e.stopPropagation();
    this.props.stateless ? this.requestClose() : this.cancel();
  };

  supressClicks = e => {
    e.stopPropagation();
  };

  render() {
    const { noCloseButton, stateless, popupRootClass, plain } = this.props;

    const isOpen = stateless ? this.props.opened : this.state.isOpen;

    return (
      isOpen && (
        <Portal isOpened>
          <div className={this.block()}>
            <div
              className={this.element('popupLayout')}
              onClick={this.onOutsideClicked}
            >
              <div
                className={`${this.element('popup')} ${popupRootClass || ''}`}
                onClick={this.supressClicks}
              >
                {!noCloseButton && (
                  <div
                    className={this.element('closeButton')}
                    onClick={this.onCloseClicked}
                  />
                )}
                {stateless || plain
                  ? this.props.children
                  : React.cloneElement(this.props.children, {
                      ...this.props.children.props,
                      ...this.state.data,
                      closeModal: this.close,
                      cancelModal: this.cancel,
                    })}
              </div>
            </div>
          </div>
        </Portal>
      )
    );
  }
}
