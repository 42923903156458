import React from 'react';
import bem from 'react-bem-classes';
import './index.scss';
import { Text } from 'languages/components/text';
import moment from 'moment';
import MediaQuery from 'react-responsive';
import { DisplayTime } from '_helpers/displayDate';

@bem({
  block: 'segmentItem',
  modifiers: ['isCurrent', 'isSingleSegment'],
})
class Segment extends React.Component {
  renderAirportName(name) {
    return (
      <div className={this.element('airportName')}>
        <MediaQuery query={SCREENS_MEDIA.portraitTabletAndLarger}>
          {name}
        </MediaQuery>
        <MediaQuery query={SCREENS_MEDIA.mobileAndSmaller}>
          {name.split(' - ')[0]}
        </MediaQuery>
      </div>
    );
  }

  renderAirport(date, name) {
    const { showDepartureTime } = this.props;
    if (showDepartureTime) {
      return (
        <div>
          <DisplayTime className={this.element('time')} date={date} utc />{' '}
          {this.renderAirportName(name)}
        </div>
      );
    }
    return <div>{this.renderAirportName(name)}</div>;
  }

  render() {
    const { segment, onClick, error, errorMessageCms } = this.props;

    const airports = segment.flights.reduce(
      (previousValue, currentItem, index, array) => {
        const additingItem =
          index !== array.length - 1
            ? [
                this.renderAirport(
                  currentItem.departureDateTimeMoment,
                  currentItem.departureAirportName
                ),
              ]
            : [
                this.renderAirport(
                  currentItem.departureDateTimeMoment,
                  currentItem.departureAirportName
                ),
                this.renderAirport(
                  currentItem.arrivalDateTimeMoment,
                  currentItem.arrivalAirportName
                ),
              ];
        return previousValue.concat(additingItem);
      },
      []
    );

    return (
      <div
        className={this.block({ direction: segment.direction.toLowerCase() })}
        onClick={onClick}
      >
        {errorMessageCms && errorMessageCms !== '' && (
          <div className={this.element('error')}>
            <Text>{errorMessageCms}</Text>
          </div>
        )}
        <div className={this.element('date')}>
          {moment(segment.flights[0].departureDateTime).format('D MMM')}
        </div>
        <div className={this.element('airportsList')}>
          {airports.map((airport, index) => (
            <div key={index} className={this.element('airport')}>
              {airport}
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default Segment;
