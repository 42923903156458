export default function modalDecorator<T extends { new (...args: any[]): {} }>(
  constructor: T
) {
  return class extends constructor {
    refs: {
      modal: any;
    };

    open() {
      this.refs.modal.open();
    }
  };
}
