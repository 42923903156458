import { IStoreState } from 'types/store';

export function selectFabScopes(state: IStoreState) {
  return state.services.fabServices;
}

export function selectFabScopeList(state: IStoreState) {
  return Object.keys(selectFabScopes(state));
}

export function selectFabServicesOfScope(
  state: IStoreState,
  scopeName: string
) {
  const scope = selectFabScopes(state)[scopeName];
  return scope ? scope.services : [];
}

export function selectFabScopeStatus(state: IStoreState, scopeName: string) {
  const scope = selectFabScopes(state)[scopeName];

  if (!scope) return 'absent';
  return scope.fulfilled ? 'fulfilled' : 'fetching';
}
