import errorLogger from '_helpers/errorLogger';

export default function promiseMiddleware() {
  return next => action => {
    const { promise, callback, type, transitionTo, ...rest } = action;

    if (!promise) {
      return next(action);
    }

    const SUCCESS = type;
    const REQUEST = `${type}_REQUEST`;
    const FAILURE = `${type}_FAILURE`;

    next({ type: REQUEST, ...rest });

    const thenable = typeof promise === 'function' ? promise() : promise;

    thenable
      .then(res => {
        next({ res, type: SUCCESS, transitionTo, ...rest });
        if (callback) {
          callback(res);
        }
      })
      .catch(res => {
        console.warn('PROMISE ERROR', type, res, FAILURE);
        next({ type: FAILURE, res });
        return false;
      })
      .catch(errorLogger);

    return thenable;
  };
}
