import * as authActions from 'authorization/actions';

import { createAuthFinishPromise } from 'authorization/selectors';
import { doStartPaxshopLite } from 'checkinWidgetStarter';
import { IPaxshopReduxStore } from 'types/store';
import { appConfig } from 'config';
import {
  createBootstrapStatusPromise,
  displayBootstrapError,
  displayStaticErrorPage,
  getSrcAuthData,
} from './common';

import * as initActions from './actions';

export function onAppInit(store: IPaxshopReduxStore) {
  store.dispatch(initActions.initPaxshop(appConfig.paxshopId));

  const srcAuth = getSrcAuthData(
    store.getState().init.paxshopInitParams,
    store.getState().init.paxshopAppCtx
  );

  if (srcAuth.srcAuthData) {
    store.dispatch(
      authActions.initiateLogin({
        authType: 'BOOKING',
        bookingSrc: srcAuth.srcAuthData,
      })
    );
  }
  const promise = createAuthFinishPromise(store);
  promise
    .then(
      res => {
        // if (onSuccess && typeof onSuccess === 'function') onSuccess();
        const bootstrapPromise = createBootstrapStatusPromise(store);
        bootstrapPromise.then(res => {
          if (res.shopInitError) {
            displayBootstrapError(null);
          } else if (srcAuth.failOnAuthError) {
            displayBootstrapError(null);
          } else {
            const rootDomNode = document.getElementById('appRoot');
            doStartPaxshopLite(rootDomNode);
          }
        });
      },
      err => {
        displayStaticErrorPage(store);
      }
    )
    .catch(() => displayStaticErrorPage(store));
}
