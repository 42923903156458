export interface IPaxDialogs {
  showPaxApisDialog(paxId: string): Promise<any>;
  showMultiPaxApisDialog(): Promise<any>;
  showServiceInfoDialog(service, addToBasket): Promise<any>;
  hideServiceInfoDialog(): Promise<any>;
  showObservationPopupDialog(options): Promise<any>;
  showFaqDialog(): Promise<any>;
  showTextInfoDialog(
    type: string,
    titleCms: string,
    contentCms: string,
    closeButtonCms?: string
  ): Promise<any>;
}

let dialogs: IPaxDialogs = null;

export function getDialogs(): IPaxDialogs {
  return dialogs;
}

export function setDialogs(newDialogs: IPaxDialogs) {
  dialogs = newDialogs;
}
