import React from 'react';
import bem from 'react-bem-classes';
import { connect } from 'react-redux';
import { localeDataSelector } from 'languages/selectors';

import { Text } from 'languages/components/text';

import './index.scss';
import { Modal } from 'layouts/modal';
import PropTypes from 'prop-types';

@connect(state => ({
  localeData: localeDataSelector(state),
}))
@bem({
  block: 'textInfoModal',
})
class TextInfoModalContent extends React.Component {
  static propTypes = {
    titleCms: PropTypes.string,
    contentCms: PropTypes.string,
    type: PropTypes.string,
    closeButtonCms: PropTypes.string,

    closeModal: PropTypes.func,
  };

  render() {
    const {
      titleCms,
      contentCms,
      closeModal,
      closeButtonCms,
      localeData,
      type,
    } = this.props;

    const closeButtonText = localeData.printCmsText(closeButtonCms) || 'Close';

    return (
      <div className={this.block()}>
        <div
          className={this.element('closeIcon', { type })}
          onClick={closeModal}
        />
        <div className={this.element('titleBar', { type })}>
          <div className={this.element('headIcon', { type })} />
          <Text className={this.element('title')}>{titleCms}</Text>
        </div>

        <Text className={this.element('content', { type })}>{contentCms}</Text>

        <div className={this.element('footer')}>
          <div className={this.element('closeButton')} onClick={closeModal}>
            {closeButtonText}
          </div>
        </div>
      </div>
    );
  }
}

export class TextInfoModal extends React.Component {
  open(type, titleCms, contentCms, closeButtonCms) {
    return this.refs.modal.open({ type, titleCms, contentCms, closeButtonCms });
  }

  render() {
    return (
      <Modal
        noCloseButton
        dontCloseOnOutsideClick
        popupRootClass="textInfoModalPopup"
        ref="modal"
      >
        <TextInfoModalContent />
      </Modal>
    );
  }
}
