import React, { ReactNode } from 'react';
import { Bem } from 'react-bem-classes';
import './index.scss';

const bem = new Bem('pageCloud');

export default function PageCloud(props) {
  return <div className={bem.block()} {...props} />;
}

export function CloudTitle({
  hideForMobile,
  ...props
}: {
  hideForMobile?: boolean;
  children?: ReactNode;
}) {
  return <div className={bem.element('title', { hideForMobile })} {...props} />;
}
