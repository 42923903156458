import React from 'react';
import bem from 'react-bem-classes';
import './index.scss';

import { Modal } from 'layouts/modal';
import { Text, T } from 'languages/components/text';
import { connect } from 'react-redux';
import { passengersSelector } from 'passengers/helpers/selectors';
import { segmentsSelector } from 'segments/helpers/selectors';
import PassengersListHelper from 'passengers/helpers/PassengersListHelper';
import SegmentsListHelper from 'segments/helpers/SegmentsListHelper';
import { localeDataSelector } from 'languages/selectors';
import PropTypes from 'prop-types';

@connect(state => ({
  passengers: passengersSelector(state),
  segments: segmentsSelector(state),
  localeData: localeDataSelector(state),
}))
@bem({
  block: 'confirmDeletingDialog',
})
export class ConfirmDeletingDialogContent extends React.Component {
  static propTypes = {
    closeModal: PropTypes.func,
    cancelModal: PropTypes.func,
    services: PropTypes.array,
    baseService: PropTypes.object,
  };

  renderServiceName(service) {
    return service.seatNumber
      ? `Seat ${service.seatNumber.rowNumber}${service.seatNumber.columnLetter}`
      : service.nameCmsKey;
  }

  renderPassengerName(passengerId) {
    const passengersListHelper = new PassengersListHelper(
      this.props.passengers
    );

    const passenger = passengersListHelper.getPassengerById(passengerId);
    return `${passenger.firstName} ${passenger.lastName}`;
  }

  renderFlightNumber(segmentId) {
    const segmentListHelper = new SegmentsListHelper(this.props.segments);

    const segment = segmentListHelper.getSegmentById(segmentId);
    return `#${segment.flights[0].flightNumber}`;
  }

  render() {
    const {
      closeModal,
      cancelModal,
      services,
      baseService,
      localeData,
      messageCmsKey,
    } = this.props;

    return (
      <div className={this.block()}>
        <div className={this.element('header')}>
          <div className={this.element('title')}>
            {localeData.printCmsTextWithParams('CONFIRM_DELETING_POPUP_TITLE', {
              count: services.length,
            })}
          </div>

          <div className={this.element('message')}>
            {baseService.seatNumber ? (
              `Seat ${baseService.seatNumber.rowNumber}${baseService.seatNumber.columnLetter}`
            ) : (
              <T fallback={baseService.name}>{baseService.nameCmsKey}</T>
            )}
            <T>{messageCmsKey}</T>
          </div>
        </div>

        <div className={this.element('content')}>
          <div className={this.element('services')}>
            {services.map((service, i) => (
              <div key={service.serviceId} className={this.element('service')}>
                {service.seatNumber ? (
                  `Seat ${service.seatNumber.rowNumber}${service.seatNumber.columnLetter}`
                ) : (
                  <Text fallback={service.name}>{service.nameCmsKey}</Text>
                )}
                <div className={this.element('info')}>
                  <div>
                    <Text useSpan>PASSENGER</Text>:{' '}
                    {this.renderPassengerName(service.passengerId)}
                  </div>
                  <div>
                    <Text useSpan>FLIGHT_NUMBER</Text>:{' '}
                    {this.renderFlightNumber(service.segmentId)}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>

        <div className={this.element('footer')}>
          <button
            type="button"
            className={this.element('button', { type: 'ok' })}
            onClick={closeModal}
          >
            <Text>CMS_DELETION_ACTION_REMOVE</Text>
          </button>
          <button
            type="button"
            className={this.element('button', { type: 'cancel' })}
            onClick={cancelModal}
          >
            <Text>CMS_DELETION_ACTION_CANCEL</Text>
          </button>
        </div>
      </div>
    );
  }
}

export class ConfirmDeletingDialog extends React.Component {
  open(options) {
    return this.refs.rootModal.open(options);
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <Modal ref="rootModal" centered>
        <ConfirmDeletingDialogContent />
      </Modal>
    );
  }
}
