import { store } from 'store';
import { checkSeatsSet } from 'seatingWidget/actions';
import { backendSeatValidationRequestSelector } from 'serviceInBasket/helpers/selectors';
import { isLoggedInSelector } from '../authorization/selectors';

export function initSeatingWidget() {
  let lastOccupiedSeats = null;

  store.subscribe(() => {
    const state = store.getState();
    const isLoggedIn = isLoggedInSelector(state);
    const occupiedSeats = backendSeatValidationRequestSelector(state);

    if (isLoggedIn && occupiedSeats !== lastOccupiedSeats) {
      lastOccupiedSeats = occupiedSeats;
      store.dispatch(checkSeatsSet(occupiedSeats, 2000, state));
    }
  });
}
