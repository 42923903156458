import {
  fetchingContainerDefaultState,
  DATA_CONTAINER_UPDATE_ACTION,
} from '_helpers/datacontainer/datacontainer';
import { PAXSHOP_LOGOUT_ACTION_ID } from '../actions/constants';

const defaultState = fetchingContainerDefaultState(false);

export default function authSrcData(state = defaultState, action) {
  if (action.type === DATA_CONTAINER_UPDATE_ACTION) {
    if (action.containerId === 'AUTH::AUTH_DATA') {
      return action.container;
    }
  } else if (action.type === 'UPDATE_ALL_INFANTS_HAVE_REF') {
    return Object.assign({}, state, {
      data: Object.assign({}, state.data, {
        booking: Object.assign({}, state.data.booking, {
          infantRefInfo: Object.assign({}, state.data.booking.infantRefInfo, {
            allInfantsHaveRef: true,
          }),
        }),
      }),
    });
  } else if (action.type === 'PAXSHOP_INITIATE_LOGIN') {
    return fetchingContainerDefaultState(true);
  } else if (action.type === PAXSHOP_LOGOUT_ACTION_ID) {
    return fetchingContainerDefaultState(false);
  }

  return state;
}
