import { combineReducers } from 'redux';

import list from './list';
import currentGroup from './currentGroup';
import serviceTypes from './serviceTypes';
import fabServices from './fabServices';

const rootReducer = combineReducers({
  list,
  currentGroup,
  serviceTypes,
  fabServices,
});

export type IServicesStoreState = ReturnType<typeof rootReducer>;

export default rootReducer;
