import { InferValueTypes } from 'types/store';
import { IFabService } from 'types/services';
import { ApiActionTypes, API_START } from '_middlewares/http';
import * as actions from '../actions';

type ActionTypes = ReturnType<InferValueTypes<typeof actions>> | ApiActionTypes;

interface IState {
  [scope: string]: {
    fulfilled: boolean,
    services: IFabService[]
  }
}

const initialState: IState = {};

export default function(state = initialState, action: ActionTypes): IState {
  if (action.type === 'SERVICES/FAB_FETCHED') {
    return {
      ...state,
      [action.scope]: {
        fulfilled: true,
        services: action.services
      },
    };
  }

  if (action.type === API_START) {
    const [requestId, requestScope] = action.label.split(':');

    if (requestId === 'SERVICES/FAB') {
      return {
        ...state,
        [requestScope]: {
          fulfilled: false,
          services: []
        }
      }
    }
  }

  return state;
}
