import { ICMSEntry } from 'types/languages';
import { makeRequest } from '_middlewares/http';
import { appConfig } from 'config';
import languagesWhitelist from '../../../languages.json';

export function setLanguage(lang: string) {
  return {
    type: 'LANGUAGES/SET_LANGUAGE',
    lang,
  } as const;
}

export function fetchShopCMSKeys(language: string) {
  return makeRequest<{ locale: string; cmsTexts: ICMSEntry[] }>({
    url: `${appConfig.cmsApiUrl}/cms/paxshop/${appConfig.paxshopId}/${language}`,
    onSuccess: handleShopCMSKeysSuccess,
  });
}

export function handleShopCMSKeysSuccess(payload: {
  cmsTexts: ICMSEntry[];
  locale: string;
}) {
  return {
    type: 'LANGUAGES/SHOP_CMS_FETCH_SUCCESS',
    payload,
  } as const;
}

export function fetchBookingCMSKeys(
  language: string,
  cmsSalesChannelId: number
) {
  return makeRequest<{ locale: string; cmsTexts: ICMSEntry[] }>({
    url: `${appConfig.cmsApiUrl}/cms/v2/${cmsSalesChannelId}/${language}`,
    onSuccess: handleBookingCMSKeysSuccess,
  });
}

export function handleBookingCMSKeysSuccess(payload: {
  cmsTexts: ICMSEntry[];
  locale: string;
}) {
  return {
    type: 'LANGUAGES/BOOKING_CMS_KEYS_FETCH_SUCCESS',
    payload,
  } as const;
}

export function fetchPaxpartnerCMSKeys(
  language: string,
  paxpartnerCmsChannel: number
) {
  return makeRequest<{ locale: string; cmsTexts: ICMSEntry[] }>({
    url: `${appConfig.cmsApiUrl}/cms/v2/${paxpartnerCmsChannel}/${language}`,
    onSuccess: handlePaxpartnerCMSKeysSuccess,
  });
}

export function handlePaxpartnerCMSKeysSuccess(payload: {
  cmsTexts: ICMSEntry[];
  locale: string;
}) {
  return {
    type: 'LANGUAGES/PAXPARTNER_CMS_KEYS_FETCH_SUCCESS',
    payload,
  } as const;
}

export function fetchShopLanguages() {
  return makeRequest<string[]>({
    url: `${appConfig.apiUrl}/info/languages?paxshopId=${appConfig.paxshopId}`,
    onSuccess: handleShopLanguagesSuccess,
  });
}

export function handleShopLanguagesSuccess(languages: string[]) {
  return {
    type: 'LANGUAGES/AVAILABLE_LANGUAGES_FETCHED',
    payload: languages.filter(l => languagesWhitelist.includes(l)),
  } as const;
}
