// Used to feature detect bug, that affects at least IE11
// IE fires 'storage' event even when item is set from the current tab
export function detectAppropriateStorageSupport(
  callback: (isSupported: boolean) => void
) {
  let called = false;
  const key = Math.floor(Math.random() * 100000).toString();
  function listener(e) {
    if (e.key === key) {
      called = true;
    }
  }
  window.addEventListener('storage', listener);
  localStorage.setItem(key, key);
  setTimeout(() => {
    localStorage.removeItem(key);
    window.removeEventListener('storage', listener);
    callback(!called);
  }, 0);
}
