import React, { Component } from 'react';
import bem from 'react-bem-classes';
import './index.scss';
import PropTypes from 'prop-types';

import { Modal } from 'layouts/modal';
import { Text } from 'languages/components/text';

@bem({
  block: 'allSectorRequiredToBuyModal',
})
export class ObservationPopup extends React.Component {
  static propTypes = {
    closeModal: PropTypes.func,
    cancelModal: PropTypes.func,
    text: PropTypes.string,
  };

  render() {
    const { closeModal, cancelModal, text, component } = this.props;

    const decsContent = component || (
      <Text className={this.element('desc')}>{text}</Text>
    );

    return (
      <div className={this.block()}>
        <Text className={this.element('title')}>OBSERVATION_POPUP_TITLE</Text>
        {decsContent}

        <div className={this.element('footer')}>
          <Text className={this.element('button')} onClick={closeModal}>
            OBSERVATION_POPUP_OK_BUTTON
          </Text>
          <Text className={this.element('button')} onClick={cancelModal}>
            OBSERVATION_POPUP_CANCEL_BUTTON
          </Text>
        </div>
      </div>
    );
  }
}

export class ObservationPopupDialog extends React.Component {
  open(options) {
    return this.refs.rootModal.open(options);
  }

  shouldComponentUpdate() {
    return false;
  }

  render() {
    return (
      <Modal ref="rootModal" centered>
        <ObservationPopup />
      </Modal>
    );
  }
}
